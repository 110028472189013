import { Link } from 'react-router-dom';
import { typePath } from '../utils';
import moment from 'moment'
import { useEffect, useState } from 'react'
import { toLower } from 'lodash';


const user = JSON.parse(localStorage.getItem('user'))



function DocumentList({ fields, data, filter, other }) {

  const getFilteredData = (data, filterKey) => {
    let filter = 'true';
    let pub = 'true';
    let es_libro = 'true';
    let categoria = 'true';
    let closed = 'true';
    let admin = 'true';
    let user = 'true';

    switch (filterKey) {
      case 'Libros': // filter name
        filter = 'Recurso'; // item typename
        es_libro = 1;
        break;
      case 'Artículos':
        filter = 'Recurso';
        es_libro = 0;
        break;

      case 'Abiertas':
        filter = 'Mensaje';
        closed = false;
        break;
      case 'Cerradas':
        filter = 'Mensaje';
        closed = true;
        break;

      case 'Administradores':
        filter = 'User';
        admin = true;
        user = 'true';
        break;
      case 'Usuarios':
        filter = 'User';
        user = true;
        admin = 'true';
        break;

      case 'Publicadas':
        pub = true;
        break;
      case 'No publicadas':
        pub = false;
        break;

      default:
        if (other) {
          other?.map(element => {
            if (element[0] === 0) {
              return null;
            } else if (element[1] === filterKey) {
              categoria = element[0];
              return null;
            }
          });
        }


      

    }

    
    if (data) {
      return Object.values(data)?.filter(item => {
        if ((filter === 'true' || filter === item.__typename) && 
            (pub === 'true' || pub === item.isPublished) && 
            (es_libro === 'true' || es_libro === item.es_libro) && 
            (closed === 'true' || closed === item.closed) && 
            (categoria === 'true' || categoria === ""+item.categoriaId) && 
            (admin === 'true' || (admin && item.rol === 'admin')) &&
            (user === 'true' || (user && item.rol === 'user'))
            ) {
          return item
        }
      });
    }

  };




  function getStars(stars) {
    const svg_star = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 7.13l.97 2.29.47 1.11 1.2.1 2.47.21-1.88 1.63-.91.79.27 1.18.56 2.41-2.12-1.28-1.03-.64-1.03.62-2.12 1.28.56-2.41.27-1.18-.91-.79-1.88-1.63 2.47-.21 1.2-.1.47-1.11.97-2.27M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2z"/></svg>
    const svg_star_filled = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/></svg>  

    var starsArray = [0, 0, 0, 0, 0];

    for (var i=0; i<stars; i++) {
      starsArray[i] = 1;
    }

    return <div className='flex'>
      {starsArray.map(element => {
        if (element) {
          return <div>{svg_star_filled}</div>;
        } else {
          return <div>{svg_star}</div>;
        }
      })}
    </div>
  }




  
  return (
    <div>
      <div
        className="flex w-full justify-between p-4 border-b uppercase text-xs mb-4"
        style={{   }}
      >
        {fields.map((field, index) => {

          if (field.name === 'provider' || field.name === 'rol' || field.name === 'stars') { 
            return <span key={index} className='w-80'>{field.label}</span>
          } else {
            return <span key={index} className='w-full'>{field.label}</span>
          }
          
        })}
      </div>


      <div>
        {getFilteredData(data, filter)?.map((item, index) => (
          <div key={index}
            className="px-4 py-4 hover:bg-gray-50 rounded flex w-full justify-between"
            style={{  }}
          >

            {fields.map((field, index) => {

              /* Fechas */
              if (field.name === 'createdAt' || field.name === 'updatedAt' || field.name === 'publishedAt' || field.name === 'lastLogin' || field.name === 'suscriberUntilNew' || field.name === 'endsAt') {
                var t = new Date(item[field.name]);
                if (field.name === 'publishedAt' && (moment(new Date(t)).format('DD/MM/YYYY hh:MM') === 'Invalid date' || item[field.name] === null ) ) {
                  return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`} className='w-full overflow-hidden'>No publicada</Link>
                } else if (moment(new Date(t)).format('DD/MM/YYYY hh:MM') === 'Invalid date' || item[field.name] == null) {
                  return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`} className='w-full overflow-hidden'>---</Link>
                }else {
                  return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`} className={'flex items-center w-full overflow-hidden'}>
                    <span>{moment(new Date(t)).format('DD/MM/YYYY hh:MM')}h</span>
                  </Link>
                }
              } 
              
              /* Editados */
              if (field.name === 'es_libro') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span>{item[field.name]? 'Libro' : 'Artículo'}</span>
                </Link>
              } 
              if (field.name === 'provider' || field.name === 'rol') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-80 overflow-hidden'}>
                  <span className='pr-5'>{ item[field.name]? item[field.name] : '---' }</span>
                </Link>
              } 
              if (field.name === 'categoriaId') {
                var cat = null;
                other.map(element => {
                  if ( parseInt(element[0]) === parseInt(item[field.name])) {
                    cat = element[1]
                  }
                });

                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ cat? cat : '---' }</span>
                </Link>
              } 
              if (field.name === 'responses') {
                if (!item?.responses?.length > 0) {
                  return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                    <span className='pr-5'>¡Nueva!</span>
                  </Link>
                } else {

                  var numberOfUnreaded = 0;
                  item?.responses?.map(element => {
                    if (!element.isReaded && element.userFrom.rol != 'admin') {
                      numberOfUnreaded++
                    }
                  });

                  if (numberOfUnreaded === 0) {
                    return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                      <span className='pr-5'>Sin nuevos mensajes</span>
                    </Link>
                  } else if (numberOfUnreaded === 1) {
                    return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                      <span className='pr-5'>{ numberOfUnreaded } nuevo mensaje</span>
                    </Link>
                  } else {
                    return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                      <span className='pr-5'>{ numberOfUnreaded } nuevos mensajes</span>
                    </Link>
                  }
                }
              }


              if (field.name === 'body') {
                var showText = false;
                /* return <Link key={index}  to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden max-h-16 overflow-y-auto'}>
                  <span className='pr-5' dangerouslySetInnerHTML={{__html: item.body}}></span>
                </Link> */
                return <div className={'w-full mr-2'}>
                  <div key={index} className={'flex w-full max-h-28 overflow-y-auto bg-gray-100 p-2 rounded'}>
                    <span className='pr-5' dangerouslySetInnerHTML={{__html: item.body}}></span>
                  </div>
                </div>
              }
              if (field.name === 'language') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ item.language === 'eng'? 'Inglés' : 'Español' }</span>
                </Link>
              }
              if (field.name === 'stars') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-80 overflow-hidden'}>
                  <span className='pr-5'>{ getStars(item.stars) }</span>
                </Link>
              }
              if (field.name === 'resource') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ item.indice?.resource?.titulo? item.indice.resource.titulo.replaceAll('*', '') : '---' }</span>
                </Link>
              }
              if (field.name === 'indice') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ item.indice?.title? item.indice.title.replaceAll('*', '') : '---' }</span>
                </Link>
              }
              if (field.name === 'user') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ item.user?.name? item.user.name : '---' }</span>
                </Link>
              }
              if (field.name === 'userEmail') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ item.user?.email? item.user.email : '---' }</span>
                </Link>
              }
              if (field.name === 'userFrom') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ item.userFrom?.name? item.userFrom.name : '---' }</span>
                </Link>
              }
              if (field.name === 'userTo') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{ item.userTo?.name? item.userTo.name : 'Los Administradores' }</span>
                </Link>
              }
              if (field.name === 'closed') {
                return !item.closed? <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>Abierta</span>
                </Link>
                :
                <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>Cerrada</span>
                </Link>
              }
              if (field.name === 'enabled') {
                return item.enabled? <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>Habilitada</span>
                </Link>
                :
                <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>Deshabilitada</span>
                </Link>
              }
              if (field.name === 'daysDuration') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  {item['daysDuration'] && item['monthsDuration']? <span className='pr-5'>{item['daysDuration']} días y {item['monthsDuration']} meses </span>: null}
                  {!item['daysDuration'] && item['monthsDuration']? <span className='pr-5'>{item['monthsDuration']} meses </span>: null}
                  {item['daysDuration'] && !item['monthsDuration']? <span className='pr-5'>{item['daysDuration']} días </span>: null}
                  {!item['daysDuration'] && !item['monthsDuration'] && item['slug'] === 'mensual_personalizada'? <span className='pr-5'> Personalizada </span>: null}
                  {!item['daysDuration'] && !item['monthsDuration'] && item['slug'] != 'mensual_personalizada'? <span className='pr-5'> Indefinida </span>: null}

                </Link>
              }
              if (field.name === 'price') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  {item[field.name]? 
                    <span className='pr-5'>{ (item[field.name] / 100).toFixed(2) }€</span>
                    : 
                    <span className='pr-5'>Gratuita</span>
                  }
                </Link>
              }




              /* Color */
              if (field.name === 'color') {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center overflow-hidden w-full'}>
                  <div className='w-10 h-full rounded-full' style={{ background: item.color? item.color : '#D3D3D3' }}></div>
                </Link>
              } 



              /* Delete */
              if (field.name === 'remove') {
                return <div key={index} className='w-full'><Link to={`/${typePath(item.__typename)}/delete/${item.id}`}  className={'select-none bg-red-500 hover:bg-red-700 hover:text-white text-white font-bold py-2 px-4 rounded-sm'}>
                  Eliminar
                </Link></div>
              } 
              
              /* Others */
              if (item[field.name]) {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>{item[field.name]}</span>
                </Link>
              } else {
                return <Link key={index} to={`/${typePath(item.__typename)}/${item.slug ?? item.id}`}  className={'flex items-center w-full overflow-hidden'}>
                  <span className='pr-5'>---</span>
                </Link>
              }
            })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DocumentList;
