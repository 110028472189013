import React, { useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import axios from 'axios';
import { Form, Upload, message, Button, Checkbox, Tooltip } from 'antd';
import { UploadOutlined, ReloadOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function StepIndices(props) {

  const {
    onSubmit,
    setCurrentStep,
    currentStep,
    indices,
    setIndices,
    indicesBig,
    setIndicesBig,
    setImageInLine,
    setAudioInLine,
    filesUpload,
    setFilesUpload,
    backStep,
    filesSaved,
    slug
  } = props;

  const svg_anterior = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;
  const svg_siguiente = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  
  const svg_arrow_right = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>
  const svg_arrow_down = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>
  


  const propsUploadMultiple = {
    maxCount: 50,
    multiple: true,
    processData: true,
    showUploadList: false,
    beforeUpload: (file, list) => {
      setFilesUpload(list => [...list, { data: file, process: true }]);
      return false;
    },
    onRemove: file => {
      setFilesUpload(filesUpload.filter(f => f.name !== file.name));
      setIndices(indices.filter(i => i.fileName !== file.name));
      return true;
    },
  };

  useEffect(() => {
    if (indices) {
      setIndicesBig(indices.filter( item => item.depth <= 3 || item.parentId === null || item.parent === null ))
    }
  }, [indices])





  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const handleReset = () => {
    setFilesUpload([]);
    setIndices([]);
    setImageInLine([])
    setAudioInLine([])
  };


  const handleSubmit = async () => {
    // Comparar y mostrar toastdependediendo de el error
    var newIndiceListTMP = [];

    for await (let file of filesUpload) {

      try {
        const formData = new FormData();
        formData.append('md', file.data);
        formData.append('process', file.process);
        const response = await axios.post(
          `${process.env.REACT_APP_API}processMd`,
          formData
        );




        if (response.data.data === 'error' || response.data.error.length) {
          response.data.error.forEach(el => {
            if (el.id === 'audio_syntax') {
              message.error(
                `Error de sintaxis en el archivo "${file.name}" (Line ${el.line})`,
                10
              );
            }
          });
        } else {


          if (response.data.numAudios === response.data.audioLines.length) {
            message.success('Indices generados correctamente', 4);

            if (slug) {

              /* Comprovar repes */
              await indices.map((indiceTMP, pos) => {
                var notSaved = true;
                response.data.data.map((newIndiceTMP, posNew) => {
                  if (indiceTMP.title === newIndiceTMP.title) {
                    if (indiceTMP.id) { newIndiceTMP.id = indiceTMP.id; }
                    newIndiceTMP.position = indiceTMP.position;
                    newIndiceTMP.slug = indiceTMP.slug;
                    newIndiceListTMP.push(newIndiceTMP)
                    notSaved = false;

                  }
                });
                if (notSaved) {
                  newIndiceListTMP.push(indiceTMP)
                }
              });



              await setIndices(newIndiceListTMP => _.uniqBy([...newIndiceListTMP, ...response.data.data], 'slug').map( (item, index) => ({ ...item, position: index }) ) );



            } else {
              setIndices(indices => _.uniqBy([...indices, ...response.data.data], 'slug').map( (item, index) => ({ ...item, position: index }) ) );
            }
            

            
            /* setIndices(indices => _.uniqBy([...indices, ...response.data.data], 'slug').map( (item, index) => ({ ...item, position: index }) ) ); */
            setImageInLine(prev => prev.concat(response.data.imageLines));
            setAudioInLine(prev => prev.concat(response.data.audioLines.sort()));
          } else {
            message.error( `Error en el archivo "${file.name}" revisa la sintaxis del markdown`, 8 );
          }
        }
      } catch (err) {
        message.error('Internal server Error.');
      }
    }
  };




  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    var items = reorder(
      indicesBig,
      result.source.index,
      result.destination.index
    );

    const itemWithNewPosition = items.map((item, index) => ({
      ...item,
      position: index,
    }));

    setIndicesBig(itemWithNewPosition);


    var copyIndices = indices;
    copyIndices.map(copyElement => {
      return itemWithNewPosition.map(newElement => {
        return copyElement.slug === newElement.slug ? copyElement.position = newElement.position : null
      });
    });

    setIndices(copyIndices);
  };

  
  const handleProcess = name => {
    setFilesUpload(
      filesUpload.map(item =>
        item.data.name === name
          ? Object.assign(item, { process: !item.process })
          : item
      )
    );
  };


  const removeFile = name => {
    setFilesUpload(filesUpload.filter(item => item.data.name !== name));
  };


  function showIndices(slug) {
    var elements = document.getElementsByClassName('showOrHidde-'+slug)

    for (var i=0; i<elements.length; i++) {
      var element = elements[i];
      if (element.style.display === 'none') {
        element.style.display = 'block';
        document.getElementById('showOrHiddeButton-'+slug).innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"/></svg>'
      } else {
        element.style.display = 'none';
        document.getElementById('showOrHiddeButton-'+slug).innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>'

      }

    }



  }



  return (
    <>
      <Form onFinish={fields => onSubmit(fields, 'step2')}>
        <div className={`flex justify-between`}>
          <Form.Item>
            <Upload {...propsUploadMultiple}>
              <Button icon={<UploadOutlined />}>Subir archivos</Button>
            </Upload>
          </Form.Item>

          <Button onClick={handleReset} icon={<ReloadOutlined />}>
            Borrar todo
          </Button>
        </div>

          <div className="ant-upload-list ant-upload-list-text mb-4">
            {filesUpload.map((item, index) => (
              <div className="ant-upload-list-text-container" key={index}>
                <div className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-text">
                  <div className="ant-upload-list-item-info">
                    <span className="ant-upload-span">
                      <div className="ant-upload-text-icon">
                        <span role="img" aria-label="paper-clip" className="anticon anticon-paper-clip" >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="paper-clip"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                          </svg>
                        </span>
                      </div>
                      <span className="ant-upload-list-item-name" title="1-Anexo3-Bibliografia-citada.md" >
                        {item.data.name} {'\u00A0'}{' '}
                        <Tooltip
                          title={
                            item.process
                              ? 'Desmarcar para no procesar archivo'
                              : 'Marcar para procesar archivo'
                          }
                        >
                          <Checkbox checked={item.process} onChange={() => handleProcess(item.data.name)} />
                        </Tooltip>
                      </span>
                      <span className="ant-upload-list-item-card-actions">
                        <button
                          title="Remove file"
                          type="button"
                          className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
                          onClick={() => removeFile(item.data.name)}
                        >
                          <span
                            role="img"
                            aria-label="delete"
                            tabIndex="-1"
                            className="anticon anticon-delete"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="delete"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                            </svg>
                          </span>
                        </button>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {filesUpload.length ? (
            <div className="mb-2 text-red-400">
              * Marca los archivos que quieres que se procesen.
            </div>
          ) : (
            ''
          )}

        <Form.Item>
          <Button onClick={handleSubmit} disabled={isEmpty(filesUpload)}>
            Generar indice
          </Button>
        </Form.Item>
        
        <h1 className={'mt-8'+isEmpty(filesUpload)? 'hidden' : ''}>Indices generados:</h1>
        <Form.Item>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`${snapshot.isDraggingOver ? `` : undefined}`}
                >
                  <div
                    className={`py-2 border-b flex justify-start items-center ${
                      snapshot.isDragging && `bg-gray-100 bg-opacity-50`
                    }`}
                  ></div>
                  {indicesBig.map((item, index) => {
                    var entred = false;
                    var noRepeat = true;

                    if (item.depth <= 3 || item.parentId === null || item.parent === null) {
                      return <Draggable
                        key={item.slug}
                        draggableId={item.slug}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`py-2 border-b flex items-start justify-start ${
                              snapshot.isDragging && `bg-gray-100 bg-opacity-50`
                            }`}
                          >

                            <div className='w-full flex flex-wrap'>
                              <div className='w-full'>
                                {/* <span className="text-xs">{`n${item.position + 1}`}</span> */}
                                <span className="text-xs mx-2">-</span>
                                <span
                                  className='text-left w-full mr-2'
                                  style={{
                                    fontWeight: item.depth > 3 ? `400` : `500`,
                                    fontSize: item.depth > 3 ? `90%` : `100%`,
                                    paddingLeft:
                                      item.depth === 4
                                        ? '1em'
                                        : item.depth === 5
                                        ? '2em'
                                        : 0,
                                  }}
                                >
                                  {item.title === 'Notas' ? `${item.title} (${item.parent})` : item.title.replaceAll('*', '')}
                                </span>
                              </div>


                              {indices.map((itemSecondary, indexSecondary) => ( (itemSecondary.parent && itemSecondary.parent+'' === item.title+'') || (itemSecondary.parentId && itemSecondary.parentId+'' === item.id+'') ?
                                <div key={itemSecondary.slug} className={'w-full showOrHidde-'+item.slug} style={{ display: 'none' }}>
                                  <span className="text-xs mx-2">·</span>
                                  <span
                                    className='text-left w-full mr-2'
                                    style={{
                                      fontWeight: itemSecondary.depth > 3 ? `400` : `500`,
                                      fontSize: itemSecondary.depth > 3 ? `90%` : `100%`,
                                      paddingLeft:
                                        itemSecondary.depth === 4
                                          ? '1em'
                                          : itemSecondary.depth === 5
                                          ? '2em'
                                          : 0,
                                    }}
                                  >
                                    {itemSecondary.title === 'Notas' ? `${itemSecondary.title} (${itemSecondary.parent})` : itemSecondary.title.replaceAll('*', '')}
                                  </span>


                                  {indices.map((itemTerciary, indexTerciary) => (
                                    (itemTerciary.parent && itemTerciary.parent+'' === itemSecondary.title+'') || (itemTerciary.parentId && itemTerciary.parentId+'' === itemSecondary.id+'') ?
                                      <div key={itemTerciary.slug} className='w-full'>
                                        <span className="text-xs mx-2">·</span>
                                        <span
                                          className='text-left w-full mr-2'
                                          style={{
                                            fontWeight: itemTerciary.depth > 3 ? `400` : `500`,
                                            fontSize: itemTerciary.depth > 3 ? `90%` : `100%`,
                                            paddingLeft:
                                            itemTerciary.depth === 4
                                                ? '1em'
                                                : itemTerciary.depth === 5
                                                ? '2em'
                                                : 0,
                                          }}
                                        >
                                          {itemTerciary.title === 'Notas' ? `${itemTerciary.title} (${itemTerciary.parent})` : itemTerciary.title.replaceAll('*', '')}
                                        </span>
                                      </div>

                                    : null
                                  ))}
                                  
                                </div>
                                : null
                                ))}
                            </div>


                            <div className='flex items-center' style={{ marginTop: '3px' }}>
                              <button type='button' onClick={() => setIndices( indices.filter( indice => indice.fileName !== item.fileName  ) ) } className="mr-2 transition transform ease-in-out duration-100 hover:scale-150 mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                                  <line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/>
                                </svg>                              
                              </button>
                              
                              {indices.map((itemSecondary, indexSecondary) => { 
                                if (((itemSecondary.parent && itemSecondary.parent+'' === item.title+'') || (itemSecondary.parentId && itemSecondary.parentId+"" != 'undefined' && itemSecondary.parentId+'' === item.id+'')) && !entred) {
                                  entred = true
                                  return <button key={'showOrHiddeButton-'+item.slug} id={'showOrHiddeButton-'+item.slug} type='button' onClick={() => showIndices(item.slug) } className="mr-2 transition transform ease-in-out duration-100 hover:scale-150">
                                    {svg_arrow_down}
                                  </button>
                                }
                              })}

                              {!entred? 
                                <button type='button' className="mr-2 disabled opacity-25">
                                  {svg_arrow_down}
                                </button> 
                                : null 
                              }
                            </div>

                          </div>
                        )}
                      </Draggable> 
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Form.Item>


        <br></br>
        <div className='flex justify-between'>
          <Form.Item>
            <Button htmlType="button" size="large" type="primary" onClick={backStep}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_anterior}</div>
                <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
              </div>
            </Button>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" size="large" type="primary">
              <div className='flex items-center'>
                <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                <div className='flex items-center ml-2'>{svg_siguiente}</div>
              </div>
            </Button>
          </Form.Item>
        </div>

      </Form>
    </>
  );
}

export default StepIndices;
