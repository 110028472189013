import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { getNumberOfSubscriptions, lastRecentActivity } from '../actions'
import recentActivity from '../reducers/recentActivity';


function Dashboard(props) {

  const { getNumberOfSubscriptions, suscripciones, lastRecentActivity, recentActivity } = props


  var svg_recursos = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-grid"><rect x="3" y="3" width="7" height="7"/><rect x="14" y="3" width="7" height="7"/><rect x="14" y="14" width="7" height="7"/><rect x="3" y="14" width="7" height="7"/></svg>
  var svg_dir = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-folder"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"/></svg>
  var svg_bell = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"/><path d="M13.73 21a2 2 0 0 1-3.46 0"/></svg>
  var svg_user = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>
  var svg_dollar = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
  var svg_question = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-help-circle"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>


  useEffect(() => {
    getNumberOfSubscriptions()
    lastRecentActivity()
  }, [])

 

  return (
    <>
      <div className="bg-gray-50 border-b flex flex-wrap">
        <div className='flex w-full'>
          <Link to="/recursos" className="w-full flex items-center justify-center md:justify-start m-2 p-5 bg-gray-100 hover:bg-gray-200 transition ease-in-out duration-300 rounded-xl font-semibold text-xl"> {svg_recursos} <p className='hidden md:block'>Recursos</p> </Link>
          <Link to="/planes" className="w-full flex items-center justify-center md:justify-start m-2 p-5 bg-gray-100 hover:bg-gray-200 transition ease-in-out duration-300 rounded-xl font-semibold text-xl"> {svg_dir} <p className='hidden md:block whitespace-nowrap'>Planes de lectura</p> </Link>
          <Link to="/notificaciones" className="w-full flex items-center justify-center md:justify-start m-2 p-5 bg-gray-100 hover:bg-gray-200 transition ease-in-out duration-300 rounded-xl font-semibold text-xl"> {svg_bell} <p className='hidden md:block'>Notificaciones</p> </Link>
        </div>
        <div className='flex w-full'>
          <Link to="/usuarios" className="w-full flex items-center justify-center md:justify-start m-2 p-5 bg-gray-100 hover:bg-gray-200 transition ease-in-out duration-300 rounded-xl font-semibold text-xl"> {svg_user} <p className='hidden md:block'>Usuarios</p> </Link>
          <Link to="/suscripciones" className="w-full flex items-center justify-center md:justify-start m-2 p-5 bg-gray-100 hover:bg-gray-200 transition ease-in-out duration-300 rounded-xl font-semibold text-xl"> {svg_dollar} <p className='hidden md:block'>Suscripciones</p> </Link>
          <Link to="/soporte" className="w-full flex items-center justify-center md:justify-start m-2 p-5 bg-gray-100 hover:bg-gray-200 transition ease-in-out duration-300 rounded-xl font-semibold text-xl"> {svg_question} <p className='hidden md:block'>Soporte técnico</p> </Link>
        </div>

      </div>
      <div className="container pt-10 flex flex-wrap md:flex-nowrap">

        <div className='w-full mt-2 md:mr-1 md:mt-0'>
          <div className='bg-gray-50 border w-full'>
            <h1 className='flex items-center text-base bg-gray-100 px-4 py-3 border-b'> 
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-clock">
                <circle cx="12" cy="12" r="10"/>
                <polyline points="12 6 12 12 16 14"/>
              </svg>
              Actividad reciente
            </h1>

            {recentActivity?.map((activity, index) => {
              var createdAtTMP = new Date(activity.createdAt)
              createdAtTMP = createdAtTMP.getDay() + '/' + createdAtTMP.getMonth() + '/' + createdAtTMP.getFullYear()

              return <div key={activity.id}>
                {index != 0? <hr></hr> : null}
                <div className='px-4 py-3'>
                  <div className='flex w-full justify-between'>
                    <p className='font-bold'><a href={'/usuarios/'+activity.user?.id}>{activity.user?.name}</a> - {activity.title}</p> 
                    <p className='font-bold'>{createdAtTMP}</p> 
                  </div>
                  <p dangerouslySetInnerHTML={{__html: activity.message}}></p>
                </div>
              </div>
            })}
            {!recentActivity && <div><hr></hr><p className='ml-4 my-1'>Sin actividad reciente registrada...</p></div>}
            {recentActivity.length === 50 && <div><hr></hr><p className='ml-4 mb-1'>...</p></div>}

          </div>
        </div>

        <div className='w-full md:ml-1'>
          <div className='bg-gray-50 border w-full'>
            <h1 className='flex items-center text-base bg-gray-100 px-4 py-3 border-b'> 
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                <circle cx="9" cy="7" r="4"/>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
              </svg>
              {!suscripciones.length? 'Sin suscripciones reciente' : suscripciones.length > 1? suscripciones.length+' suscripciones recientes' : suscripciones.length+' suscripció reciente'} 
            </h1>

            {suscripciones?.map((suscripcion, index) => {
              var endsAtTMP = new Date(suscripcion.endsAt)
              endsAtTMP = endsAtTMP.getDate() + '/' + (endsAtTMP.getMonth()+1) + '/' + endsAtTMP.getFullYear()

              return <div key={suscripcion.id}>
                {index != 0? <hr></hr> : null}
                <div className='px-4 py-3 flex'>
                  <p className='font-bold mr-1'><a href={'/usuarios/'+suscripcion.user?.id}>{suscripcion.user?.name} / {suscripcion.user?.email}</a></p> 
                  <p> ha pagado su suscripción hasta el {endsAtTMP}</p>
                </div>
              </div>
            })}
            {!suscripciones && <div><hr></hr><p className='ml-4 my-1'>Sin suscripciones activas registradas...</p></div>}
          </div>
        </div>

      </div>
    </>
  )
}


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getNumberOfSubscriptions: () => dispatch(getNumberOfSubscriptions()),
    lastRecentActivity: () => dispatch(lastRecentActivity()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

