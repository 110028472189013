import React from 'react'
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import { useState, useEffect } from "react";

export default function Modal({ toggle, children, actions, heading, close, danger, buttonEnabled, setButtonEnabled }) {

  return (
    <AnimatePresence>
      {toggle &&
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed z-60 left-1/2 top-1/4 -translate-x-1/2 transform p-5 bg-white border border-solid rounded-md z-40"
          >
            <header className="flex items-center space-x-2 mb-2">
              {danger &&
                <svg xmlns="http://www.w3.org/2000/svg" width={18} viewBox="0 0 20 20" fill="red">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              }
              <h2 className="font-semibold text-xl">{heading}</h2>
            </header>

            <div className="mb-4">
              {children}
            </div>

            <div className="flex justify-end space-x-2">
              <button className="hover:bg-gray-200 py-2 px-4 adwdounded" onClick={close}>Cancelar</button>
              { actions && actions.map((action, i) => <button key={i} className={`text-white py-2 px-4 rounded ${ !buttonEnabled? 'bg-red-300 cursor-not-allowed' : 'bg-red-500 hover:bg-red-400'}`} disabled={ !buttonEnabled? true : false} onClick={action.onClick}>{action.text}</button>)}
            </div>

          </motion.div>
          
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'spring', damping: 18 }}
            className="fixed z-20 top-0 left-0 w-screen h-screen bg-white bg-opacity-50" 
            onClick={close}
          />
        </>
      }
    </AnimatePresence>
  )
}