import * as types from '../constants/ActionTypes'

const videoResources = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_VIDEO_RESOURCES:
      return action.payload
    case types.NEW_VIDEO_RESOURCES:
      return action.payload
    case types.GET_VIDEO_RESOURCES:
      return action.payload
    default:
      return state
  }
}

export default videoResources
