import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllGlossaryWords } from '../actions'
import { Alert  } from 'antd';

function Subscripciones(props) {
  const { getAllGlossaryWords, glossaryWords } = props

  const svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>

  useEffect(() => {
    getAllGlossaryWords()
  }, [])


  function close() {
    localStorage.removeItem('deletedGlossaryWord')
  }

  const actions = [
    {
      label: 'Añadir palabras',
      to: '/sugeridos/nuevo',
      type: 'link'
    }
  ]

  return (
    <>
      <Filters options={['Todos']} actions={actions}/>
      <div className="container">

        {localStorage.getItem('deletedGlossaryWord')? <div className="my-4">
          <Alert
            showIcon 
            message="Palabra eliminada correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        {glossaryWords?
          <DocumentList 
            data={glossaryWords}
            fields={[
              {
                label: 'Palabra',
                name: 'word'
              },
              {
                label: 'Creada el',
                name: 'createdAt'
              },
              {
                label: 'Eliminar',
                name: 'remove'
              },
            ]} /> 
          : null }
      </div>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllGlossaryWords: () => dispatch(getAllGlossaryWords()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscripciones)