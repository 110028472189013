import { connect } from 'react-redux'
import { Route, Redirect } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from 'axios';

import Layout from "./Layout";

const PrivateRoute = (props) => {
  const { auth, component: RouteComponent, ...rest} = props
  const [permision, setPermision] = useState(null)



  useEffect(async () => {
    setPermision(null)
    if (auth) {
      const response = await axios.post(
        `${process.env.REACT_APP_API}checkUser`,
        { id: auth.uid}
      );
      setPermision(response.data)
    }
  }, [auth]);


  return (
    permision != null?
      <Route
        {...rest}
        render={(routeProps) =>
          auth.uid && permision ? (
            <Layout>
              <RouteComponent {...routeProps} />
            </Layout>
          ) : (
            auth.uid ? (
              <Redirect to={"/login/no-credentials"} />
            ) : (
              <Redirect to={"/login"} />
            )          
          )
        }
      />
    :
      null
  )
}


export default connect(state => ({ auth: state.firebase.auth }))(PrivateRoute)


