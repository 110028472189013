import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Input,  Button, Alert, Space  } from 'antd';

import { useHistory } from 'react-router-dom';
import { getChapterReview } from '../actions'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill';


const { TextArea } = Input;

function Opinion(props) {

  const svg_enable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"/></svg>;
  const svg_disable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>;
  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;
  
  const svg_star = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 7.13l.97 2.29.47 1.11 1.2.1 2.47.21-1.88 1.63-.91.79.27 1.18.56 2.41-2.12-1.28-1.03-.64-1.03.62-2.12 1.28.56-2.41.27-1.18-.91-.79-1.88-1.63 2.47-.21 1.2-.1.47-1.11.97-2.27M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2z"/></svg>
  const svg_star_filled = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/></svg>



  const history = useHistory();
  const [showDelete, setShowDelete] = useState(0);

  const [dateOfUser, setDateOfUser] = useState('')
  const [dateOfPost, setDateOfPost] = useState('')
  const [dateOfSub, setDateOfSub] = useState('')

  const { id } = props.match.params;
  const { getChapterReview, chapterReviews } = props




  useEffect(() => {
    if (chapterReviews && chapterReviews.user) {
      var user = chapterReviews.user;

      var dateTMP = new Date(user?.lastLogin);
      var hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
      var minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
      var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
      setDateOfUser(dateTMPToString)
  
      dateTMP = new Date(user?.createdAt);
      hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
      minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
      var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
      setDateOfPost(dateTMPToString)
  
  
      if (user && user?.suscriberUntilNew) {
        dateTMP = new Date(user?.suscriberUntilNew);
        hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
        minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
        var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
        setDateOfSub(dateTMPToString)
      }
    }
  }, [chapterReviews])








  useEffect(() => {
    if (id) {
      getChapterReview(id)
    }
  }, [])


  const submitResource = async (fields) => {

  };


  const validateMessages = {
    required: `Este campo es obligatorio...`,
  };


  function delete_chapter_review(state) {
    if (state) {
      history.push("/opiniones/delete/"+chapterReviews.id);
    }
  }

  
  function getStars() {
    var stars = chapterReviews.stars;
    var starsArray = [0, 0, 0, 0, 0];

    for (var i=0; i<stars; i++) {
      starsArray[i] = 1;
    }


    return <div className='flex'>
      {starsArray.map(element => {
        if (element) {
          return <div>{svg_star_filled}</div>;
        } else {
          return <div>{svg_star}</div>;
        }
      })}
    </div>
  }


  return (
    ( id && chapterReviews ) ? <div className="">
      <div className="py-2 container">




        <Form
          /* initialValues={{
            word: id && glossaryWord.word? glossaryWord.word : '', 
          }} */

          onFinish={fields => submitResource(fields)}
          validateMessages={validateMessages}
        >

          {id && showDelete? <div className="mb-4">
            <Alert
              message="¿Seguro que quieres eliminar esta suscripción?"
              type="error"
              action={
                <Space direction="horizontal">
                  <Button size="small" type="danger" onClick={() => { delete_chapter_review(1); }}>
                    Eliminar
                  </Button>
                  <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                    Cancelar
                  </Button>
                </Space>
              }
            />
          </div> : ''}


          <div className='flex w-full'>
            <div className='bg-gray-200 w-32 h-32 min-w-max mr-4 overflow-hidden rounded'>
              <div className='w-full h-full overflow-hidden'>
                {chapterReviews.user?.image?
                  <img className='object-cover w-32 h-32' id="profilePic" src={chapterReviews.user?.image} />
                  :
                  <div className='font-bold w-32 h-32 flex items-center justify-center text-4xl select-none' style={{ backgroundColor: '#DBEAFE' }}>
                    {chapterReviews.user?.name?.split(' ')[0]? chapterReviews.user?.name.split(' ')[0].charAt(0) : null}
                    {chapterReviews.user?.name?.split(' ')[1]? chapterReviews.user?.name.split(' ')[1].charAt(0) : null}
                  </div>
                }
              </div>
            </div>
            <div className='w-full mt-2'>
              <h1 className="text-4xl font-semibold w-full">{chapterReviews.user?.name}</h1>
              <h1 className="text-lg">{chapterReviews.user?.email}</h1>
              <h1 className="text">{dateOfPost != ''? 'Usuario creada el '+dateOfPost : ''}</h1>
              <h1 className="text">{dateOfUser != ''? 'Último inicio el '+dateOfUser : ''}</h1>
              <h1 className="text">{dateOfSub != ''? 'Suscrito hasta el '+dateOfSub : ''}</h1>
            </div>
          </div>


          <hr className='mt-2'></hr>

          <div className='pt-3'>

            <div className='flex items-center justify-between mb-1'>
              <h1 className='text-xl'> {chapterReviews.indice?.resource?.titulo} / <strong>{chapterReviews.indice?.title}</strong> </h1>
              {getStars()}
            </div>
            <div dangerouslySetInnerHTML={{__html: chapterReviews?.body}}></div>
            <hr className='my-2'></hr>

          </div>




        </Form>
      </div>
    </div> : null
  );
}


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getChapterReview: id => dispatch(getChapterReview(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Opinion)