import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDLehxaaQmkCndnV5oPOiH76F8bU5KvXUI",
  authDomain: "atril-develop.firebaseapp.com",
  databaseURL: "https://atril-develop.firebaseio.com",
  projectId: "atril-develop",
  storageBucket: "atril-develop.appspot.com",
  messagingSenderId: "169417414858",
  appId: "1:169417414858:web:045cc1ecb0ede78ba73c33",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });
firebase.firestore().settings({ experimentalForceLongPolling: true });
export default firebase;
