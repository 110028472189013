import { useState } from 'react'
import Navigation from './Navigation'
import Header from './Header'

function Layout({children}) {

  const [navIsCollapsed, setNavIsCollapsed] = useState(localStorage.getItem('navIsCollapsed') === "true" || false)


  return (
    <div className="grid" style={{ gridTemplateColumns: !navIsCollapsed ? '1fr' : 'auto 1fr' }}>
      <Navigation collapse={navIsCollapsed} />
      <main>
        <Header setNavIsCollapsed={setNavIsCollapsed} navIsCollapsed={navIsCollapsed} />
        {children}
      </main>
    </div>
  )

}

export default Layout