import * as types from '../constants/ActionTypes'

const glossaryWords = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_GLOSSARY_WORDS:
      return action.payload
    case types.GET_GLOSSARY_WORD:
      return action.payload
    case types.NEW_GLOSSARY_WORD:
      return action.payload
    case types.DELETE_GLOSSARY_WORD:
      return action.payload
    default:
      return state
  }
}

export default glossaryWords

