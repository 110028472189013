import * as types from '../constants/ActionTypes'
import _ from 'lodash'

const recentActivity = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_RECENT_ACTIVITY:
      return action.payload
    default:
      return state
  }
}

export default recentActivity