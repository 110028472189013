import { useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import { Form, Input, Upload, Button, Steps, Select, Alert, Tabs } from 'antd';

import RecursosDeVideoInputs from './RecursosDeVideoInputs';
import axios from 'axios';
import { getVideoResource } from '../actions'

import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const { Step } = Steps;
const { Option } = Select;
const { TabPane } = Tabs;



function RecursosDeVideoAdd(props) {

  const { id } = props.match.params;
  const { getVideoResource, videoResources } = props;

  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;
  const svg_prev = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;
  const svg_next = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_add = <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"/><line x1="5" y1="12" x2="19" y2="12"/></svg>
  const svg_empty_image = process.env.REACT_APP_API+'default.png';
  
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0)
  const [actualVideoRes, setActualVideoRes] = useState(null);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  
  const [newSectionType, setNewSectionType] = useState('');
  const [newSectionName, setNewSectionName] = useState('');
  const [newSectionPosition, setNewSectionPosition] = useState('end');
  const [enableAddButton, setEnableAddButton] = useState(false);
  const [fakeSections, setFakeSections] = useState(null);
  const [fakeSubSections, setFakeSubSections] = useState(null);
  const [sectionError, setSectionError] = useState(null);

  const [sections, setSections] = useState([]);
  const [subSections, setSubSections] = useState([]);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [author, setAuthor] = useState('');
  const [startImage, setStartImage] = useState(null);
  const [description, setDescription] = useState('');
  const [translation, setTranslation] = useState(null);
  const [language, setLanguage] = useState('esp');
  const [videos, setVideos] = useState({});





  /* Video pages */
  
  const refreshValues = (newValue, itemKey) => {
    var videosTMP = videos;
    videosTMP[itemKey] = newValue;
    setVideos(videosTMP)
  };

  const [newTabIndex, setNewTabIndex] = useState(1);
  const [activeKey, setActiveKey] = useState('1');
  const initialPanes = [ { title: 'Video inicial', content: <RecursosDeVideoInputs num={0} refreshValues={refreshValues}  />, key: '1', closable: false } ];
  const [panes, setPanes] = useState(initialPanes);



  function createPanes(videosTMP) {
    var panesTMP = [];
    var newTabIndexTMP = newTabIndex;

    Object.keys(videosTMP).map( function(key) {
      var splitKey = key.split('-')
      if (splitKey[1] === 'title') {

        var titleTMP = videosTMP[key];
        var subtitleTMP = videosTMP['video-subtitle-'+splitKey[2]];
        var descriptionTMP = videosTMP['video-description-'+splitKey[2]];
        var linkTMP = videosTMP['video-link-'+splitKey[2]];

        var sectionSlugTMP = videosTMP['section-slug-'+splitKey[2]];
        var startPositionTMP = videosTMP['start-position-'+splitKey[2]];
        var endPositionTMP = videosTMP['end-position-'+splitKey[2]];

        var dataTMP = { titleTMP: titleTMP, subtitleTMP: subtitleTMP, descriptionTMP: descriptionTMP, linkTMP: linkTMP, sectionSlugTMP: sectionSlugTMP, startPositionTMP: startPositionTMP, endPositionTMP: endPositionTMP }

        if (panesTMP.length < 1) {
          panesTMP.push({ 
            title: titleTMP, 
            content: <RecursosDeVideoInputs dataTMP={dataTMP} num={0} refreshValues={refreshValues}  />, 
            key: '1', 
            closable: false 
          })
          newTabIndexTMP++;
          setNewTabIndex(newTabIndexTMP);
          setPanes(panesTMP);
        } else {
          var newActiveKey = `newTab${newTabIndexTMP+1}`;
          panesTMP.push({ 
            title: titleTMP, 
            content: <RecursosDeVideoInputs dataTMP={dataTMP} num={newTabIndexTMP} refreshValues={refreshValues}  />, 
            key: newActiveKey 
          })
          newTabIndexTMP++;
          setNewTabIndex(newTabIndexTMP);
          setPanes(panesTMP);
        }


      }
    });

  }






  useEffect(() => {
    if (id) {
      getVideoResource(id)
    } else {
      setActualVideoRes(null)
      localStorage.removeItem('sectionsTMP')
      localStorage.removeItem('subSectionsTMP')
    }
  }, [])
  

  useEffect(() => {
    if (videoResources) {
      setActualVideoRes(videoResources)
    } else {
      setActualVideoRes(null)
      localStorage.removeItem('sectionsTMP')
      localStorage.removeItem('subSectionsTMP')
    }
  }, [videoResources])


  useEffect(() => {
    if (actualVideoRes && actualVideoRes.videos) {
      setTitle(actualVideoRes.title)
      setSubtitle(actualVideoRes.subtitle)
      setAuthor(actualVideoRes.author)
      setDescription(actualVideoRes.description)
      setTranslation(actualVideoRes.translation_of)
      setLanguage(actualVideoRes.language)

      var sectionsTMP = JSON.parse(actualVideoRes.sections)
      setSections(sectionsTMP)
      localStorage.setItem('sectionsTMP', actualVideoRes.sections)

      var subSectionsTMP = JSON.parse(actualVideoRes.subSections)
      setSubSections(subSectionsTMP)
      localStorage.setItem('subSectionsTMP', actualVideoRes.subSections)

      var videosTMP = JSON.parse(actualVideoRes.videos)
      setVideos(videosTMP)
      document.getElementById('imgshow1').src = id && actualVideoRes && actualVideoRes.image? process.env.REACT_APP_API+actualVideoRes.image : svg_empty_image;
      document.getElementById('imgshow2').src = id && actualVideoRes && actualVideoRes.image? process.env.REACT_APP_API+actualVideoRes.image : svg_empty_image;
      
      if (videosTMP) {
        createPanes(videosTMP);
      }

    } else {
      setTitle('')
      setSubtitle('')
      setAuthor('')
      setDescription('')
      setTranslation(null)
      setLanguage('esp')
      setSections([])
      localStorage.removeItem('sectionsTMP')
      setSubSections([])
      localStorage.removeItem('subSectionsTMP')
      setVideos({})
    }
  }, [actualVideoRes])


  const propsUpload = {
    maxCount: 1,
    multiple: false,
    beforeUpload: file => {
      setStartImage(file);

      var reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById('imgshow1').src = e.target.result;
        document.getElementById('imgshow2').src = e.target.result;
      }
      reader.readAsDataURL(file);

      return false;
    },
    onRemove: (file, list) => {
      setStartImage(null);
      document.getElementById('imgshow1').src = id && actualVideoRes && actualVideoRes.image? process.env.REACT_APP_API+actualVideoRes.image : svg_empty_image;
      document.getElementById('imgshow2').src = id && actualVideoRes && actualVideoRes.image? process.env.REACT_APP_API+actualVideoRes.image : svg_empty_image;
    }
  };

    
  const validateMessages = {
    required: `Este campo es obligatorio...`,
  };


  /* Video pages */
  function onChange(newActiveKey) {
    setActiveKey(newActiveKey)
  };


  function onEdit(targetKey, action) {    
    if (action === 'add') {
      add(targetKey);
    } else if (action === 'remove') {
      remove(targetKey);
    }
  };


  function add() {
    var newActiveKey = `newTab${newTabIndex+1}`;
    var newPanes = [...panes];

    newPanes.push({ title: 'Nuevo vídeo', content: <RecursosDeVideoInputs num={newTabIndex} refreshValues={refreshValues}  />, key: newActiveKey });

    setNewTabIndex(newTabIndex+1);
    setPanes(newPanes);
    setActiveKey(newActiveKey);
  };
  

  function remove(targetKey) {
    let newActiveKey = activeKey;
    let lastIndex;

    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });

    var newPanes = panes.filter(pane => pane.key !== targetKey);

    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }

    setPanes(newPanes);
    setActiveKey(newActiveKey);
  };


  

  const onFinish = (values) => {
    
    setEnableSaveButton(true)
    
    var titleTMP = title && title.replaceAll(' ', '') != ''? title : null;
    var subtitleTMP = subtitle && subtitle.replaceAll(' ', '') != ''? subtitle : null;
    var authorTMP = author && author.replaceAll(' ', '') != ''? author : null;
    var languageTMP = language && language.replaceAll(' ', '') != ''? language : 'esp';
    var translationTMP = translation && translation.replaceAll(' ', '') != ''? startImage : null;
    var descriptionTMP = description && description.replaceAll(' ', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<br>', '').replaceAll('</br>', '').replaceAll('<h1>', '').replaceAll('</h1>', '').replaceAll('<h2>', '').replaceAll('</h2>', '').replaceAll('<h3>', '').replaceAll('</h3>', '') != ''? description : null;
    var sectionsTMP = sections? JSON.stringify(sections) : null;
    var subSectionsTMP = subSections? JSON.stringify(subSections) : null;
    var videosTMP = videos? JSON.stringify(videos) : null;

    if (id) {
      const formData = {
        id: id,
        title: titleTMP,
        subtitle: subtitleTMP,
        author: authorTMP,
        language: languageTMP,
        translation_of: translationTMP,
        description: descriptionTMP,
        sections: sectionsTMP,
        subSections: subSectionsTMP,
        videos: videosTMP,
      };
      axios.post(
        `${process.env.REACT_APP_API}recursoDeVideo`,
        formData
      ).then( async (response) => {

        if (startImage) {
          const imageFormData = new FormData();
          imageFormData.append('id', response.data.id);
          imageFormData.append('file', startImage);
          await axios.post(
            `${process.env.REACT_APP_API}uploadRecursoDeVideoImage`,
            imageFormData
          );
        }

        localStorage.removeItem('sectionsTMP')
        localStorage.removeItem('subSectionsTMP')
        history.push('/recursos-de-video');

      });
    } else {
      const formData = {
        title: titleTMP,
        subtitle: subtitleTMP,
        author: authorTMP,
        language: languageTMP,
        translation_of: translationTMP,
        description: descriptionTMP,
        sections: sectionsTMP,
        subSections: subSectionsTMP,
        videos: videosTMP,
      };
      axios.post(
        `${process.env.REACT_APP_API}recursoDeVideo`,
        formData
      ).then( async (response) => {

        if (startImage) {
          const imageFormData = new FormData();
          imageFormData.append('id', response.data.id);
          imageFormData.append('file', startImage);
          await axios.post(
            `${process.env.REACT_APP_API}uploadRecursoDeVideoImage`,
            imageFormData
          );
        }
        
        localStorage.removeItem('sectionsTMP')
        localStorage.removeItem('subSectionsTMP')
        history.push('/recursos-de-video');

      });
    }

  };




  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    var items = reorder(
      sections,
      result.source.index,
      result.destination.index
    );

    const itemWithNewPosition = items.map((item, index) => ({
      ...item,
      position: index,
    }));

    setSections(itemWithNewPosition);
    localStorage.setItem('sectionsTMP', JSON.stringify(itemWithNewPosition))
    reorderSubSections()
  };




  function reorderSubSections() {
    if (subSections && subSections.length > 0) {
      var subSectionsTMP = [];

      sections.map(section => {
        var posTMP = 0;
        subSections.map(subSection => {
          var subSectionTMP = subSection
          if (subSection.parent === section.slug) {
            posTMP++;
            subSectionTMP['position'] = posTMP;
            subSectionsTMP.push(subSectionTMP)
          }
        });
      });
  
      setSubSections(subSectionsTMP)
      localStorage.setItem('subSectionsTMP', JSON.stringify(subSectionsTMP))
    }
  }

  function addSectionFunc() {
    /* newSectionType newSectionPosition newSectionName */
    var newSectionTMP = {}
    newSectionTMP['slug'] = newSectionName?.trim()?.replaceAll(' ', '_')?.toLowerCase()?.normalize("NFD");
    newSectionTMP['title'] = newSectionName;
    setSectionError(null);

    if (!newSectionType) {
      var sectionsTMP = sections;
      sectionsTMP.push(newSectionTMP)
      setFakeSections(sectionsTMP)
      checkRepeated()
      setNewSectionType('')
      setNewSectionPosition('end')
      setNewSectionName('')
    } else {
      newSectionTMP['parent'] = newSectionType;
      var sectionsTMP = subSections;

      if (newSectionPosition === 'end') {
        sectionsTMP.push(newSectionTMP)
      } else if (newSectionPosition === 'start') {
        sectionsTMP.splice(0, 0, newSectionTMP);
      } else {
        sectionsTMP.map((element, index) => {
          if (newSectionPosition === element.slug) {
            newSectionTMP['parent'] = newSectionType;
            sectionsTMP.splice((index + 1), 0, newSectionTMP);
          }
        });
      }

      setFakeSubSections(sectionsTMP)

      setNewSectionType('')
      setNewSectionPosition('end')
      setNewSectionName('')
      setSectionError(null)

      checkRepeated()
      reorderSubSections()
    }
  }

  function checkRepeated() {

    var enable = true;
    var error = null;
    var sectionSlugTMP = newSectionName?.trim()?.replaceAll(' ', '_')?.toLowerCase()?.normalize("NFD");

    if (sections && sections.length > 0) {
      sections?.map(element => {
        if (element.slug === sectionSlugTMP) {
          enable = false;
          error = 1;
        }
      });
    }

    if (subSections && subSections.length > 0) {
      subSections?.map(element => {
        if (element.slug === sectionSlugTMP) {
          enable = false;
          error = 2;
        }
      });
    }

    if (!newSectionName || newSectionName.replaceAll(' ', '') === '') {
      enable = false
    }

    setSectionError(error)
    setEnableAddButton(enable)
  }

  useEffect(() => {
    if (fakeSections) {
      setSections(fakeSections)
      localStorage.setItem('sectionsTMP', JSON.stringify(fakeSections))
      setFakeSections(null)
    }
  }, [fakeSections])

  useEffect(() => {
    if (fakeSubSections) {
      setSubSections(fakeSubSections)
      localStorage.setItem('subSectionsTMP', JSON.stringify(fakeSubSections))
      setFakeSubSections(null)
    }
  }, [fakeSubSections])


  return (
    <div className="">
      <Form
      onFinish={onFinish} 
      validateMessages={validateMessages} 
      className="py-2 container"
      >

        <div className='px-5 py-5'>
          <Steps current={currentStep} onChange={setCurrentStep}>
            <Step title="Portada" />
            <Step title="Unidades" />
            <Step title="Vídeos" />
            <Step title="Revisión" />
          </Steps>
        </div>



        <div className={`${currentStep != 0 && 'hidden'}`}>
          <div className='flex w-full'>


            <div className="rounded-sm border overflow-hidden mr-2 mb-2" style={{ minWidth: '480px', minHeight: '270px' }}>
              <img id="imgshow1" className={'object-cover'} style={{ width: '480px', height: '270px' }} src={svg_empty_image} />
            </div>


            <div className='w-full'>
              <div className='w-full mb-2'>
                <label className='whitespace-nowrap'>Título del recurso:</label>
                <Input id="title" name="title" placeholder="Título del recurso..." value={title} onChange={ (event) => setTitle(event.target.value) } rules={[{ required: true }]}/>
              </div>



              <div className='flex space-x-2 mb-2'>
                <div className='w-full'>
                  <label className='whitespace-nowrap'>Subtítulo del recurso:</label>
                  <Input id="subtitle" name="subtitle" placeholder="Subtítulo del recurso..." value={subtitle} onChange={ (event) => setSubtitle(event.target.value) }/>
                </div>

                <div className='w-full'>
                  <label className='whitespace-nowrap'>Autor del recurso:</label>
                  <Input id="author" name="author" placeholder="Autor del recurso..." value={author} onChange={ (event) => setAuthor(event.target.value) } rules={[{ required: true }]}/>
                </div>
              </div>



              <div className='flex space-x-2 mb-2'>
                <div className='w-full'>
                  <label className='whitespace-nowrap'>Idioma del recurso:</label>
                  <Select id="author" name="author" defaultValue={'esp'} placeholder="Selecciona el idioma del recurso" value={language} onChange={ setLanguage } className='w-full'>
                    <Option value='esp'>Español</Option>
                    <Option value='eng'>Inglés</Option>
                  </Select>
                </div>

                <div className='w-full'>
                  <label className='whitespace-nowrap'>Este recurso es una traducción de:</label>
                  <Select id="author" name="author" placeholder="Este recurso es una traducción de..." value={translation} onChange={ setTranslation } className='w-full'>
                    <Option value={null}>No es una traducción</Option>
                  </Select>
                </div>
              </div>

              <div className='w-full'>
                <Upload id="imgload" {...propsUpload}>
                  <Button icon={<UploadOutlined />}>Subir portada del recurso</Button>
                </Upload>
              </div>
            </div>


          </div>


          <div className="mt-2">
            <label>Descripción del recurso: </label>
            <ReactQuill id="description" name="description" theme="snow" placeholder='Descripción del recurso...' value={description? description : ''} onChange={setDescription} className="h-96"/>
          </div>


          <div className='flex justify-between mt-12'>
            <Button htmlType="button" size="large" type="primary" disabled={true}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_prev}</div>
                <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
              </div>
            </Button>

            <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep+1)}>
              <div className='flex items-center'>
                <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                <div className='flex items-center ml-2'>{svg_next}</div>
              </div>
            </Button>
          </div>
            
        </div>




        <div className={`${currentStep != 1 && 'hidden'}`}>
          <div className='flex w-full space-x-2'>

              <div className='w-full max-w-xs'>
                <label className='whitespace-nowrap'>Tipo unidad:</label>
                <Select placeholder="Seleccione un tipo" value={newSectionType} onChange={ (value) => { setNewSectionType(value); setNewSectionPosition('end')} } className='w-full'>
                  <Option value=''>Nueva unidad principal</Option>
                  {sections && sections.length > 0 && sections?.map(element => {
                    return <Option key={element.slug} value={element.slug}>Sección de {element.title}</Option>
                  })}
                </Select>
              </div>

              {newSectionType && <div className='w-full max-w-xs'>
                <label className='whitespace-nowrap'>Posición:</label>
                <Select placeholder="Seleccione un tipo" value={newSectionPosition} onChange={setNewSectionPosition} className='w-full'>
                  <Option value='start'>Al inicio de la unidad</Option>
                  {subSections.map(element => {
                    if (element.parent === newSectionType) {
                      return <Option key={element.slug} value={element.slug}>Después de {element.position}. {element.title}</Option>
                    }
                  })}
                  <Option value='end'>Al final de la unidad</Option>
                </Select>
              </div>}

              <div className='w-full'>
                <label className='whitespace-nowrap'>Nombre de la nueva unidad:</label>
                <Input placeholder="Nombre de la nueva unidad..." value={newSectionName} onKeyUp={checkRepeated} onChange={ (event) => setNewSectionName(event.target.value) } rules={[{ required: true }]}/>
              </div>

              <div className='flex items-end'>
                <Button htmlType="button" size="large" type="primary" disabled={!enableAddButton} style={{ paddingInline: '10px', paddingBlock: '0px', height: '31px', margin: '1px' }} onClick={addSectionFunc}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_add}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Añadir</div>
                  </div>
                </Button>
              </div>
          </div>

          <div>
            {newSectionName && newSectionName.replaceAll(' ', '') != '' && (sectionError === 1 || sectionError === 2) && 
              <Alert
                message="El título que intanta introducir ya esta siendo útilizado en este mismo recurso."
                type="error"
                className='mt-2'
              />
            }
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`${snapshot.isDraggingOver ? `` : undefined}`}
                >
                  <div
                    className={`py-2 mt-1 flex justify-start items-center ${
                      snapshot.isDragging && `bg-gray-100 bg-opacity-50`
                    }`}
                  ></div>
                  {sections && sections.length > 0 && sections?.map((section, index) => {                    
                    return <Draggable
                      key={section.slug}
                      draggableId={section.slug}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="w-full flex items-center bg-gray-200 mb-1"
                        >
                          <div className='text-gray-400 p-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M7.542 16.667Q6.833 16.667 6.333 16.167Q5.833 15.667 5.833 14.958Q5.833 14.25 6.333 13.75Q6.833 13.25 7.542 13.25Q8.25 13.25 8.75 13.75Q9.25 14.25 9.25 14.958Q9.25 15.667 8.75 16.167Q8.25 16.667 7.542 16.667ZM7.542 11.708Q6.833 11.708 6.333 11.208Q5.833 10.708 5.833 10Q5.833 9.292 6.333 8.792Q6.833 8.292 7.542 8.292Q8.25 8.292 8.75 8.792Q9.25 9.292 9.25 10Q9.25 10.708 8.75 11.208Q8.25 11.708 7.542 11.708ZM7.542 6.75Q6.833 6.75 6.333 6.25Q5.833 5.75 5.833 5.042Q5.833 4.333 6.333 3.833Q6.833 3.333 7.542 3.333Q8.25 3.333 8.75 3.833Q9.25 4.333 9.25 5.042Q9.25 5.75 8.75 6.25Q8.25 6.75 7.542 6.75ZM12.458 6.75Q11.75 6.75 11.25 6.25Q10.75 5.75 10.75 5.042Q10.75 4.333 11.25 3.833Q11.75 3.333 12.458 3.333Q13.167 3.333 13.667 3.833Q14.167 4.333 14.167 5.042Q14.167 5.75 13.667 6.25Q13.167 6.75 12.458 6.75ZM12.458 11.708Q11.75 11.708 11.25 11.208Q10.75 10.708 10.75 10Q10.75 9.292 11.25 8.792Q11.75 8.292 12.458 8.292Q13.167 8.292 13.667 8.792Q14.167 9.292 14.167 10Q14.167 10.708 13.667 11.208Q13.167 11.708 12.458 11.708ZM12.458 16.667Q11.75 16.667 11.25 16.167Q10.75 15.667 10.75 14.958Q10.75 14.25 11.25 13.75Q11.75 13.25 12.458 13.25Q13.167 13.25 13.667 13.75Q14.167 14.25 14.167 14.958Q14.167 15.667 13.667 16.167Q13.167 16.667 12.458 16.667Z"/></svg>
                          </div>

                          <div className='w-full bg-gray-100 p-2'>
                            <p className='font-semibold' style={{ fontSize: '20px' }}>{index + 1}. {section.title}</p>

                            {subSections.map((subSection) => {
                              if (subSection.parent === section.slug) {
                                return <p key={subSection.slug} className='ml-3' style={{ fontSize: '15px' }}>{index + 1}.{subSection.position}. {subSection.title}</p>
                              }
                            })}

                          </div>
                        </div>
                      )}

                    </Draggable> 
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>




          <div className='flex justify-between mt-5'>
            <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_prev}</div>
                <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
              </div>
            </Button>

            <Button htmlType="button" size="large" type="primary" disabled={!(sections && sections.length > 0)} onClick={ () => setCurrentStep(currentStep+1)}>
              <div className='flex items-center'>
                <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                <div className='flex items-center ml-2'>{svg_next}</div>
              </div>
            </Button>
          </div>
            
        </div>





        <div className={`${currentStep != 2 && 'hidden'}`}>

          <Tabs
            type="editable-card"
            animated={true}
            onChange={onChange}
            activeKey={activeKey}
            onEdit={onEdit}
          >
            {panes?.map(pane => (
              <TabPane 
                tab={pane.title} 
                key={pane.key} 
                closable={pane.closable}>
                {pane.content}
              </TabPane>
            ))}
          </Tabs>



          <div className='flex justify-between mt-2'>
            <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_prev}</div>
                <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
              </div>
            </Button>

            <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep+1)}>
              <div className='flex items-center'>
                <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                <div className='flex items-center ml-2'>{svg_next}</div>
              </div>
            </Button>
          </div>


        </div>



        <div className={`${currentStep != 3 && 'hidden'}`}>

          {/* 
          title
          subtitle
          author
          startImage
          description
          translation
          language 
          */}


          <div className='flex w-full flex-wrap'>

            <h1 className='w-full text-4xl'>{title && title.replaceAll(' ', '')? title : 'Falta por definir un título.'}</h1>

            <div className="rounded-sm border overflow-hidden relative w-full my-2" style={{ paddingTop: '56.25%' }}>
              <img id="imgshow2" className={'object-cover w-full h-full absolute top-0 right-0 left-0 bottom-0'} src={svg_empty_image} />
            </div>

            {subtitle && subtitle.replaceAll(' ', '') && <h1 className='w-full text-2xl leading-3 mt-4'>{subtitle}</h1>}
            <h1 className='w-full mb-4'>{author && author.replaceAll(' ', '')? 'Creado por '+author : 'Falta por definir un autor.'}</h1>
            {description && <div dangerouslySetInnerHTML={{__html: description}} className='initial max-h-80 overflow-auto'></div>}

          </div>




          <div className='flex justify-start my-4'>
            {panes.map(element => {
              return <div key={element.key} className="bg-gray-100 mr-2 rounded px-3 py-2">
                {element.title}
              </div>
            })}
          </div>


            


          <div className='flex justify-between mt-2'>
            <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_prev}</div>
                <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
              </div>
            </Button>

            <Button htmlType="submit" size="large" type="primary" disabled={enableSaveButton}>
              <div className='flex items-center'>
                <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                <div className='flex items-center ml-2'>{svg_save}</div>
              </div>
            </Button>
          </div>


        </div>



      </Form>
    </div>
  );

}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getVideoResource: id => dispatch(getVideoResource(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecursosDeVideoAdd)
