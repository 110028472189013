import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllPlans, getAllPlanCategorias } from '../actions'
import { Alert  } from 'antd';

function Planes(props) {
  const { getAllPlans, plans, getAllPlanCategorias, planCategorias } = props
  const [filter, setFilter] = useState(0)
  const [optionsTMP, setOptionsTMP] = useState([])
  const [idOptionsTMP, setIdOptionsTMP] = useState([])

  const svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>

  useEffect(() => {
    getAllPlans()
    getAllPlanCategorias()
  }, [])

  useEffect(() => {
    setOptionsTMP(['Todos']);
    setIdOptionsTMP([[0, 'Todos']])
    planCategorias.map(element => {
      setOptionsTMP(optionsTMP => [...optionsTMP, element.name]);
      setIdOptionsTMP(idOptionsTMP => [...idOptionsTMP, [element.id, element.name]]);
    });
  }, [planCategorias])


  function close() {
    localStorage.removeItem('deletedPlan')
  }

  
  const actions = [
    {
      label: 'Nuevo plan',
      to: '/planes/nuevo',
      type: 'link'
    },
    {
      label: 'Categorias',
      to: '/plancategorias',
      type: 'link'
    }
  ]

  return (
    <>
      <Filters options={optionsTMP} setFilter={setFilter} actions={actions} />
      <div className="container">

        {localStorage.getItem('deletedPlan')? <div className="my-4">
          <Alert
            showIcon 
            message="Plan de lectura eliminado correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        <DocumentList 
          data={plans}
          other={idOptionsTMP}
          filter={filter}
          fields={[
            {
              label: 'Titulo',
              name: 'titulo'
            },
            {
              label: 'Categoría',
              name: 'categoriaId'
            },
            {
              label: 'Idioma',
              name: 'language'
            },
            {
              label: 'Creada el',
              name: 'createdAt'
            },
            
          ]} />
      </div>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    /* getAllBooks: () => dispatch(getAllBooks()), */
    getAllPlans: () => dispatch(getAllPlans()),
    getAllPlanCategorias: () => dispatch(getAllPlanCategorias())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Planes)