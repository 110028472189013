import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';
import SignIn from './components/SignIn';
import UserSettings from './components/settings/';
import SettingsLayout from './components/settings/layout';
import SettingsApearance from './components/settings/appearance';
import SettingsGeneral from './components/settings/general';

import Users from './pages/users';
import User from './pages/user';
import UserDelete from './pages/userDelete';

import Recursos from './pages/recursos';
import RecursosAdd from './components/RecursosAdd';
import RecursoDelete from './pages/recursoDelete';

import RecursosDeVideo from './pages/recursosDeVideo';
import RecursosDeVideoAdd from './components/RecursosDeVideoAdd';
/* import RecursoDelete from './pages/recursoDelete'; */

import Notifications from './pages/notifications';
import Notification from './pages/notification';
import NotificationDelete from './pages/notificationDelete';

import Planes from './pages/planes';
import PlanDelete from './pages/planDelete';
import PlanAdd from './components/PlanAdd';

import PlanCategorias from './pages/planCategorias';
import PlanCategoria from './pages/planCategoria';
import PlanCategoriaDelete from './pages/planCategoriaDelete';

import Soporte from './pages/soporte';
import SoporteMsgs from './pages/soporteMsgs';

import Sugeridos from './pages/sugeridos';
import Sugerido from './pages/sugerido';
import SugeridoDelete from './pages/sugeridoDelete';

import Suscripciones from './pages/suscripciones';
import Suscripcion from './pages/suscripcion';
import RegistroSuscriptciones from './pages/registroDeSuscripciones';
import RegistroSuscriptcionesRedirect from './pages/registroDeSuscripcionesRedirect';

import Emails from './pages/emails';
import EmailTemplate from './pages/emailTemplate';

import Opiniones from './pages/opiniones';
import Opinion from './pages/opinion';

import OpinionesAprobadas from './pages/opinionesAprobadas';
import OpinionAprobada from './pages/opinionAprobada';
import OpinionesAprobadasDelete from './pages/opinionesAprobadasDelete';


function App() {

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" exact component={Dashboard} />
        

        <PrivateRoute path="/recursos" exact component={Recursos} />
        <PrivateRoute path="/recursos/nuevo" exact component={RecursosAdd} />
        <PrivateRoute path="/recursos/:slug" exact component={RecursosAdd} />
        <PrivateRoute path="/recursos/delete/:id" exact component={RecursoDelete} />


        <PrivateRoute path="/recursos-de-video" exact component={RecursosDeVideo} />
        <PrivateRoute path="/recursos-de-video/nuevo" exact component={RecursosDeVideoAdd} />
        <PrivateRoute path="/recursos-de-video/:id" exact component={RecursosDeVideoAdd} />
        {/* <PrivateRoute path="/recursos/delete/:id" exact component={RecursoDelete} /> */}


        <PrivateRoute path="/usuarios" exact component={Users} />
        <PrivateRoute path="/usuarios/:id" exact component={User} />
        <PrivateRoute path="/usuarios/delete/:id" exact component={UserDelete} />


        <PrivateRoute path="/notificaciones" exact component={Notifications} />
        <PrivateRoute path="/notificaciones/:id" exact component={Notification} />
        <PrivateRoute path="/notificaciones/delete/:id" exact component={NotificationDelete} />
        

        <PrivateRoute path="/planes" exact component={Planes} />
        <PrivateRoute path="/planes/nuevo" exact component={PlanAdd} />
        <PrivateRoute path="/planes/:slug" exact component={PlanAdd} />
        <PrivateRoute path="/planes/delete/:id" exact component={PlanDelete} />


        <PrivateRoute path="/plancategorias" exact component={PlanCategorias} />
        <PrivateRoute path="/plancategorias/:id" exact component={PlanCategoria} />
        <PrivateRoute path="/plancategorias/delete/:id" exact component={PlanCategoriaDelete} />


        <PrivateRoute path="/soporte" exact component={Soporte} />
        <PrivateRoute path="/soporte/:id" exact component={SoporteMsgs} />


        <PrivateRoute path="/suscripciones" exact component={Suscripciones} />
        <PrivateRoute path="/suscripciones/nuevo" exact component={Suscripcion} />
        <PrivateRoute path="/suscripciones/:slug" exact component={Suscripcion} />


        <PrivateRoute path="/sugeridos" exact component={Sugeridos} />
        <PrivateRoute path="/sugeridos/nuevo" exact component={Sugerido} />
        <PrivateRoute path="/sugeridos/:id" exact component={Sugerido} />
        <PrivateRoute path="/sugeridos/delete/:id" exact component={SugeridoDelete} />

        <PrivateRoute path="/opiniones-aprobadas" exact component={OpinionesAprobadas} />
        <PrivateRoute path="/opiniones-aprobadas/nuevo" exact component={OpinionAprobada} />
        <PrivateRoute path="/opiniones-aprobadas/:id" exact component={OpinionAprobada} />
        <PrivateRoute path="/opiniones-aprobadas/delete/:id" exact component={OpinionesAprobadasDelete} />

        <PrivateRoute path="/opiniones" exact component={Opiniones} />
        <PrivateRoute path="/opiniones/:id" exact component={Opinion} />

        <PrivateRoute path="/registros" exact component={RegistroSuscriptciones} />
        <PrivateRoute path="/registros/:id" exact component={RegistroSuscriptcionesRedirect} />


        <PrivateRoute path="/emails" exact component={Emails} />
        <PrivateRoute path="/emails/:slug" exact component={EmailTemplate} />


        <Route path="/login" component={SignIn} />
        <Route path="/login/:error" component={SignIn} />


        <SettingsLayout>
          <PrivateRoute path="/settings" component={UserSettings} />
          <PrivateRoute path="/settings/general" component={SettingsGeneral} />
          <PrivateRoute path="/settings/apariencia" component={SettingsApearance} />
        </SettingsLayout>
      </Switch>
    </Router>
  );
}

export default App;
