import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getNotification, publishNotification, updateNotification } from '../actions'
import { Modal, Button, Form, Input, Alert, Space, Steps } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';

const { TextArea } = Input
const { Step } = Steps;

function Notification(props){

  const { notifications, getNotification, publishNotification, updateNotification } = props
  const { id } = props.match.params
  const [notification, setNotification] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [input, setInput] = useState(null)
  const [showDelete, setShowDelete] = useState(0);
  const [currentStep, setCurrentStep] = useState(0)

  const history = useHistory();
  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>
  const svg_next = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_prev = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;




  function delete_notification(state) {
    if (state) {
      history.push("/notificaciones/delete/"+notification.id);
    }
  }


  useEffect(() => {
    const isStored = notifications.some(n => n.id === id)
    if (!isStored) getNotification(id)
  }, [])

  useEffect(() => {
    if (id) setNotification(notifications.find(n => n.id === id))
  }, [notifications])

  const createdAt = new Date(notification?.createdAt)

  const _updateNotification = () => {
    setCurrentStep(0);

    updateNotification({
      id,
      ...input
    })
    setIsModalVisible(false)
  }

  const onChange = event => {
    setInput({ ...input, [event.target.id]: event.target.value })
  }

  return (
    <>
      {showDelete? <div className="">
        <Alert
          message="¿Seguro que quieres eliminar esta notificación?"
          type="error"
          action={
            <Space direction="horizontal">
              <Button size="small" type="danger" onClick={() => { delete_notification(1); }}>
                Eliminar
              </Button>
              <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                Cancelar
              </Button>
            </Space>
          }
        />
      </div> : ''}

      <header className="py-8 bg-gray-100">
        <div className="container flex items-center space-x-5">
          <div className="flex-1">
            <h1 className="text-3xl font-semibold">{id && <p>Creada el {createdAt.getDate()}/{createdAt.getMonth()+1}/{createdAt.getFullYear()}</p>}</h1>
          </div>
          <div>
            <div className="flex justify-center items-center">
              <Button className="mr-2" icon={<EditOutlined />} onClick={() => setIsModalVisible(true)} disabled={Boolean(notification?.isPublished)} >
                Editar
              </Button>
              <Button danger className='mr-2' onClick={() => { setShowDelete(1) }} disabled={Boolean(notification?.isPublished)}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_trash}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Eliminar</div>
                </div>
              </Button>
              <Button onClick={() => publishNotification(notification.id)} disabled={Boolean(notification?.isPublished)} type="primary">
                {Boolean(notification?.isPublished) ? 'Publicada' : 'Publicar'}
              </Button>
            </div>
          </div>
        </div>
      </header>
      <main className="container py-10">

        <div className='bg-gray-100 p-4 rounded'>
          <h1 className='text-xl mb-2'>{notification?.title? 'Notificación en español:' : ''}</h1>
          <p>{notification?.title}</p>
          <p>{notification?.body}</p>
          <p>Link: <a href={notification?.link}>{notification?.link}</a></p>
        </div>

        <div className='bg-gray-100 p-4 rounded mt-2'>
          <h1 className='text-xl mb-2'>{notification?.titleENG? 'Notificación en inglés:' : ''}</h1>
          <p>{notification?.titleENG}</p>
          <p>{notification?.bodyENG}</p>
          <p>Link: {notification?.linkENG? <a href={notification.linkENG}>{notification.linkENG}</a> : 'Sin link'}</p>
        </div>

      </main>



      {/* <Modal
        title="Editar notificación"
        visible={isModalVisible}
        onOk={_updateNotification}
        onCancel={() => setIsModalVisible(false)}
        okText="Actualizar"
        destroyOnClose
        >
        <Form
          preserve={false}
          onChange={onChange}
        >
          <Form.Item>
            <Input id="title" defaultValue={notification?.title} placeholder="Título" />
          </Form.Item>
          <Form.Item>
            <TextArea
              id="body"
              defaultValue={notification?.body}
              placeholder="Texto"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item>
            <Input id="link" defaultValue={notification?.link} placeholder="Link" />
          </Form.Item>
        </Form>
      </Modal> */}



      <Modal
        title="Editar notificación"
        visible={isModalVisible}
        onOk={_updateNotification}
        onCancel={() => { setIsModalVisible(false); setCurrentStep(0) }}
        okText="Actualizar notificación"
        cancelText="Cancelar"
        okButtonProps={{ disabled: currentStep === 1? false : true }}
        destroyOnClose
        >
        <Form
          preserve={false}
          onChange={onChange}
        >
          <div className='px-2 pb-7'>
            <Steps current={currentStep} onChange={setCurrentStep}>
              <Step title="Español" />
              <Step title="Inglés" />
            </Steps>
          </div>

          <div className='mb-3' style={{ display: currentStep === 0? 'inherit' : 'none' }}>
            <Form.Item>
              <Input id="title" defaultValue={notification?.title} placeholder="Título en español..." />
            </Form.Item>
            <Form.Item>
              <TextArea
                id="body"
                defaultValue={notification?.body}
                placeholder="Cuerpo en español..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item>
              <Input id="link"defaultValue={notification?.link} placeholder="Link para la notificación en español..." />
            </Form.Item>

            <div className='w-full flex justify-between'>
              <Button htmlType="button" size="large" type="primary" disabled={true}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep+1)}>
                <div className='flex items-center'>
                  <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                  <div className='flex items-center ml-2'>{svg_next}</div>
                </div>
              </Button>
            </div>
          </div>

          <div className='mb-3' style={{ display: currentStep === 1? 'inherit' : 'none' }}>
            <Form.Item>
              <Input id="titleENG" defaultValue={notification?.titleENG} placeholder="Título en inglés..." />
            </Form.Item>
            <Form.Item>
              <TextArea
                id="bodyENG"
                defaultValue={notification?.bodyENG}
                placeholder="Cuerpo en inglés..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item>
              <Input id="linkENG" defaultValue={notification?.linkENG} placeholder="Link para la notificación en inglés..." />
            </Form.Item>

            <div className='w-full flex justify-between'>
              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              <Button htmlType="button" size="large" type="primary" disabled={true}>
                <div className='flex items-center'>
                  <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                  <div className='flex items-center ml-2'>{svg_next}</div>
                </div>
              </Button>
            </div>
          </div>


        </Form>
      </Modal>



    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getNotification: id => dispatch(getNotification(id)),
    publishNotification: id => dispatch(publishNotification(id)),
    updateNotification: input => dispatch(updateNotification(input)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)