import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Input,  Button, Alert, Space, Select, Upload, Tabs } from 'antd';

import { useHistory } from 'react-router-dom';
import { getApprovedChapterReview, getAllBooks } from '../actions'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';


const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

function Opinion(props) {

  const svg_enable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"/></svg>;
  const svg_disable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>;
  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;
  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>

  const svg_star = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 7.13l.97 2.29.47 1.11 1.2.1 2.47.21-1.88 1.63-.91.79.27 1.18.56 2.41-2.12-1.28-1.03-.64-1.03.62-2.12 1.28.56-2.41.27-1.18-.91-.79-1.88-1.63 2.47-.21 1.2-.1.47-1.11.97-2.27M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2z"/></svg>
  const svg_star_filled = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/></svg>
  const svg_empty_image = process.env.REACT_APP_API+'default.png';



  const history = useHistory();
  const [showDelete, setShowDelete] = useState(0);
  const [checkToSave, setCheckToSave] = useState(true);
  const [language, setLanguage] = useState('esp')

  const { id } = props.match.params;
  const { getApprovedChapterReview, approvedChapterReviews, getAllBooks, books } = props

  const [stars, setStars] = useState(5)
  const [userName, setUserName] = useState('')
  const [userImage, setUserImage] = useState(null);

  const [toPublishBody, setToPublishBody] = useState('')
  const [toPublishBodyENG, setToPublishBodyENG] = useState('')




  useEffect(() => {
    getAllBooks()
    if (id) {
      getApprovedChapterReview(id)
    }
  }, [])



  useEffect(() => {
    if (approvedChapterReviews && approvedChapterReviews.body) {
      setToPublishBody(approvedChapterReviews.body)
      setToPublishBodyENG(approvedChapterReviews.bodyENG)
      setStars(parseInt(approvedChapterReviews.stars))
      setUserName(approvedChapterReviews.username)
    }
  }, [approvedChapterReviews])





  useEffect(() => {
    var checkText = toPublishBody?.replaceAll(' ', '').replaceAll('<br>', '').replaceAll('</br>', '').replaceAll('<p>', '').replaceAll('</p>', '');
    var checkTextENG = toPublishBodyENG?.replaceAll(' ', '').replaceAll('<br>', '').replaceAll('</br>', '').replaceAll('<p>', '').replaceAll('</p>', '');
    if (checkText && checkText != '' && checkTextENG && checkTextENG != '') { setCheckToSave(false) } else { setCheckToSave(true) }
  }, [toPublishBody, toPublishBodyENG])




  const submitResource = async (fields) => {
    await axios.post(
      `${process.env.REACT_APP_API}createApprovedChapterReview`,
      { id, stars, toPublishBody, toPublishBodyENG, userName }
    ).then( async (response) => {

      if (response.data.id) {
        const imageFormData = new FormData();
        imageFormData.append('id', response.data.id);
        imageFormData.append('file', userImage);
  
        await axios.post( `${process.env.REACT_APP_API}uploadUserReviewImage`, imageFormData ).then( () => { 
          setTimeout(function(){ history.push('/opiniones-aprobadas'); }, 300);
        });
      } else {
        console.log('ERROR')
      }
      

    })

  };


  const propsUpload = {
    maxCount: 1,
    multiple: false,
    beforeUpload: file => {
      setUserImage(file);

      var reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById('imgshow1').src = e.target.result;
      }
      reader.readAsDataURL(file);

      return false;
    },
    onRemove: (file, list) => {
      setUserImage(null);
      document.getElementById('imgshow1').src = id? process.env.REACT_APP_API+approvedChapterReviews.image : svg_empty_image;
    }
  };


  const validateMessages = {
    required: `Este campo es obligatorio...`,
  };


  function delete_approved_chapter_review(state) {
    if (state) {
      history.push("/opiniones-aprobadas/delete/"+id);
    }
  }




  return (
    ( (id && approvedChapterReviews) || (!id) ) ? <div className="">
      <div className="py-2 container">

        <Form
          onFinish={fields => submitResource(fields)}
          validateMessages={validateMessages}
        >

          {id && showDelete? <div className="mb-4">
            <Alert
              message="¿Seguro que quieres eliminar esta opinión?"
              type="error"
              action={
                <Space direction="horizontal">
                  <Button size="small" type="danger" onClick={() => { delete_approved_chapter_review(1); }}>
                    Eliminar
                  </Button>
                  <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                    Cancelar
                  </Button>
                </Space>
              }
            />
          </div> : ''}



          <div className='initial flex bg-gray-100 rounded px-3 py-3'>
            <div className='mb-2 mr-3 bg-gray-500 h-20 w-20 overflow-hidden border rounded-full' style={{ minWidth: '80px' }}>
              <img id="imgshow1" name="imgshow1" src={id && approvedChapterReviews && approvedChapterReviews.image? process.env.REACT_APP_API+approvedChapterReviews.image : svg_empty_image} className="object-cover h-20 w-20"></img>
            </div>

            <div className='w-full mb-1'>
              <div className='flex items-center justify-between my-2'>
                <h1 className='text-2xl px-1'>{userName?.replaceAll(' ', '') != ''? userName : 'Usuario anónimo'}</h1>

                <div className='flex'>
                  {stars < 1? svg_star : svg_star_filled} 
                  {stars < 2? svg_star : svg_star_filled} 
                  {stars < 3? svg_star : svg_star_filled} 
                  {stars < 4? svg_star : svg_star_filled} 
                  {stars < 5? svg_star : svg_star_filled} 
                </div>
              </div>
              <hr></hr>

              <div className='px-1 max-h-60 overflow-y-auto mt-2' dangerouslySetInnerHTML={{__html: toPublishBody}}></div>

            </div>
          </div>



          <hr className='mt-2'></hr>

          <Upload id="imgload" {...propsUpload} className="mr-2 mt-2 flex">
            <Button icon={<UploadOutlined />}>Subir imagen de usuario</Button>
          </Upload>

          <div className='pt-2'>

            <div className='flex items-center justify-between mb-2'>
              <div className='flex w-full'>
                <Input id="username" placeholder="Nombre de usuario..." value={userName} onChange={ (event) => setUserName(event.target.value) } className="mr-2"/>  

                <Select id="stars" name="stars" placeholder="Puntuación..." value={stars} onChange={setStars}>
                  <Option value={0}>0/5</Option>
                  <Option value={1}>1/5</Option>
                  <Option value={2}>2/5</Option>
                  <Option value={3}>3/5</Option>
                  <Option value={4}>4/5</Option>
                  <Option value={5}>5/5</Option>
                </Select>     
              </div>
            </div>

            <div className="mt-4">
              <label>Cuerpo del testimonio en español: </label>
              <ReactQuill id="body" name="body" theme="snow" placeholder='Cuerpo en español...' value={toPublishBody? toPublishBody : ''} onChange={setToPublishBody}/>
            </div>

            <div className="mt-4">
              <label>Cuerpo del testimonio en inglés: </label>
              <ReactQuill id="bodyENG" name="bodyENG" theme="snow" placeholder='Cuerpo en inglés...' value={toPublishBodyENG? toPublishBodyENG : ''} onChange={setToPublishBodyENG}/>
            </div>

            <hr className='my-2'></hr>

          </div>

          <div className='w-full flex items-center justify-between'>
            <Button htmlType="button" size="large" danger className='mr-2' disabled={!id} onClick={() => { setShowDelete(1) }}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_trash}</div>
                <div className='flex' style={{marginTop: '1px'}}>Eliminar</div>
              </div>
            </Button>
            <Button htmlType="submit" size="large" type="primary" disabled={checkToSave}>
              <div className='flex items-center'>
                <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                <div className='flex items-center ml-2'>{svg_save}</div>
              </div>
            </Button>
          </div>

        </Form>
      </div>
    </div> : null
  );
}


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllBooks: () => dispatch(getAllBooks()),
    getApprovedChapterReview: id => dispatch(getApprovedChapterReview(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Opinion)