import React, { useContext, useEffect, useState } from "react";




const EmailTemplate = (props) => {

  var {textTitle, textBody} = props;

  var css = `
    .inside a {
      color: SteelBlue !important;
      text-decoration-line: underline;
    }
    .outside a:hover {
      color: white;
    }
  `

  var template =  (
    <html lang="es">
      <body className="bg-white outside" style={{ fontFamily: `'Open Sans', Helvetica, Arial, sans-serif` }}>
        <style>{css}</style>


        <div className='p-2'>

          <div className='flex items-baseline'>
            <div className='rounded bg-no-repeat bg-center' style={{ backgroundImage: `url(${process.env.REACT_APP_API}/bg.jpg)`, height: '40px !important', width: '40px !important' }}>
              <a href={process.env.REACT_APP_URL} className="rounded-sm no-underline relative w-10 h-10 bg-fire-default hover:bg-fire-400 bg-opacity-95 transition flex justify-start pl-3 items-center text-white font-bold transform">
                A
                <i className="font-serif font-light">f</i>
              </a>
            </div>
            <div className='rounded-sm overflow-hidden ml-2 h-10 w-full bg-no-repeat bg-center' style={{ backgroundImage: `url(${process.env.REACT_APP_API}/bg.jpg)` }}>
              <a href={process.env.REACT_APP_URL} className='no-underline relative whitespace-nowrap h-10 bg-fire-default hover:bg-fire-400 bg-opacity-95 transition flex justify-start px-3 items-center text-white font-bold transform'>
                Atril Flamenco
              </a>
            </div>
          </div>

          <div className='rounded-sm overflow-hidden mt-2 bg-no-repeat bg-center' style={{ backgroundImage: `url(${process.env.REACT_APP_API}/bg.jpg)` }}>
            <div className='bg-gray-100 p-3 bg-opacity-95 inside'>

              <p className="font-bold text-lg">{textTitle}</p>
              <hr classNameName="border-gray-400 border-opacity-50"></hr>
              <div className="mt-2" dangerouslySetInnerHTML={{__html: textBody}}></div>

            </div>
          </div>

          <div className='flex items-baseline mt-2'>
            <div className='rounded-sm overflow-hidden mr-2 h-10 w-full bg-no-repeat bg-center' style={{ backgroundImage: `url(${process.env.REACT_APP_API}/bg.jpg)` }}>
              <div className='relative whitespace-nowrap h-10 bg-gray-100 bg-opacity-95 flex justify-start px-3 items-center font-bold'>
                Atril Flamenco
              </div>
            </div>

            <div className='rounded-sm bg-no-repeat bg-center' style={{ backgroundImage: `url(${process.env.REACT_APP_API}/bg.jpg)`, height: '40px !important', width: '40px !important' }}>
              <a href={process.env.REACT_APP_URL} className="rounded-sm no-underline relative w-10 h-10 bg-fire-default hover:bg-fire-400 bg-opacity-95 transition flex justify-start pl-3 items-center text-white font-bold transform">
                A
                <i className="font-serif font-light">f</i>
              </a>
            </div>
          </div>

        </div>


      </body>
    </html>
  )
    
  return template;



};

export default EmailTemplate;