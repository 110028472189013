import { useState, useEffect } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from './../actions'

function UserActions(props) {
  const { className, auth, signOut, position } = props
  const [visible, setVisible] = useState(false)

  localStorage.setItem('user', JSON.stringify(auth));

  return (
    <div className="relative">
    <button onClick={() => setVisible(!visible)} className={`flex items-center p-1 pr-3 space-x-2 rounded-3xl border dark:bg-gray-900 dark:text-gray-500 focus:outline-none ${className?? ''} ${position && position[0] + `-4`} ${position && position[1] + `-4`} z-20`}>
      <img src={auth.photoURL} className="w-6 h-6 rounded-full" alt="User Avatar" />
      {/* <span className="font-semibold">{auth.displayName}</span> */}
      <svg xmlns="http://www.w3.org/2000/svg" className={`h-3 w-3 transform ${visible? `rotate-180` : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M19 9l-7 7-7-7" />
      </svg>
    </button>
    {visible &&
      <div onMouseOver={() => setVisible(true)} onMouseLeave={() => setVisible(false)} className={`absolute top-full right-0 mt-2 w-56 border bg-white dark:bg-gray-900 rounded p-2 flex flex-col font-semibold z-30`}>
        <Link to="/configuracion" className="text-left px-3 py-2 rounded hover:bg-gray-500 hover:bg-opacity-10">Configuración</Link>
        <span className="block border-b w-full my-2 dark:border-gray-800" />
        <button onClick={signOut} className="text-left px-3 py-2 rounded hover:bg-gray-500 hover:bg-opacity-10">
          <span>Salir</span>
        </button>
      </div>
    }
    </div>
  )
}

export default connect(state => ({auth: state.firebase.auth}), dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  }
})(UserActions)