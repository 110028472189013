import * as types from '../constants/ActionTypes'

const users = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_USERS:
      return action.payload
    case types.NEW_USER:
      return action.payload
    case types.GET_USER:
      return action.payload
    case types.DELETE_USER:
      return action.payload
    default:
      return state
  }
}

export default users