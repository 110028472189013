import { useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import { Form, Radio, Input, Upload, Button, Steps, Select, Alert, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import StepAudio from './stepsResource/StepAudio';
import StepIndices from './stepsResource/StepIndices';
import StepImage from './stepsResource/StepImage';
import StepFinal from './stepsResource/StepFinal';
import { sortIndices } from '../utils';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { getDataBook, getSmallDataBooks, getDataAudios } from '../actions'
import { connect } from 'react-redux'

import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';


const { TextArea } = Input;
const { Step } = Steps;
const { Option } = Select;

function RecursosAdd(props) {

  const { slug } = props.match.params;
  const { getDataBook, getSmallDataBooks, book, books, getDataAudios, audios: dataAudios } = props;


  const svg_anterior = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;
  const svg_siguiente = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_empty_image = process.env.REACT_APP_API+'default.png';
  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>

  const history = useHistory();
  const [value, setValue] = useState('true');
  const [languageValue, setLanguageValue] = useState('esp');
  const [isTranslationFrom, setIsTranslationFrom] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showDelete, setShowDelete] = useState(0);

  const [fileUpload, setFileUpload] = useState(null);
  const [referencesFile, setReferencesFile] = useState(null);
  const [portada, setPortada] = useState('');

  const [filesUpload, setFilesUpload] = useState([]);
  const [imageInLine, setImageInLine] = useState([]);
  const [uploadedImageInLine, setUploadedImageInLine] = useState([]);
  const [audioInLine, setAudioInLine] = useState([]);
  const [uploadedAudioInLine, setUploadedAudioInLine] = useState([]);

  const [indices, setIndices] = useState([]);
  const [indicesBig, setIndicesBig] = useState([]);
  const [audios, setAudios] = useState([]);
  const [images, setImages] = useState([]);
  const [data, setData] = useState({});

  const [isLoadingIndices, setIsLoadingIndices] = useState(false);
  const [isLoadingIndiceFiles, setIsLoadingIndiceFiles] = useState(false);
  const [isLoadingAudioFiles, setIsLoadingAudioFiles] = useState(false);
  const [isLoadingImageFiles, setIsLoadingImageFiles] = useState(false);
  const [isLoadingEnding, setIsLoadingEnding] = useState(false);

  const [audioList, setAudioList] = useState([]);
  const [references, setReferences] = useState([]);

  const [totalWithoutUploadImages, setTotalWithoutUploadImages] = useState(0);
  const [totalUploadedImages, setTotalUploadedImages] = useState(0);
  const [totalToUploadedImages, setTotalToUploadedImages] = useState(0);
  
  const [totalWithoutUploadAudios, setTotalWithoutUploadAudios] = useState(0);
  const [totalUploadedAudios, setTotalUploadedAudios] = useState(0);
  const [totalToUploadedAudios, setTotalToUploadedAudios] = useState(0);

  
  const [titleText, setTitleText] = useState('');
  const [subtitleText, setSubtitleText] = useState('');
  const [secondarySubtitleText, setSecondarySubtitleText] = useState('');
  const [textTest, setTextTest] = useState('');
  const [authorText, setAuthorText] = useState('');
  const [isBookText, setIsBookText] = useState('');


  

  useEffect(() => {
    var tmp = data;
    tmp.description = textTest;
    setData(tmp)
  }, [textTest])


  useEffect(() => {
    getSmallDataBooks()
    if (slug) {
      getDataBook(slug)
      getDataAudios(slug)
    }
  }, [])


  function delete_plan(state) {
    if (state) {
      history.push("/recursos/delete/"+book.id);
    }
  }


  useEffect(() => {
    
    if (slug && book.tableOfContents && book.slug === slug) {

      setIndices(book.tableOfContents)
      setImageInLine(book.images)
      setUploadedImageInLine(book.imagesUploaded)
      
      setFileUpload(null)
      setReferences([]);
      setReferencesFile(null)

      setTitleText(book.titulo)
      setSubtitleText(book.subtitulo)
      setSecondarySubtitleText(book.secondarySubtitle)
      setTextTest(book.descripcion)
      setAuthorText(book.author)
      setIsBookText(book.es_libro? 'true' : 'false')


      


    } else {
      setIndices([])
      setImageInLine([])
      setUploadedImageInLine([])
      setAudioInLine([])

      setFileUpload(null)
      setReferences([]);
      setReferencesFile(null)

      setTitleText('')
    }
  }, [book])

  useEffect(() => {

    if (slug && book.audios && !isEmpty(dataAudios) && book.slug === slug) {

      book.audios.map(element => {
        dataAudios.map(elementAudio => {
          if (elementAudio.fileName === element) {
            setAudioInLine(audioInLine => [...audioInLine, {fileName: element, id: elementAudio.fileName}])
          }
        });
      });
      
      setUploadedAudioInLine(book.audiosUploaded)

    } else if (!slug) {
      setUploadedAudioInLine([])

    }
  }, [book, dataAudios])



  const onChange = event => {
    setValue(event);
  };
  const onChangeLanguage = event => {
    setLanguageValue(event);
  };
  const onChangeTranslation = event => {
    setIsTranslationFrom(event);
  };


  const backStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };


  const propsUpload = {
    maxCount: 1,
    multiple: false,
    beforeUpload: file => {
      setFileUpload(file);

      var reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById('imgshow1').src = e.target.result;
        /* document.getElementById('imgshow2').src = e.target.result; */
        document.getElementById('imgshowfinal').src = e.target.result;
      }
      reader.readAsDataURL(file);

      return false;
    },
    onRemove: (file, list) => {
      setFileUpload(null);
      document.getElementById('imgshow1').src = slug && book && book.image? process.env.REACT_APP_API+book.image : svg_empty_image;
      /* document.getElementById('imgshow2').src = slug && book && book.image? process.env.REACT_APP_API+book.image : svg_empty_image; */
      document.getElementById('imgshowfinal').src = slug && book && book.image? process.env.REACT_APP_API+book.image : svg_empty_image;

    }
  };








  const submitResource = async () => {
    
    window.scrollTo(0, 0);
    setIsLoadingIndices(true)
    
    const sortedIndices = await sortIndices(data.indices);
    const isBookTMP = data.isBook === 'true'? 1 : 0
    const finalSlug = slug? slug : data.slug;
    
    const formData = {
      id: data.id,
      title: data.title,
      slug: finalSlug,
      subtitulo: subtitleText,
      secondarySubtitle: secondarySubtitleText,
      isBook: isBookTMP,
      translation_of: data.translation_of,
      language: data.language,
      descripcion: data.description,
      autor: data.autor,
      indices: JSON.stringify(sortedIndices),
    };


    /* Crar objeto de recurso e indices */
    const response = await axios.post(
      `${process.env.REACT_APP_API}createIndices`,
      formData
    );


    /* Subir portada */
    if (fileUpload) {
      const imageFormData = new FormData();
      imageFormData.append('id', response.data.data.id);
      imageFormData.append('title', response.data.data.slug);
      imageFormData.append('isBook', response.data.data.es_libro);
      imageFormData.append('file', fileUpload);
      await axios.post(
        `${process.env.REACT_APP_API}uploadFrontPageImage`,
        imageFormData
      );
    }



    /* Subir ficheros de los indices */
    setIsLoadingIndiceFiles(true)
    if ( filesUpload ) {
      for await (let indice of filesUpload) {
        const formData = new FormData();
        formData.append('id', response.data.data.id);
        formData.append('title', response.data.data.slug);
        formData.append('isBook', response.data.data.es_libro);
        formData.append('file', indice.data);
        await axios.post(
          `${process.env.REACT_APP_API}uploadIndice`,
          formData
        );
      }
    }



    /* Subir ficheros de los audios */
    setIsLoadingAudioFiles(true)
    var countTMO = 0;
    if ( audios ) {

      for await (let file of audios) {

        const formData = new FormData();
        formData.append('title', response.data.data.slug);
        formData.append('token', response.data.data.token);
        formData.append('refsArr', JSON.stringify(references));
        formData.append('audioList', JSON.stringify(audioList));
        formData.append('isBook', response.data.data.es_libro);
        formData.append('file', file);

        await axios.post(
          `${process.env.REACT_APP_API}uploadAudios`,
          formData
        );
        
      }

    }
    

    /* Subir los ficheros de las imagenes */
    setIsLoadingImageFiles(true)
    if (slug) {
      var formDataTMP = {
        title: response.data.data.slug,
        isBook: response.data.data.es_libro,
        token: response.data.data.token,
        refsArr: JSON.stringify(references),
        audioList: JSON.stringify(audioList),
      };

      await axios.post(
        `${process.env.REACT_APP_API}updateAudios`,
        formDataTMP
      );
    }


    if (!slug) {
      var unUploadedAudios = [];
      for await (let audioOfList of audioList) {
        var fileLess = true;
        for await (let file of audios) {
          if (file.name === audioOfList.fileName) {
            fileLess = false;
          }
        }
        if (fileLess) {
          unUploadedAudios.push(audioOfList)
        }
      }

      if (!isEmpty(unUploadedAudios)) {
        await axios.post(
          `${process.env.REACT_APP_API}uploadFileLessAudio`,
          { title: response.data.data.slug, token: response.data.data.token, audioOfList: JSON.stringify(unUploadedAudios) }
        );
      }
    }




    setIsLoadingEnding(true)
    if ( images ) {
      for await (let file of images) {
        const formData = new FormData();
        formData.append('title', response.data.data.slug);
        formData.append('isBook', response.data.data.es_libro);
        formData.append('file', file);
        await axios.post(
          `${process.env.REACT_APP_API}uploadImages`,
          formData
        );
      }
    }

    
    /* if ( referencesFile ) {
      const AudiosReferencesFormData = new FormData();
      AudiosReferencesFormData.append('name', response.data.data.slug);
      AudiosReferencesFormData.append('md', referencesFile);
      await axios.post(
        `${process.env.REACT_APP_API}generateReferences`,
        AudiosReferencesFormData
      );
    } */


    history.push('/recursos');
  };


  const onSubmit = (fields, step) => {
    setCurrentStep(currentStep => currentStep + 1);
    window.scrollTo(0, 0);

    switch (step) {
      case 'step1':
        setData({ ...data, ...fields, slug: _.kebabCase(fields['title']) });
        break;
      case 'step2':
        setData({
          ...data,
          indices: [...indices],
        });
        break;
      default:
        return null;
    }
  };


  const validateMessages = {
    required: `Este campo es obligatorio...`,
  };


  const handleFinish = (fileList, type = null) => {
    
    setTitleText(document.getElementById('title').value)
    /* setSubtitleText(document.getElementById('subtitle').value)
    setSecondarySubtitleText(document.getElementById('secondarySubtitle').value) */
    setAuthorText(document.getElementById('autor').value)
    setIsBookText(document.getElementById('isBook').value)
    setLanguageValue(document.getElementById('language').value)

    setCurrentStep(prev => prev + 1);
    window.scrollTo(0, 0);
    if (type === 'img') {
      setImages(fileList);
    } else if (type === 'audio') {
      setAudios(fileList);
    }
  };





  if ( !slug || (slug && book.titulo && book.slug === slug) ) { 
    
    const steps = [
      {
        name: 'Datos básicos',
        content: (
          <Form 
            initialValues={{
              id: slug && book.id? book.id : '', 
              title: slug && book.titulo? book.titulo : '', 
              subtitle: slug && book.subtitulo? book.subtitulo : '',
              secondarySubtitle: slug && book.secondarySubtitle? book.secondarySubtitle : '',
              autor: slug && book.author? book.author : '',
              isBook: slug? book.es_libro? 'true' : 'false' : null,
              translation_of: slug && book.translation_of? book.translation_of : null,
              language: slug && book.language? book.language : 'esp',
              description: slug && book.descripcion? book.descripcion : '',
            }}
            onFinish={fields => onSubmit(fields, 'step1')} 
            validateMessages={validateMessages} 
          >

            <Form.Item name="id" className='hidden'>
              <Input />
            </Form.Item>

            
            {slug && showDelete? <div className="mb-4">
              <Alert
                message="¿Seguro que quieres eliminar este recurso de forma definitiva?"
                type="error"
                action={
                  <Space direction="horizontal">
                    <Button size="small" type="danger" onClick={() => { delete_plan(1); }}>
                      Eliminar
                    </Button>
                    <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                      Cancelar
                    </Button>
                  </Space>
                }
              />
            </div> : ''}



            <div className='flex mb-2'>
              <div className='flex flex-wrap'>

                {/* <div className='flex w-full'>
                  <div className='w-full flex justify-center rounded-sm mr-1' style={{ backgroundColor: '#FFC17A' }}>
                    <div className="block relative w-80 h-80">
                      <div className="book-cover book-cover--thumbnail">
                        <img id="imgshow1" src={slug && book && book.image? process.env.REACT_APP_API+book.image : svg_empty_image} alt={`Portada ${book.titulo}`} />
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex justify-center rounded-sm bg-gray-100 ml-1'>
                    <div className="block relative w-80 h-80">
                      <div className="book-cover book-cover--thumbnail">
                        <img id="imgshow2" src={slug && book && book.image? process.env.REACT_APP_API+book.image : svg_empty_image} alt={`Portada ${book.titulo}`} />
                      </div>
                    </div>
                  </div>
                </div> */}


                <div className='flex w-full'>
                  <div  style={{ width: '350px', minWidth: '350px', height: '466px', minHeight: '466px' }}>
                    <img id="imgshow1" className={'object-cover border rounded-sm'} style={{ width: '350px', minWidth: '350px', height: '466px', minHeight: '466px' }} src={slug && book && book.image? process.env.REACT_APP_API+book.image : svg_empty_image} alt={`Portada ${book.titulo}`} />
                  </div>
                </div>

              </div>


              <div className='w-full mx-4'>
                <div className='w-full'>
                  <label>Título:</label>
                  <Form.Item id="title" name="title" rules={[{ required: true }]}>
                    <Input placeholder="Título" />
                  </Form.Item>
                </div>
                <div className='w-full mb-2'>
                  <label>Subtítulo:</label>
                  <ReactQuill style={{ maxHeight: '100px' }} className="overflow-y-auto border-t border-b" id="subtitle" placeholder="Subtítulo" theme="snow" value={subtitleText? subtitleText : ''} onChange={setSubtitleText}/>
                </div>
                <div className='w-full mb-2'>
                  <label>Segundo subtítulo:</label>
                  <ReactQuill style={{ maxHeight: '100px' }} className="overflow-y-auto border-t border-b" id="secondarySubtitle" placeholder="Segundo subtítulo" theme="snow" value={secondarySubtitleText? secondarySubtitleText : ''} onChange={setSecondarySubtitleText}/>
                </div>





                <div className='w-full flex space-x-2'>
                  <div className='w-full'>
                    <label>Autor:</label>
                    <Form.Item id="autor" name="autor" rules={[{ required: true }]}>
                      <Input placeholder="Autor" />
                    </Form.Item>
                  </div>

                  <div className='w-full'>
                    <label>Este recurso es una traducción de:</label>
                    <Form.Item id="translation_of" name="translation_of">
                      <Select onChange={onChangeLanguage} placeholder="Selecciona el idioma del recurso" className='w-full'>

                        <Option value={null}>No es una traducción</Option>
                        {books?.map(res => <Option value={res.token}>{res.titulo.replaceAll('*', '')}</Option> )}

                      </Select>
                    </Form.Item>
                  </div>
                </div>


                <div className='w-full flex space-x-2'>
                  <div className='w-full'>
                    <label>Tipo:</label>
                    <Form.Item id="isBook" name="isBook" rules={[{ required: true }]}>
                      <Select onChange={onChange} placeholder="Selecciona el tipo de recurso" className='w-full'>
                        <Option value='true'>Libro</Option>
                        <Option value='false'>Artículo</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className='w-full'>
                    <label>Idioma:</label>
                    <Form.Item id="language" name="language" rules={[{ required: true }]}>
                      <Select onChange={onChangeLanguage} placeholder="Selecciona el idioma del recurso" className='w-full'>
                        <Option value='esp'>Español</Option>
                        <Option value='eng'>Inglés</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <Form.Item  className='w-full'>
                  <Upload id="imgload" {...propsUpload}>
                    <Button icon={<UploadOutlined />}>Subir portada</Button>
                  </Upload>
                </Form.Item>

              </div>


            </div>

            <label>Descripción:</label>
            <ReactQuill id="desc" theme="snow" value={textTest? textTest : ''} onChange={setTextTest}/>







            <br></br>
            <div className='flex justify-between'>
              <Form.Item>
                <Button htmlType="button" size="large" type="primary" onClick={backStep} disabled>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_anterior}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                  </div>
                </Button>
              </Form.Item>


              
              

              <div className='flex space-x-2'>
                {slug && <Form.Item>
                  <Button htmlType="button" size="large" danger type="primary" onClick={() => { setShowDelete(1) }}>
                    <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_trash}</div>
                      <div className='flex' style={{marginTop: '1px'}}>Eliminar</div>
                    </div>
                  </Button>
                </Form.Item>}

                <Form.Item>
                  <Button htmlType="submit" size="large" type="primary">
                    <div className='flex items-center'>
                      <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                      <div className='flex items-center ml-2'>{svg_siguiente}</div>
                    </div>
                  </Button>
                </Form.Item>
              </div>
            </div>

          </Form>
        ),
      },
      {
        name: 'Indices',
        content: (
          <StepIndices
            onSubmit={onSubmit}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            indices={indices}
            setIndices={setIndices}
            indicesBig={indicesBig}
            setIndicesBig={setIndicesBig}
            setImageInLine={setImageInLine}
            setAudioInLine={setAudioInLine}
            backStep={backStep}
            setFilesUpload={setFilesUpload}
            filesUpload={filesUpload}
            filesSaved={book.files}
            slug={slug}
          />
        ),
      },
      {
        name: 'Imágenes',
        content: (
          <StepImage
            backStep={backStep}
            imagesOcc={imageInLine}
            handleFinish={handleFinish}
            uploadedImageInLine={uploadedImageInLine}
            setUploadedImageInLine={setUploadedImageInLine}
            slug={slug}
            isBook={value}

            totalWithoutUpload={totalWithoutUploadImages}
            setTotalWithoutUpload={setTotalWithoutUploadImages}
            totalUploaded={totalUploadedImages}
            setTotalUploaded={setTotalUploadedImages}
            totalToUploaded={totalToUploadedImages}
            setTotalToUploaded={setTotalToUploadedImages}
            // onSubmit={onSubmit}
          />
        ),
      },
      {
        name: 'Audios',
        content: (
          <StepAudio
            onSubmit={onSubmit}
            backStep={backStep}
            handleFinish={handleFinish}
            audiosOcc={audioInLine}
            referencesFile={referencesFile}
            setReferencesFile={setReferencesFile}
            uploadedAudioInLine={uploadedAudioInLine}
            setUploadedAudioInLine={setUploadedAudioInLine}
            slug={slug}
            isBook={value}
            references={references}
            setReferences={setReferences}
            audioList={audioList}
            setAudioList={setAudioList}
            dataAudios={dataAudios}

            totalWithoutUpload={totalWithoutUploadAudios}
            setTotalWithoutUpload={setTotalWithoutUploadAudios}
            totalUploaded={totalUploadedAudios}
            setTotalUploaded={setTotalUploadedAudios}
            totalToUploaded={totalToUploadedAudios}
            setTotalToUploaded={setTotalToUploadedAudios}
          />
        ),
      },
      {
        name: 'Resumen',
        content: data ? (
          <StepFinal
            filesUpload={filesUpload}
            indices={indices}
            backStep={backStep}
            onSubmit={onSubmit}
            audios={audios}
            images={images}
            submitResource={submitResource}

            isLoadingIndices={isLoadingIndices}
            isLoadingIndiceFiles={isLoadingIndiceFiles}
            isLoadingAudioFiles={isLoadingAudioFiles}
            isLoadingImageFiles={isLoadingImageFiles}
            isLoadingEnding={isLoadingEnding}

            indicesBig={indicesBig}


            totalWithoutUploadImages={totalWithoutUploadImages}
            totalUploadedImages={totalUploadedImages}
            totalToUploadedImages={totalToUploadedImages}

            totalWithoutUploadAudios={totalWithoutUploadAudios}
            totalUploadedAudios={totalUploadedAudios}
            totalToUploadedAudios={totalToUploadedAudios}

            book={book}
            slug={slug}
            textTest={textTest}
            titleText={titleText}
            subtitleText={subtitleText}
            secondarySubtitleText={secondarySubtitleText}
            authorText={authorText}
            isBookText={isBookText}
          />
        ) : (
          'no data'
        ),
      },
    ];

    return (
      <div className="">
        <div className="py-2 container">
          <Steps
            type="navigation"
            current={currentStep}
            className="site-navigation-steps mb-8"
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                status={currentStep === index ? `process` : `wait`}
                title={step.name}
              />
            ))}
          </Steps>
          {steps.map((step, index) => (
            <div key={index} className={currentStep !== index? 'hidden' : ''}>
              {step.content}
            </div>
          ))}
        </div>
      </div>
    );

  } else {
    return null;
  }

}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getDataBook: slug => dispatch(getDataBook(slug)),
    getSmallDataBooks: () => dispatch(getSmallDataBooks()),
    getDataAudios: slug => dispatch(getDataAudios(slug)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecursosAdd)

/* export default RecursosAdd; */
