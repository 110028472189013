import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Filters({ options: filters = [], setFilter = () => {}, actions = null, data }){

  const [active, setActive] = useState(0)
  const _setFilter = index => {
    setActive(index)
    setFilter(filters[index])
  }







  return ( !data || ( data && data.length > 0 ) ) && (
    <div className={`bg-gray-100 py-4 ${!filters.length && 'hidden'}`}>
      <div className="container flex justify-between items-center">
        <div className="m-3 flex flex-wrap items-start whitespace-nowrap overflow-x-auto">
          {filters.length > 1? filters.map((filter, index) => <button key={index} onClick={() => _setFilter(index)} className={`px-4 py-2 hover:bg-gray-200 mr-2 rounded ${index === active && `bg-gray-200`}`}>{filter}</button>) : null} 
        </div>
        {actions &&
          <div className='flex space-x-2'>
            {actions.map( (action, index) => {
              const { label, onClick, to, type } = action 
              switch (type) {
                case 'button':
                  return <Button key={index} onClick={onClick} type="primary">{label}</Button>
                case 'excelExportUsers':
                  const multiDataSet = [
                    {
                      columns: [
                        { title: 'ID', width: { wpx: 250 } },
                        { title: 'Nombre', width: { wpx: 200 } },
                        { title: 'Email', width: { wpx: 200 } },
                        { title: 'Rol', width: { wpx: 100 } },
                        { title: 'Proveedor', width: { wpx: 100 } },
                        { title: 'Imagen', width: { wpx: 200 } },                      
                        { title: 'Último LogIn', width: { wpx: 200 } },                      
                        { title: 'Suscrito hasta', width: { wpx: 200 } },                      
                        { title: 'Fecha de creación', width: { wpx: 200 } },                      
                      ],
                      data: []
                    }
                  ];

                  data?.map(user => {

                    var lastLoginDateSplit = user.lastLogin? user.lastLogin.split('T')[0].split('-') : null
                    var lastLoginHourSplit = user.lastLogin? user.lastLogin.split('T')[1].split(':') : null
                    var lastLoginDate = user.lastLogin? new Date(lastLoginDateSplit[0], lastLoginDateSplit[1], lastLoginDateSplit[2], lastLoginHourSplit[0], lastLoginHourSplit[1]) : null;
                    var lastLogin =  user.lastLogin? lastLoginDate.getHours() + ':' + lastLoginDate.getMinutes() + 'h ' + lastLoginDate.getDate() + '/' + lastLoginDate.getMonth() + '/' + lastLoginDate.getFullYear() : null;  

                    var suscriberUntilDateSplit = user.suscriberUntilNew? user.suscriberUntilNew.split('T')[0].split('-') : null
                    var suscriberUntilHourSplit = user.suscriberUntilNew? user.suscriberUntilNew.split('T')[1].split(':') : null
                    var suscriberUntilDate = user.suscriberUntilNew? new Date(suscriberUntilDateSplit[0], suscriberUntilDateSplit[1], suscriberUntilDateSplit[2], suscriberUntilHourSplit[0], suscriberUntilHourSplit[1]) : null;
                    var suscriberUntil =  user.suscriberUntilNew? suscriberUntilDate.getHours() + ':' + suscriberUntilDate.getMinutes() + 'h ' + suscriberUntilDate.getDate() + '/' + suscriberUntilDate.getMonth() + '/' + suscriberUntilDate.getFullYear() : null;  

                    var createdAtDateSplit = user.createdAt? user.createdAt.split('T')[0].split('-') : null
                    var createdAtHourSplit = user.createdAt? user.createdAt.split('T')[1].split(':') : null
                    var createdAtDate = user.createdAt? new Date(createdAtDateSplit[0], createdAtDateSplit[1], createdAtDateSplit[2], createdAtHourSplit[0], createdAtHourSplit[1]) : null;
                    var createdAt =  user.createdAt? createdAtDate.getHours() + ':' + createdAtDate.getMinutes() + 'h ' + createdAtDate.getDate() + '/' + createdAtDate.getMonth() + '/' + createdAtDate.getFullYear() : null;  


                    var userArr = [
                      { value: user.id? user.id : '' },
                      { value: user.name? user.name : '' },
                      { value: user.email? user.email : '' },
                      { value: user.rol? user.rol : '' },
                      { value: user.provider? user.provider : '' },
                      { value: user.image? user.image : '' },
                      { value: lastLogin? lastLogin : '' },
                      { value: suscriberUntil? suscriberUntil : 'Sin suscripción' },
                      { value: createdAt? createdAt : '' },
                    ]
                    multiDataSet[0].data.push(userArr);
                  });
                  return <ExcelFile key={index} filename="Atril_Flamenco_Users" element={
                    <Button type="primary">Exportar Usuarios</Button>
                  }>
                    <ExcelSheet dataSet={multiDataSet} name="Organization" />

                    {/* <ExcelSheet data={data} name="Usuarios">
                      <ExcelColumn label="ID" value="id" style={{ width: { wpx: 800 } }} />
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Email" value="email"/>
                      <ExcelColumn label="Rol" value="rol"/>
                    </ExcelSheet> */}
                  </ExcelFile>
                case 'link':
                  return <Link to={to} key={index}><Button type="primary">{label}</Button></Link>
                default:
                  return <button key={index}>{label}</button>
              }
            })}
          </div>
        }
      </div>
    </div>
  )
}

export default Filters