import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getPlanCategoria, updatePlanCategoria } from '../actions'
import { Modal, Button, Form, Input, Alert, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';

const { TextArea } = Input

function PlanCategoria(props){

  const { getPlanCategoria, planCategorias, updatePlanCategoria } = props
  const { id } = props.match.params

  const history = useHistory();

  const [planCategoria, setPlanCategoria] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [input, setInput] = useState(null)
  const [showDelete, setShowDelete] = useState(0);
  const [color, setColor] = useState('#D3D3D3')
  const [date, setDate] = useState(null)

  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>


  useEffect(() => {
    getPlanCategoria(id)
  }, [])

  useEffect(() => {
    setPlanCategoria(planCategorias)
  }, [planCategorias])


  useEffect(() => {
    if (planCategoria) {
      setDate(new Date(planCategorias.createdAt))
      setColor(planCategoria.color)
    }

  }, [planCategoria])



  const _updatePlanCategoria = () => {
    updatePlanCategoria({ id, ...input })
    setIsModalVisible(false)
    getPlanCategoria(id)

  }

  const onChange = event => {
    setInput({ ...input, [event.target.id]: event.target.value })
  }

  function delete_cat(state) {
    if (state) {
      history.push("/plancategorias/delete/"+planCategoria.id);
    }
  }

  function setColorFunc(hex) {
    if (hex.target.value) {
      setColor(hex.target.value)
    } else {
      setColor('#D3D3D3')
    }
  }
  

  return (
    planCategoria?
    <>
      {showDelete? <div className="">
        <Alert
          message="¿Seguro que quieres eliminar esta categoría de los planes de lectura?"
          type="error"
          action={
            <Space direction="horizontal">
              <Button size="small" type="danger" onClick={() => { delete_cat(1); }}>
                Eliminar
              </Button>
              <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                Cancelar
              </Button>
            </Space>
          }
        />
      </div> : ''}

      <header className="py-8" style={{ backgroundColor: planCategoria?.color }}>
        <div className="container flex items-center space-x-5">
          <div className="flex-1">
            <h1 className="text-4xl font-semibold">{planCategoria?.name}</h1>
            {/* {date && <p>Creada el {date.toLocaleString('ca-ES')}</p>} */}

          </div>
          <div>
            <div className="flex justify-center items-center">
              <Button className="mr-2" icon={<EditOutlined />} onClick={() => setIsModalVisible(true)} >
                Editar
              </Button>
              <Button danger className='mr-2' onClick={() => { setShowDelete(1) }}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_trash}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Eliminar Categoría</div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </header>


      <Modal
        title="Editar categoría"
        visible={isModalVisible}
        onOk={_updatePlanCategoria}
        onCancel={() => setIsModalVisible(false)}
        okText="Actualizar"
        destroyOnClose
        >
        <Form preserve={false} onChange={onChange} className='flex w-full'>
          <Form.Item className='w-full' rules={[{ required: true }]}>
            <Input id="name" placeholder="Nombre" defaultValue={planCategoria?.name} />
          </Form.Item>
          <Form.Item className='w-28 ml-2' onChange={setColorFunc}>
            <Input id="color" placeholder="FFC17A" maxLength="7" defaultValue={planCategoria?.color? planCategoria.color : '#D3D3D3'} />
          </Form.Item>
          <div id="colorBox" className='ml-2 w-20 h-8 rounded-sm' style={{ background: color }}></div>
        </Form>
      </Modal>
    </> 
    : 
    null
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getPlanCategoria: id => dispatch(getPlanCategoria(id)),
    updatePlanCategoria: input => dispatch(updatePlanCategoria(input))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCategoria)