import { useEffect } from 'react'
import { connect } from 'react-redux'
import { deleteUser } from '../actions'
import { useHistory } from "react-router-dom";

function PlanCategoriDelete(props){
  const { deleteUser } = props
  const { id } = props.match.params
  let history = useHistory();

  useEffect(() => {
    if (id) { 
      deleteUser(id);
    }
  }, [id])

  useEffect(() => {
    if (props.users.status) {
      localStorage.setItem('deletedUser', true);
      history.push("/usuarios");
    }
  }, [props])

  return "Eliminando..."
}


const mapStateToProps = state => state
const mapDispatchToProps = dispatch => {
  return {
    deleteUser: input => dispatch(deleteUser(input))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCategoriDelete)