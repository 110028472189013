import { useEffect, useState } from 'react'


function UserTags(props){

  const { id, tags } = props
  const numberOf = tags?.length ?? null


  return (
    <div>

        <div className="container py-5">
            <h3 className="text-xl font-semibold mb-3">
            Tags <span>{numberOf ? `(${numberOf})` : ``}</span>
            </h3>

            <div className='flex flex-wrap'>
                {tags?.map(tag => ( 
                    <div key={tag.id} className="bg-gray-50 px-2 border rounded-sm text-gray-400 mb-2 mr-2">
                        {tag.name}
                    </div>
                ))}
            </div>

            {tags?.length < 1? 'Sin tags registradas...' : null}
        </div>
        
    </div>
  )
}

export default UserTags