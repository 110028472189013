import { useEffect } from 'react'
import { connect } from 'react-redux'
import { deleteNotification } from '../actions'
import { useHistory } from "react-router-dom";


function NotificationDelete(props){
  const { deleteNotification } = props
  const { id } = props.match.params
  let history = useHistory();

  useEffect(() => {
    if (id) { 
      deleteNotification(id);
    }
  }, [id])

  useEffect(() => {
    if (props.notifications) {
      localStorage.setItem('deletedNotification', true);
      setTimeout(function(){ history.push('/notificaciones'); }, 300);
    }
  }, [props])

  return "Eliminando..."
}


const mapStateToProps = state => state
const mapDispatchToProps = dispatch => {
  return {
    deleteNotification: input => dispatch(deleteNotification(input))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDelete)