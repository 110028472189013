import * as types from '../constants/ActionTypes'

const audios = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_AUDIOS:
      return action.payload
    case types.GET_AUDIOS_BY_SLUG:
      return action.payload;
    case types.DELETE_AUDIO:
      return true
    default:
      return state
  }
}

export default audios