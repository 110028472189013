import * as types from '../constants/ActionTypes'
import _ from 'lodash'

const notas = (state = [], action) => {
  switch(action.type) {
    case types.GET_NOTAS_BY_USER:
      return _.uniqBy([...state, ...action.payload], 'id')
    default:
      return state
  }
}

export default notas