import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Filters from '../components/Filters';
import DocumentList from '../components/DocumentList';
import { getAllUsers, createBasicUser } from '../actions';
import { Modal, Input, Form, Alert, Select } from 'antd'


const { Option } = Select;

function Users(props) {
  const { getAllUsers, users, createBasicUser } = props;
  const [filter, setFilter] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showMsg, setShowMsg] = useState(false)
  
  const [email, setEmail] = useState('')
  const [rol, setRol] = useState('admin')


  useEffect(() => {
    getAllUsers()
  }, []);


  function close() {
    localStorage.removeItem('deletedUser')
  }





  const actions = [
    {
      label: 'Exportar usuarios',
      type: 'excelExportUsers'
    },
    {
      label: 'Nuevo usuario',
      onClick: () => setIsModalVisible(true),
      type: 'button'
    }
  ]

  const saveNewUser = async () => {
    if (email && email != '' && rol && rol != '' && email.includes('@')) {
      createBasicUser({email, rol})
      setIsModalVisible(false)
      setTimeout(function(){ getAllUsers() }, 500);
    }
  }


  function setEmailFunc(event) {
    var value = event.target.value.replaceAll(' ', '')
    setEmail(value) 

    if (value && value != '' && value.includes('@')) {
      setShowMsg(false)
    } else {
      setShowMsg(true)
    }
  }
  function setRolFunc(value) { setRol(value) }



  return (
    <>

      <Filters options={['Todos', 'Administradores', 'Usuarios']} setFilter={setFilter} actions={actions} data={users} />
      <div className="container">

        {localStorage.getItem('deletedUser')? <div className="my-4">
          <Alert
            showIcon 
            message="Usuario eliminado correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        <DocumentList
          data={users}
          filter={filter}
          fields={[
            {
              label: 'Nombre',
              name: 'name',
            },
            {
              label: 'Rol',
              name: 'rol',
            },
            {
              label: 'E-Mail',
              name: 'email',
            },
            {
              label: 'Suscrito hasta el',
              name: 'suscriberUntilNew',
            },
            {
              label: 'Ultimo Inicio de sesión',
              name: 'lastLogin',
            },
            /* {
              label: 'Proveedor',
              name: 'provider',
            }, */
          ]}
        />
      </div>



      <Modal
        title="Nuevo usuario a registrar"
        visible={isModalVisible}
        onOk={saveNewUser}
        onCancel={() => setIsModalVisible(false)}
        okText="Crear"
        destroyOnClose
        >
        <Form preserve={false} className='flex w-full'>
          <Form.Item className='w-full mr-2'>
            <Input type={'email'} id="email" name='email' onChange={setEmailFunc} placeholder="email"/>
            {showMsg? <p className='text-xs text-red-600'>*Formato incorrecto del campo de email...</p> : null}
          </Form.Item>
          <Form.Item className='w-full'>
            <Select id="rol" name='rol' onChange={setRolFunc} defaultValue="admin">
              <Option value="admin">Administrador</Option>
              <Option value="user">Usuario base</Option>
            </Select>
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
  return {
    getAllUsers: () => dispatch(getAllUsers()),
    createBasicUser: input => dispatch(createBasicUser(input)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
