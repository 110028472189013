import * as types from '../constants/ActionTypes';

export const books = (state = [], action) => {
  switch (action.type) {
    case types.GET_ALL_BOOKS:
      return action.payload;
    case types.DELETE_BOOK:
      return action.payload;
    default:
      return state;
  }
};

export const book = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DATA_BOOK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
