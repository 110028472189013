import * as types from '../constants/ActionTypes'

const emailTemplates = (state = [], action) => {
  switch(action.type) {
    case types.GET_EMAIL_TEMPLATES:
      return action.payload
    case types.GET_EMAIL_TEMPLATE:
      return action.payload
    case types.UPDATE_EMAIL_TEMPLATE:
      return action.payload
    default:
      return state
  }
}

export default emailTemplates