import { NavLink as Link } from 'react-router-dom'

function SettingsLayout({children}) {

  return (
    <>
    <div className="grid gap-5" style={{ gridTemplateColumns: 'auto 1fr' }}>
      <div className="border-r h-screen flex">
        <Link to="/" className="fixed top-5 right-5 dark:text-gray-400 dark:hover:text-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </Link>
        <div className="py-5">
          <h1 className="text-3xl font-semibold mb-3 px-3">Configuración</h1>
          <nav className="flex flex-col w-56">
            <Link to="/configuracion/general" className="flex items-center space-x-2 px-3 py-2 text-semibold" activeClassName="bg-blue-100 dark:bg-gray-100">
              {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
              </svg> */}
              <span>General</span>
            </Link>
            <Link to="/configuracion/appearance" exact className="flex items-center space-x-2 px-3 py-2 text-semibold" activeClassName="bg-blue-100 dark:bg-gray-100">
              {/* <svg className="w-5 h-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M167.02 309.34c-40.12 2.58-76.53 17.86-97.19 72.3-2.35 6.21-8 9.98-14.59 9.98-11.11 0-45.46-27.67-55.25-34.35C0 439.62 37.93 512 128 512c75.86 0 128-43.77 128-120.19 0-3.11-.65-6.08-.97-9.13l-88.01-73.34zM457.89 0c-15.16 0-29.37 6.71-40.21 16.45C213.27 199.05 192 203.34 192 257.09c0 13.7 3.25 26.76 8.73 38.7l63.82 53.18c7.21 1.8 14.64 3.03 22.39 3.03 62.11 0 98.11-45.47 211.16-256.46 7.38-14.35 13.9-29.85 13.9-45.99C512 20.64 486 0 457.89 0z" />
              </svg> */}
              <span>Apariencia</span>
            </Link>
          </nav>
        </div>
      </div>
      <div className="px-10 pt-6">
        { children }
      </div>
    </div>
    </>
  )
}

export default SettingsLayout