function SettingsApearance() {

  return (
    <>
    <header>
      <h2 className="text-3xl font-black">Apariencia</h2>
    </header>
    <div className="py-5">

    </div>
    </>
  )
}

export default SettingsApearance