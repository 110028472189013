import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUserSuscripcion } from '../actions'


function RegistroSuscriptcionesRedirect(props) {
  const { getUserSuscripcion, suscripciones } = props
  const { id } = props.match.params


  useEffect(() => {
    getUserSuscripcion(id)
  }, [])



  useEffect(() => {
    if (suscripciones && suscripciones.userToken) {
      window.location.href = "/usuarios/"+suscripciones.userToken;
    }
  }, [suscripciones])
  
  return null

}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getUserSuscripcion: (id) => dispatch(getUserSuscripcion(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistroSuscriptcionesRedirect)