import * as types from '../constants/ActionTypes'

const notifications = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_NOTIFICATIONS:
      return action.payload
    case types.GET_NOTIFICATION:
      return state.concat(action.payload)
    case types.NEW_NOTIFICATION:
      return [ ...state, action.payload ]
    case types.PUBLISH_NOTIFICATION:
      const { id, isPublished } = action.payload
      return state.map(notification => {
        if(notification.id === id) return { ...notification, isPublished: isPublished }
        return notification
      })
    case types.UPDATE_NOTIFICATION:
      const { id: nid } = action.payload
      return state.map(notification => {
        if (notification.id === nid) return { ...notification, ...action.payload }
        return notification
      })
    case types.DELETE_NOTIFICATION:
      return true
    default:
      return state
  }
}

export default notifications