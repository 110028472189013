import * as types from '../constants/ActionTypes'

const plans = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_PLANS:
      return action.payload
    case types.GET_PLAN:
      return action.payload;
    case types.DELETE_PLAN:
      return action.payload;
    default:
      return state
  }
}

export default plans