import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import { getAllNotifications, createNotification } from '../actions'
import DocumentList from '../components/DocumentList'
import { Modal, Input, Form, Alert, Steps, Button } from 'antd'

const { TextArea } = Input
const { Step } = Steps;

function Notifications(props){

  const { getAllNotifications, notifications, createNotification } = props
  const [filter, setFilter] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [input, setInput] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)

  const svg_next = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_prev = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;



  useEffect(() => {
    getAllNotifications()
  }, [])

  const actions = [
    {
      label: 'Nueva notificación',
      onClick: () => setIsModalVisible(true),
      type: 'button'
    }
  ]

  const saveNewNotification = async () => {
    setCurrentStep(0);
    createNotification(input)
    setIsModalVisible(false)
    setTimeout(function(){ getAllNotifications() }, 300);
  }

  const onChange = event => {
    setInput({ ...input, [event.target.id]: event.target.value })
  }
  
  function close() {
    localStorage.removeItem('deletedNotification')
  }
  
  return (
    <>
      <Filters options={['Todas', 'Publicadas', 'No publicadas']} actions={actions} setFilter={setFilter}  />
      <div className="container">
        {localStorage.getItem('deletedNotification')? <div className="my-4">
          <Alert
            showIcon 
            message="Plan de lectura eliminado correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        <DocumentList 
          data={notifications}
          filter={filter}
          fields={[
            {
              label: 'Título',
              name: 'title'
            },
            {
              label: 'Fecha',
              name: 'createdAt'
            },
            {
              label: 'Publicado',
              name: 'publishedAt'
            }
          ]} />
      </div>


      <Modal
        title="Nueva notificación"
        visible={isModalVisible}
        onOk={saveNewNotification}
        okText="Guardar notificación"
        cancelText="Cancelar"

        okButtonProps={{ disabled: currentStep === 1? false : true }}

        onCancel={() => { setIsModalVisible(false); setCurrentStep(0) }}
        destroyOnClose
        >
        <Form
          preserve={false}
          onChange={onChange}
        >
          <div className='px-2 pb-7'>
            <Steps current={currentStep} onChange={setCurrentStep}>
              <Step title="Español" />
              <Step title="Inglés" />
            </Steps>
          </div>


          <div className='mb-3' style={{ display: currentStep === 0? 'inherit' : 'none' }}>
            <Form.Item>
              <Input id="title" placeholder="Título en español..." />
            </Form.Item>
            <Form.Item>
              <TextArea
                id="body"
                defaultValue=""
                placeholder="Cuerpo en español..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item>
              <Input id="link" placeholder="Link para la notificación en español..." />
            </Form.Item>

            <div className='w-full flex justify-between'>
              <Button htmlType="button" size="large" type="primary" disabled={true}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep+1)}>
                <div className='flex items-center'>
                  <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                  <div className='flex items-center ml-2'>{svg_next}</div>
                </div>
              </Button>
            </div>
          </div>

          <div className='mb-3' style={{ display: currentStep === 1? 'inherit' : 'none' }}>
            <Form.Item>
              <Input id="titleENG" placeholder="Título en inglés..." />
            </Form.Item>
            <Form.Item>
              <TextArea
                id="bodyENG"
                defaultValue=""
                placeholder="Cuerpo en inglés..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item>
              <Input id="linkENG" placeholder="Link para la notificación en inglés..." />
            </Form.Item>

            <div className='w-full flex justify-between'>
              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              <Button htmlType="button" size="large" type="primary" disabled={true}>
                <div className='flex items-center'>
                  <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                  <div className='flex items-center ml-2'>{svg_next}</div>
                </div>
              </Button>
            </div>
          </div>


        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllNotifications: () => dispatch(getAllNotifications()),
    createNotification: input => dispatch(createNotification(input)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)