import * as types from '../constants/ActionTypes'

const approvedChapterReviews = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_APPROVED_CHAPTER_REVIEWS:
      return action.payload
    case types.GET_APPROVED_CHAPTER_REVIEW:
      return action.payload
    case types.NEW_APPROVED_CHAPTER_REVIEW:
      return action.payload
    case types.DELETE_APPROVED_CHAPTER_REVIEW:
      return action.payload
    default:
      return state
  }
}

export default approvedChapterReviews

