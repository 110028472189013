export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER = 'GET_USER';
export const NEW_USER = 'NEW_USER';
export const GET_DESTACADOS_BY_USER = 'GET_DESTACADOS_BY_USER';
export const DELETE_USER = 'DELETE_USER';

export const GET_NOTAS_BY_USER = 'GET_NOTAS_BY_USER';
export const GET_TAGS_BY_USER = 'GET_TAGS_BY_USER';
export const GET_INDICES_DESTACADOS_BY_USER = 'GET_INDICES_DESTACADOS_BY_USER';
export const GET_INDICES_COMPLETEDS_BY_USER = 'GET_INDICES_COMPLETEDS_BY_USER';

export const REGISTER_LOGIN = 'REGISTER_LOGIN';
export const GET_LOG_LOGINS_BY_USER = 'GET_LOG_LOGINS_BY_USER';

export const GET_ALL_BOOKS = 'GET_ALL_BOOKS';
export const DELETE_BOOK = 'DELETE_BOOK';
export const GET_RECURSO_BY_SLUG = 'GET_RECURSO_BY_SLUG';

export const GET_ALL_INDICES = 'GET_ALL_INDICES';

export const GET_PLAN = 'GET_PLAN';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const DELETE_PLAN = 'DELETE_PLAN';

export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';


export const GET_ALL_PLAN_CATEGORIAS = 'GET_ALL_PLAN_CATEGORIAS';
export const UPDATE_PLAN_CATEGORIA = 'UPDATE_PLAN_CATEGORIA';
export const GET_PLAN_CATEGORIA = 'GET_PLAN_CATEGORIA';
export const NEW_PLAN_CATEGORIA = 'NEW_PLAN_CATEGORIA';

export const GET_ALL_VIDEO_RESOURCES = 'GET_ALL_VIDEO_RESOURCES';
export const NEW_VIDEO_RESOURCES = 'NEW_VIDEO_RESOURCES';
export const GET_VIDEO_RESOURCES = 'GET_VIDEO_RESOURCES';

export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const PUBLISH_NOTIFICATION = 'PUBLISH_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export const GET_DATA_BOOK = 'GET_DATA_BOOK';


export const GET_ALL_RECENT_ACTIVITY = 'GET_ALL_RECENT_ACTIVITY';



export const GET_ALL_MENSAJES = 'GET_ALL_MENSAJES';
export const GET_MENSAJE = 'GET_MENSAJE';
export const NEW_MENSAJE = 'NEW_MENSAJE';
export const MARK_MENSAJE = 'MARK_MENSAJE';


export const GET_ALL_SUBSCRIPTIONS = 'GET_ALL_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTION= 'UPDATE_SUBSCRIPTION';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const NEW_SUBSCRIPTION = 'NEW_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';

export const GET_ALL_GLOSSARY_WORDS = 'GET_ALL_GLOSSARY_WORDS';
export const GET_GLOSSARY_WORD = 'GET_GLOSSARY_WORD';
export const NEW_GLOSSARY_WORD = 'NEW_GLOSSARY_WORD';
export const DELETE_GLOSSARY_WORD = 'DELETE_GLOSSARY_WORD';


export const GET_ALL_CHAPTER_REVIEWS = 'GET_ALL_CHAPTER_REVIEWS';
export const GET_CHAPTER_REVIEW = 'GET_CHAPTER_REVIEW';
export const NEW_CHAPTER_REVIEW = 'NEW_CHAPTER_REVIEW';
export const DELETE_CHAPTER_REVIEW = 'DELETE_CHAPTER_REVIEW';

export const GET_ALL_APPROVED_CHAPTER_REVIEWS = 'GET_ALL_APPROVED_CHAPTER_REVIEWS';
export const GET_APPROVED_CHAPTER_REVIEW = 'GET_APPROVED_CHAPTER_REVIEW';
export const NEW_APPROVED_CHAPTER_REVIEW = 'NEW_APPROVED_CHAPTER_REVIEW';
export const DELETE_APPROVED_CHAPTER_REVIEW = 'DELETE_APPROVED_CHAPTER_REVIEW';

// Layout
export const SHOW_FILTER = 'SHOW_FILTER';
export const HIDE_FILTER = 'HIDE_FILTER';

export const GET_AUDIOS_BY_SLUG = 'GET_AUDIOS_BY_SLUG';
export const DELETE_AUDIO = 'DELETE_AUDIO';
export const GET_ALL_AUDIOS = 'GET_ALL_AUDIOS';


