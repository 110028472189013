import { useEffect, useState } from 'react'


function UserDestacados(props){

  const { id, destacados } = props
  const numberOf = destacados?.filter(destacado => destacado.userId === id && !destacado.isRemoved).length ?? null


  return (
    <div>

        <div className="container py-5">
            <h3 className="text-xl font-semibold mb-3">
            Destacados <span>{numberOf ? `(${numberOf})` : ``}</span>
            </h3>

            {destacados?.filter(destacado => destacado.userId === id && !destacado.isRemoved)?.map(destacado => ( <>
                <div key={destacado.id} className="mb-2 bg-gray-50 p-2 border rounded-sm">
                    <h2 className="text-base font-bold">{destacado.indice.title === 'Notas' ? 'Nota' : destacado.indice.title} del libro {destacado.recurso.titulo}</h2>
                    <p className="leading-normal">{destacado.text}</p>
                </div>
                <div className='flex space-x-2 flex-wrap mb-10'>
                    {destacado?.tags?.map(tag => {
                        return <div key={tag.id} className="bg-gray-50 px-2 border rounded-sm text-gray-400">
                            # {tag.name}
                        </div>
                    })}
                </div>
            </>))}

            {destacados?.filter(destacado => destacado.userId === id && !destacado.isRemoved).length < 1? 'Sin destacados registrados...' : null}
        </div>

    </div>
  )
}

export default UserDestacados