import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getEmailTemplate, updateEmailTemplate, emailTemplatesBySlug } from '../actions'
import { Modal, Button, Form, Steps, Divider } from 'antd'
import { useHistory } from 'react-router-dom';

import EmailTemplateHtml from '../components/EmailTemplate'
import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';


const { Step } = Steps;

function EmailTemplate(props) {

  const { getEmailTemplate, emailTemplates:emailTemplatesQuery, updateEmailTemplate, emailTemplatesBySlug } = props
  const { slug } = props.match.params

  const [emailTemplates, setEmailTemplates] = useState(null)
  const [emailTemplatesENG, setEmailTemplatesENG] = useState(null)

  const [currentStep, setCurrentStep] = useState(0)

  const [textBody, setTextBody] = useState('');
  const [textBodyENG, setTextBodyENG] = useState('');

  const [textTitle, setTextTitle] = useState('');
  const [textTitleENG, setTextTitleENG] = useState('');

  const [valid, setValid] = useState(false);
  const [validENG, setValidENG] = useState(false);

  const [ready, setReady] = useState(false);
  const [readyENG, setReadyENG] = useState(false);

  const history = useHistory();
  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;
  const svg_next = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_prev = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;



  useEffect(() => {
    /* getEmailTemplate(slug) */
    emailTemplatesBySlug(slug)
  }, [])

  useEffect(() => {
    setEmailTemplates(null)
    setEmailTemplatesENG(null)

    emailTemplatesQuery.map(element => {
      if (element.language === 'eng') {
        setEmailTemplatesENG(element)
      } else {
        setEmailTemplates(element)
      }
    });
  }, [emailTemplatesQuery])


  useEffect(() => {
    if (textTitle && textBody) {
      var validTextTitle = textTitle.replaceAll(' ', '')
      var validTextBody = textBody.replaceAll(' ', '').replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '')

      if (validTextTitle && validTextTitle!='' && validTextBody && validTextBody!='') {
        setValid(true)
      } else {
        setValid(false)
      }
    } else {
      setValid(false)
    }

    if (textTitleENG && textBodyENG) {
      var validTextTitle = textTitleENG.replaceAll(' ', '')
      var validTextBody = textBodyENG.replaceAll(' ', '').replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '')

      if (validTextTitle && validTextTitle!='' && validTextBody && validTextBody!='') {
        setValidENG(true)
      } else {
        setValidENG(false)
      }
    } else {
      setValidENG(false)
    }

    window.scrollTo(0,document.body.scrollHeight);
  }, [textTitle, textBody, textTitleENG, textBodyENG])



  useEffect(() => {
    if (emailTemplates) {
      /* setDate(new Date(emailTemplates.createdAt)) */
      setTextBody(emailTemplates.body)
      setTextTitle(emailTemplates.title)
      setReady(true)
    }
    if (emailTemplatesENG) {
      setTextBodyENG(emailTemplatesENG.body)
      setTextTitleENG(emailTemplatesENG.title)
      setReadyENG(true)
    }
  }, [emailTemplates, emailTemplatesENG])




  const onSubmit = () => {

    if (textTitle && textBody && emailTemplates) {
      var validTextTitle = textTitle.replaceAll(' ', '')
      var validTextBody = textBody.replaceAll(' ', '').replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '')

      var validTextTitleENG = textTitleENG.replaceAll(' ', '')
      var validTextBodyENG = textBodyENG.replaceAll(' ', '').replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '')

      if (validTextTitle && validTextTitle!='' && validTextBody && validTextBody!='') {

        updateEmailTemplate({
          id: emailTemplates.id,
          title: textTitle,
          body: textBody,
        })

        if (emailTemplatesENG && emailTemplatesENG.title && validTextTitleENG && validTextTitleENG!='' && validTextBodyENG && validTextBodyENG!='') {
          updateEmailTemplate({
            id: emailTemplatesENG.id,
            title: textTitleENG,
            body: textBodyENG,
          })
        }


        window.location.href = "/emails";
        /* history.push('/emails'); */
      } else {
        return false
      }
    } else {
      return false
    }
  };



  
  const validateMessages = {
    required: `Este campo es obligatorio...`,
    // ...
  };

  

  return (
    <>

      {emailTemplatesENG && emailTemplatesENG.title && <div className='px-5 py-5'>
        <Steps current={currentStep} onChange={setCurrentStep}>
          <Step title="Español" />
          <Step title="Inglés" />
        </Steps>
      </div>}


      {currentStep === 0 && ( emailTemplates && emailTemplates.title && ready? <>

        <h1 className='py-2 px-3 font-bold bg-gray-100'>Muestra del email definido en español:</h1>
        <hr className='bg-gray-200 border-gray-300'></hr>

        <div className=''>
          <EmailTemplateHtml slug={slug} textTitle={textTitle} textBody={textBody}/>
        </div> 
        <hr className='border-gray-300'></hr>

        <div className='p-4'>
          <Form onFinish={fields => onSubmit(fields)} >

            <label>Cabecera:</label>
            <Form.Item>
              <input type="text" id="title" name="title" placeholder="Título" value={textTitle} onChange={(fields => setTextTitle(fields.target.value) )} className="border w-full py-1 px-2 rounded-sm border-gray-300"/>
            </Form.Item>

            <label>Cuerpo:</label>
            <ReactQuill id="description" theme="snow" value={textBody? textBody : ''} onChange={setTextBody}/>

            <div className='w-full flex justify-start flex-wrap items-center font-bold text-gray-500 text-xs'>
              <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %name% = Nombre del usuario
              </p>
              <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %email% = Email del usuario
              </p>
              {emailTemplates.slug === 'plan_finalizado' && <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %plan_title% = Título del plan de lectura
              </p>}
              {/* {emailTemplates.slug === 'plan_finalizado' && <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %plan_link% = Url del plan de lectura
              </p>} */}
              {(emailTemplates.slug === 'libro_finalizado' || emailTemplates.slug === 'articulo_finalizado') && <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %resource_title% = Título del recurso
              </p>}
              {/* {emailTemplates.slug === 'recurso_finalizado' && <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %resource_link% = Url del recurso
              </p>} */}
            </div>

            <br></br>
            <div className='flex justify-between'>
              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)} disabled={'disabled'}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              {emailTemplatesENG && emailTemplatesENG.title ?
                <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep+1)} disabled={valid? '' : 'disabled'}>
                  <div className='flex items-center'>
                    <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                    <div className='flex items-center ml-2'>{svg_next}</div>
                  </div>
                </Button>
                :
                <Button htmlType="submit" size="large" type="primary" disabled={valid? '' : 'disabled'}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_save}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                  </div>
                </Button>
              }
            </div>

          </Form>
        </div>

      </> : <div className='mx-5'>Este email no ha sido configurado en el idioma seleccionado...</div> )}


      {currentStep === 1 && ( emailTemplatesENG && emailTemplatesENG.title && readyENG? <>

        <h1 className='py-2 px-3 font-bold bg-gray-100'>Muestra del email definido en inglés:</h1>
        <hr className='bg-gray-200 border-gray-300'></hr>

        <div className=''>
          <EmailTemplateHtml slug={slug} textTitle={textTitleENG} textBody={textBodyENG}/>
        </div> 
        <hr className='border-gray-300'></hr>

        <div className='p-4'>
          <Form onFinish={fields => onSubmit(fields)} >

            <label>Cabecera:</label>
            <Form.Item>
              <input type="text" id="title" name="title" placeholder="Título" value={textTitleENG} onChange={(fields => setTextTitleENG(fields.target.value) )} className="border w-full py-1 px-2 rounded-sm border-gray-300"/>
            </Form.Item>

            <label>Cuerpo:</label>
            <ReactQuill id="description" theme="snow" value={textBodyENG? textBodyENG : ''} onChange={setTextBodyENG}/>

            <div className='w-full flex justify-start flex-wrap items-center font-bold text-gray-500 text-xs'>
              <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %name% = Nombre del usuario
              </p>
              <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %email% = Email del usuario
              </p>
              {emailTemplates.slug === 'plan_finalizado' && <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %plan_title% = Título del plan de lectura
              </p>}
              {(emailTemplates.slug === 'libro_finalizado' || emailTemplates.slug === 'articulo_finalizado') && <p className='mr-2 mt-2 px-2 py-1 bg-gray-200 rounded'>
                %resource_title% = Título del recurso
              </p>}
            </div>

            <br></br>
            <div className='flex justify-between'>
              <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_prev}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>

              <Form.Item>
                <Button htmlType="submit" size="large" type="primary" disabled={validENG && valid? '' : 'disabled'}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_save}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                  </div>
                </Button>
              </Form.Item>
            </div>

          </Form>
        </div>

      </> : <div className='mx-5'>Este email no ha sido configurado en el idioma seleccionado...</div> )}

    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getEmailTemplate: slug => dispatch(getEmailTemplate(slug)),
    updateEmailTemplate: (input) => dispatch(updateEmailTemplate(input)),
    emailTemplatesBySlug: slug => dispatch(emailTemplatesBySlug(slug)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate)