import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';


function UserLogIns(props){

    const { id, logLogIn } = props
    const logLogIns = logLogIn;
    const numberOf = logLogIn.length ?? null


    return (
        <div>

            <div className="container py-5">
                <h3 className="text-xl font-semibold mb-3">
                    Inicios de sesión registrados <span>{numberOf ? `(${numberOf})` : ``}</span>
                </h3>

                {logLogIns && logLogIns.length >= 1 && 
                    <table className='w-full bg-gray-50 border'>
                        <tr className='border bg-gray-100'>
                            <th className='border p-2'>Cliente</th>
                            <th className='border p-2'>Ip / Info adicional</th>
                            <th className='border p-2'>Navegador</th>
                            <th className='border p-2'>Fecha</th>
                        </tr>
                        {logLogIns?.map(login => {

                            var dateTMP = new Date(login.createdAt)
                            console.log(login)

                            return <tr key={login.id} className="border">
                                <td className="border text-base text-center p-2">{login.site}</td>
                                <td className='text-center flex justify-center space-x-2 p-2'>
                                    <h1 className="text-base px-1">{login.ip}</h1>
                                    {login.deviceType? 
                                        <div>
                                            <Tooltip title={login.deviceType}>    
                                                {login.deviceType === 'Desktop'? 
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M6.604 18.333V16.583H8.25V15H3.417Q2.688 15 2.177 14.49Q1.667 13.979 1.667 13.25V4.25Q1.667 3.521 2.177 3.01Q2.688 2.5 3.417 2.5H16.583Q17.312 2.5 17.823 3.01Q18.333 3.521 18.333 4.25V13.25Q18.333 13.979 17.823 14.49Q17.312 15 16.583 15H11.75V16.583H13.396V18.333ZM3.417 13.25H16.583Q16.583 13.25 16.583 13.25Q16.583 13.25 16.583 13.25V4.25Q16.583 4.25 16.583 4.25Q16.583 4.25 16.583 4.25H3.417Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25V13.25Q3.417 13.25 3.417 13.25Q3.417 13.25 3.417 13.25ZM3.417 13.25Q3.417 13.25 3.417 13.25Q3.417 13.25 3.417 13.25V4.25Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25V13.25Q3.417 13.25 3.417 13.25Q3.417 13.25 3.417 13.25Z"/></svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M5.917 19.167Q5.188 19.167 4.677 18.656Q4.167 18.146 4.167 17.417V2.583Q4.167 1.854 4.677 1.344Q5.188 0.833 5.917 0.833H14.083Q14.812 0.833 15.323 1.344Q15.833 1.854 15.833 2.583V17.417Q15.833 18.146 15.323 18.656Q14.812 19.167 14.083 19.167ZM5.917 14.792H14.083V5.208H5.917ZM5.917 16.542V17.417Q5.917 17.417 5.917 17.417Q5.917 17.417 5.917 17.417H14.083Q14.083 17.417 14.083 17.417Q14.083 17.417 14.083 17.417V16.542ZM5.917 3.458H14.083V2.583Q14.083 2.583 14.083 2.583Q14.083 2.583 14.083 2.583H5.917Q5.917 2.583 5.917 2.583Q5.917 2.583 5.917 2.583ZM5.917 2.583Q5.917 2.583 5.917 2.583Q5.917 2.583 5.917 2.583V3.458V2.583Q5.917 2.583 5.917 2.583Q5.917 2.583 5.917 2.583ZM5.917 17.417Q5.917 17.417 5.917 17.417Q5.917 17.417 5.917 17.417V16.542V17.417Q5.917 17.417 5.917 17.417Q5.917 17.417 5.917 17.417Z"/></svg>
                                                }
                                            </Tooltip> 
                                        </div> 
                                        : 
                                        <div className='opacity-30'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M6.604 18.333V16.583H8.25V15H3.417Q2.688 15 2.177 14.49Q1.667 13.979 1.667 13.25V4.25Q1.667 3.521 2.177 3.01Q2.688 2.5 3.417 2.5H16.583Q17.312 2.5 17.823 3.01Q18.333 3.521 18.333 4.25V13.25Q18.333 13.979 17.823 14.49Q17.312 15 16.583 15H11.75V16.583H13.396V18.333ZM3.417 13.25H16.583Q16.583 13.25 16.583 13.25Q16.583 13.25 16.583 13.25V4.25Q16.583 4.25 16.583 4.25Q16.583 4.25 16.583 4.25H3.417Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25V13.25Q3.417 13.25 3.417 13.25Q3.417 13.25 3.417 13.25ZM3.417 13.25Q3.417 13.25 3.417 13.25Q3.417 13.25 3.417 13.25V4.25Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25Q3.417 4.25 3.417 4.25V13.25Q3.417 13.25 3.417 13.25Q3.417 13.25 3.417 13.25Z"/></svg>
                                        </div>
                                    }
                                    {login.fullOperativeSystem? 
                                        <div>
                                            <Tooltip title={login.fullOperativeSystem}>    
                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M1.667 16.667V14.042H3.312V5.083Q3.312 4.354 3.823 3.844Q4.333 3.333 5.062 3.333H17.458V5.083H5.062Q5.062 5.083 5.062 5.083Q5.062 5.083 5.062 5.083V14.042H10.083V16.667ZM12.688 16.667Q12.333 16.667 12.083 16.417Q11.833 16.167 11.833 15.833V7.646Q11.833 7.292 12.083 7.063Q12.333 6.833 12.688 6.833H17.542Q17.875 6.833 18.104 7.063Q18.333 7.292 18.333 7.646V15.833Q18.333 16.167 18.104 16.417Q17.875 16.667 17.542 16.667ZM13.583 14.042H16.583V8.583H13.583Z"/></svg>
                                            </Tooltip> 
                                        </div> 
                                        : 
                                        <div className='opacity-30'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M1.667 16.667V14.042H3.312V5.083Q3.312 4.354 3.823 3.844Q4.333 3.333 5.062 3.333H17.458V5.083H5.062Q5.062 5.083 5.062 5.083Q5.062 5.083 5.062 5.083V14.042H10.083V16.667ZM12.688 16.667Q12.333 16.667 12.083 16.417Q11.833 16.167 11.833 15.833V7.646Q11.833 7.292 12.083 7.063Q12.333 6.833 12.688 6.833H17.542Q17.875 6.833 18.104 7.063Q18.333 7.292 18.333 7.646V15.833Q18.333 16.167 18.104 16.417Q17.875 16.667 17.542 16.667ZM13.583 14.042H16.583V8.583H13.583Z"/></svg>
                                        </div>
                                    }
                                    {login.fullAgent? 
                                        <div>
                                            <Tooltip title={login.fullAgent}>    
                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M9.875 18.333Q8.167 18.312 6.677 17.646Q5.188 16.979 4.073 15.854Q2.958 14.729 2.312 13.219Q1.667 11.708 1.667 10Q1.667 8.271 2.323 6.75Q2.979 5.229 4.104 4.104Q5.229 2.979 6.75 2.323Q8.271 1.667 10 1.667Q13.042 1.667 15.302 3.552Q17.562 5.438 18.167 8.292H16.354Q15.958 6.812 14.948 5.656Q13.938 4.5 12.479 3.917V4.229Q12.479 4.917 12 5.396Q11.521 5.875 10.833 5.875H9.188V7.521Q9.188 7.875 8.948 8.115Q8.708 8.354 8.354 8.354H6.708V10H8.208V12.458H7.542L3.583 8.5Q3.5 8.875 3.458 9.25Q3.417 9.625 3.417 10Q3.417 12.667 5.281 14.594Q7.146 16.521 9.875 16.583ZM17.5 17.917 14.896 15.312Q14.458 15.604 13.958 15.74Q13.458 15.875 12.917 15.875Q11.333 15.875 10.229 14.771Q9.125 13.667 9.125 12.083Q9.125 10.5 10.229 9.396Q11.333 8.292 12.917 8.292Q14.5 8.292 15.604 9.396Q16.708 10.5 16.708 12.083Q16.708 12.625 16.573 13.125Q16.438 13.625 16.146 14.083L18.75 16.667ZM12.917 14.125Q13.771 14.125 14.365 13.531Q14.958 12.938 14.958 12.083Q14.958 11.229 14.365 10.635Q13.771 10.042 12.917 10.042Q12.062 10.042 11.469 10.635Q10.875 11.229 10.875 12.083Q10.875 12.938 11.469 13.531Q12.062 14.125 12.917 14.125Z"/></svg>
                                            </Tooltip> 
                                        </div> 
                                        :
                                        <div className='opacity-30'>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M9.875 18.333Q8.167 18.312 6.677 17.646Q5.188 16.979 4.073 15.854Q2.958 14.729 2.312 13.219Q1.667 11.708 1.667 10Q1.667 8.271 2.323 6.75Q2.979 5.229 4.104 4.104Q5.229 2.979 6.75 2.323Q8.271 1.667 10 1.667Q13.042 1.667 15.302 3.552Q17.562 5.438 18.167 8.292H16.354Q15.958 6.812 14.948 5.656Q13.938 4.5 12.479 3.917V4.229Q12.479 4.917 12 5.396Q11.521 5.875 10.833 5.875H9.188V7.521Q9.188 7.875 8.948 8.115Q8.708 8.354 8.354 8.354H6.708V10H8.208V12.458H7.542L3.583 8.5Q3.5 8.875 3.458 9.25Q3.417 9.625 3.417 10Q3.417 12.667 5.281 14.594Q7.146 16.521 9.875 16.583ZM17.5 17.917 14.896 15.312Q14.458 15.604 13.958 15.74Q13.458 15.875 12.917 15.875Q11.333 15.875 10.229 14.771Q9.125 13.667 9.125 12.083Q9.125 10.5 10.229 9.396Q11.333 8.292 12.917 8.292Q14.5 8.292 15.604 9.396Q16.708 10.5 16.708 12.083Q16.708 12.625 16.573 13.125Q16.438 13.625 16.146 14.083L18.75 16.667ZM12.917 14.125Q13.771 14.125 14.365 13.531Q14.958 12.938 14.958 12.083Q14.958 11.229 14.365 10.635Q13.771 10.042 12.917 10.042Q12.062 10.042 11.469 10.635Q10.875 11.229 10.875 12.083Q10.875 12.938 11.469 13.531Q12.062 14.125 12.917 14.125Z"/></svg>
                                        </div>
                                    }
                                </td>
                                <td className="border text-base text-center p-2">{login.agent}</td>
                                <td className="border text-base text-center p-2">{dateTMP.getDate() < 10? '0'+dateTMP.getDate() : dateTMP.getDate()}/{dateTMP.getMonth() < 10? '0'+dateTMP.getMonth() : dateTMP.getMonth()}/{dateTMP.getFullYear()} {dateTMP.getHours()-2}:{dateTMP.getMinutes() < 10? '0'+dateTMP.getMinutes() : dateTMP.getMinutes()}</td>
                            </tr>
                        })}
                    </table>
                }

                {logLogIns.length < 1? 'Sin registros de inicio de sesión registrados...' : null}
            </div>

        </div>
    )
}

export default UserLogIns