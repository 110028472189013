import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllPlanCategorias, createPlanCategoria } from '../actions'
import { Modal, Input, Form, Alert } from 'antd'
import { useHistory } from 'react-router-dom';


function PlanCategorias(props) {

  const { getAllPlanCategorias, planCategorias, createPlanCategoria } = props

  const [filter, setFilter] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [input, setInput] = useState(null)
  const [color, setColor] = useState('#D3D3D3')


  const saveNewPlanCategoria = async () => {
    createPlanCategoria(input)
    setIsModalVisible(false)
    setTimeout(function(){ getAllPlanCategorias() }, 300);
  }


  useEffect(() => {
    getAllPlanCategorias()
  }, [])

  function close() {
    localStorage.removeItem('deletedPlanCategoria')
  }


  const actions = [
    {
      label: 'Nueva categoría',
      onClick: () => setIsModalVisible(true),
      type: 'button'
    },
    {
      label: 'Planes de lectura',
      to: '/planes',
      type: 'link'
    }
  ]


  const onChange = event => {
    setInput({ ...input, [event.target.id]: event.target.value })
  }


  function setColorFunc(hex) {
    if (hex.target.value) {
      setColor(hex.target.value)
    } else {
      setColor('#D3D3D3')
    }
  }




  return (
    <>
      <Filters options={['Todos']} setFilter={setFilter} actions={actions} />
      <div className="container">

        {localStorage.getItem('deletedPlanCategoria')? <div className="my-4">
          <Alert
            showIcon 
            message="Plan de lectura eliminado correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        <DocumentList 
          data={planCategorias}
          filter={filter}
          fields={[
            {
              label: 'Nombre',
              name: 'name'
            },
            {
              label: 'Color',
              name: 'color'
            },
            {
              label: 'Creado el',
              name: 'createdAt'
            },
            /* {
              label: 'Eliminar',
              name: 'remove'
            }, */
          ]} />
      </div>



      <Modal
        title="Nueva categoría para los planes de lectura"
        visible={isModalVisible}
        onOk={saveNewPlanCategoria}
        onCancel={() => setIsModalVisible(false)}
        okText="Crear"
        destroyOnClose
        >
        <Form preserve={false} onChange={onChange} className='flex w-full'>
          <Form.Item className='w-full'>
            <Input id="name" placeholder="Nombre" rules={[{ required: true }]}/>
          </Form.Item>
          <Form.Item className='w-28 ml-2' onChange={setColorFunc}>
            <Input id="color" placeholder="FFC17A" maxLength="7" />
          </Form.Item>
          <div id="colorBox" className='ml-2 w-20 h-8 rounded-sm' style={{ background: color }}></div>
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllPlanCategorias: () => dispatch(getAllPlanCategorias()),
    createPlanCategoria: input => dispatch(createPlanCategoria(input)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCategorias)