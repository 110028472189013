import * as types from '../constants/ActionTypes'

const chapterReviews = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_CHAPTER_REVIEWS:
      return action.payload
    case types.GET_CHAPTER_REVIEW:
      return action.payload
    case types.NEW_CHAPTER_REVIEW:
      return action.payload
    case types.DELETE_CHAPTER_REVIEW:
      return action.payload
    default:
      return state
  }
}

export default chapterReviews

