import * as types from '../constants/ActionTypes'

const suscripciones = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_SUBSCRIPTIONS:
      return action.payload
    case types.GET_SUBSCRIPTION:
      return action.payload
    case types.NEW_SUBSCRIPTION:
      return action.payload
    case types.UPDATE_SUBSCRIPTION:
      return action.payload
    case types.DELETE_SUBSCRIPTION:
      return action.payload;
    default:
      return state
  }
}

export default suscripciones

