import { useEffect, useState } from 'react'


function UserIndicesCompleted(props){

    const { id, indicesCompleted } = props
    const indices = indicesCompleted?.filter(indiceCompleted => indiceCompleted.resource && (indiceCompleted.parent === null || indiceCompleted.depth < 4) );
    const numberOf = indices.length ?? null

    return (
        <div>

            <div className="container py-5">
                <h3 className="text-xl font-semibold mb-3">
                    Capítulos completados <span>{numberOf ? `(${numberOf})` : ``}</span>
                </h3>

                {indices?.map(indiceCompleted => ( <>
                    <div className="mb-2 bg-gray-50 p-2 border rounded-sm flex overflow-hidden">

                        <div className="block relative overflow-hidden rounded-sm mr-3" style={{ width: '64px', height: '86px', backgroundColor: 'transparent' }}>
                            <div className="rounded-sm overflow-hidden">                            
                                <img src={`${process.env.REACT_APP_API}${indiceCompleted.resource.image}`} alt={`Portada ${indiceCompleted.resource.titulo}`} />
                            </div>
                        </div>

                        <div key={indiceCompleted.id} className="flex flex-wrap items-center ">
                            <div>
                                <h1 className="text-base w-full">{indiceCompleted.resource.titulo}</h1>
                                <h2 className="text-xl font-bold w-full">{indiceCompleted.title === 'Notas' ? 'Nota' : indiceCompleted.title}</h2>
                            </div>
                        </div>

                    </div>
                </>))}

                {indices.length < 1? 'Sin índices completados registrados...' : null}
            </div>

        </div>
    )
}

export default UserIndicesCompleted