import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getDestacadosByUser, getNotasByUser, getTagsByUser, getIndicesDestacadosByUser, getIndicesCompleted, getUser, getLogLoginsByUser } from '../actions'
import { Button, Alert, Space, Tabs } from 'antd'
import Modal from '../components/Modal'
import ReactQuill from 'react-quill';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import socketIOClient from "socket.io-client";

import UserDestacados from "./userDestacados";
import UserNotas from "./userNotas";
import UserTags from "./userTags";
import UserIndicesDestacados from "./userIndicesDestacados";
import UserIndicesCompleted from "./userIndicesCompleted";
import UserLogIns from "./userLogIns";


const { TabPane } = Tabs;


function User(props){

  const { users, getUser, getDestacadosByUser, destacados, getNotasByUser, notas, getTagsByUser, tags, getIndicesDestacadosByUser, indicesDestacados, getIndicesCompleted, indicesCompleted, getLogLoginsByUser, logLogIn } = props
  const { id } = props.match.params
  const userYou = JSON.parse(localStorage.getItem('user'))
  const history = useHistory();

  const svg_add = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"/><line x1="5" y1="12" x2="19" y2="12"/></svg>
  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>

  const [user, setUser] = useState(null)
  const [tab, setTab] = useState('1')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)

  const [dateOfUser, setDateOfUser] = useState('')
  const [dateOfPost, setDateOfPost] = useState('')
  const [dateOfSub, setDateOfSub] = useState('')
  const [showDelete, setShowDelete] = useState(0);

  const [newMessageIsOpen, setNewMessageIsOpen] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [topic, updateTopic] = useState("");
  const [message, updateMessage] = useState("");






  useEffect( async () => {
    getUser(id);
  }, [])


  function delete_user(state) {
    if (state) {
      history.push("/usuarios/delete/"+id);
    }
  }


  useEffect( async () => {
    setUser(users)
  }, [users])


  useEffect( async () => {
    if (user) {

      if (user.id) {
        getLogLoginsByUser(user.id)
      }

      if ( user.rol && user.rol === 'admin') {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }

      setIsEnabled(user.enabled)
    }
  }, [user])


  useEffect(() => {
    getDestacadosByUser(id)
    getNotasByUser(id)
    getTagsByUser(id)
    getIndicesDestacadosByUser(id)
    getIndicesCompleted(id)
  }, [])


  const handleChangeTopic = (e) => {
    updateTopic(e.currentTarget.value);
  };
  

  useEffect(() => {

    var dateTMP = new Date(user?.lastLogin);
    var hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
    var minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
    var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
    setDateOfUser(dateTMPToString)

    dateTMP = new Date(user?.createdAt);
    hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
    minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
    var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
    setDateOfPost(dateTMPToString)


    if (user && user?.suscriberUntilNew) {
      dateTMP = new Date(user?.suscriberUntilNew);
      hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
      minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
      var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
      setDateOfSub(dateTMPToString)
    }

  }, [user, users])


  useEffect(() => {
    var textIfTest = message.replaceAll('<p>', '').replaceAll('</p>', '').replaceAll(' ', '').replaceAll('<br>', '');
    var topicIfTest = topic.replaceAll(' ', '')
    if (textIfTest != '' && topicIfTest) {
      setButtonEnabled(true)
    } else {
      setButtonEnabled(false)
    }
  }, [message, topic]);


  const saveMessage = async () => {
    var formData = {
      responseId: null,
      topic: topic,
      body: message,
      isReaded: false,
      closed: false,
      userFromId: userYou.uid,
      userToId: user.id,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}createMensaje`,
      formData
    );


    const socket = socketIOClient(process.env.REACT_APP_SOCKET_ROUTE, {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd"
      },
    });

    socket.emit("userIdAdmins", user.uid);
    socket.on("new-messages", formData);

    history.push('/soporte/'+response.data.resource.id);
  };


  

  const changeRol = async (rol) => {

    if (rol === 0) {
      const response = await axios.post(
        `${process.env.REACT_APP_API}changeRol`,
        { rol: 'user', userId: user.id, adminId: userYou.uid }
      );    
      setIsAdmin(false)
    } else if (rol === 1) {
      const response = await axios.post(
        `${process.env.REACT_APP_API}changeRol`,
        { rol: 'admin', userId: user.id, adminId: userYou.uid }
      );
      setIsAdmin(true)
    }

  };


  const disableOrEnableUser = async (enabled) => {

    setIsEnabled(enabled);
    await axios.post(
      `${process.env.REACT_APP_API}disableOrEnableUser`,
      { enabled: enabled, userId: user.id, adminId: userYou.uid }
    );

  };


  return (
    <div className="z-10">
      <header className="py-8 bg-gray-100">

        <div className="flex items-start pl-8 pr-7">
          <div className='flex w-full'>
            <div className='bg-gray-200 w-40 h-40 min-w-max mr-4 overflow-hidden rounded'>
              <div className='w-full h-full overflow-hidden'>
                {user?.image?
                  <img className='object-cover w-40 h-40' id="profilePic" src={user?.image} />
                  :
                  <div className='font-bold w-40 h-40 flex items-center justify-center text-4xl select-none' style={{ backgroundColor: '#DBEAFE' }}>
                    {user?.name?.split(' ')[0]? user?.name.split(' ')[0].charAt(0) : null}
                    {user?.name?.split(' ')[1]? user?.name.split(' ')[1].charAt(0) : null}
                  </div>
                }
              </div>
            </div>
            <div className='w-full'>
              <h1 className="text-4xl mt-1 font-semibold w-full">{user?.name}</h1>
              <h1 className="text">{user?.email}</h1>
              <h1 className="text">Sistema en {user?.language === 'esp'? 'español' : 'Inglés'}</h1>
              <h1 className="text">{dateOfPost != ''? 'Usuario creada el '+dateOfPost : ''}</h1>
              <h1 className="text">{dateOfUser != ''? 'Último inicio el '+dateOfUser : ''}</h1>
              <h1 className="text">{dateOfSub != ''? 'Suscrito hasta el '+dateOfSub : ''}</h1>
            </div>
          </div>

          {/* <div className='w-10'>
            <div className="flex flex-wrap justify-end items-center">
              <Button className='mr-2 mb-2' onClick={() => setNewMessageIsOpen(true)}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_add}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Crear Consulta</div>
                </div>
              </Button>

              {!isAdmin? 
                <Button className='mr-2 mb-2' danger onClick={() => changeRol(1)}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_add}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Hacer Administrador</div>
                  </div>
                </Button>
                :
                <Button className='mr-2 mb-2' danger onClick={() => changeRol(0)}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_add}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Eliminar Como Administrador</div>
                  </div>
                </Button>
              }

              {user && !user.enabled? 
                <Button className='mr-2 mb-2' danger onClick={() => disableOrEnableUser(true)}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_add}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Habilitar Usuario</div>
                  </div>
                </Button>
                :
                <Button className='mr-2 mb-2' danger onClick={() => disableOrEnableUser(false)}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_add}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Deshabilitar Usuario</div>
                  </div>
                </Button>
              }

              <Button danger className='mr-2' onClick={() => { setShowDelete(1) }}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_trash}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Eliminar Usuario</div>
                </div>
              </Button>

            </div>
          </div> */}
        </div>

      </header>


      {showDelete? <div className="">
        <Alert
          message="¿Seguro que quieres eliminar este usuario de forma definitiva?"
          type="error"
          action={
            <Space direction="horizontal">
              <Button size="small" type="danger" onClick={() => { delete_user(1); }}>
                Eliminar
              </Button>
              <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                Cancelar
              </Button>
            </Space>
          }
        />
      </div> : ''}


      <div className='container p-2 mt-2'>
        <div className="flex justify-start items-center flex-wrap">
          <Button className='mr-2 mb-2' onClick={() => setNewMessageIsOpen(true)}>
            <div className='flex items-center'>
              <div className='flex items-center mr-2'>{svg_add}</div>
              <div className='flex' style={{marginTop: '1px'}}>Crear Consulta</div>
            </div>
          </Button>

          {!isAdmin? 
            <Button className='mr-2 mb-2' danger onClick={() => changeRol(1)}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_add}</div>
                <div className='flex' style={{marginTop: '1px'}}>Hacer Administrador</div>
              </div>
            </Button>
            :
            <Button className='mr-2 mb-2' danger onClick={() => changeRol(0)}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_add}</div>
                <div className='flex' style={{marginTop: '1px'}}>Eliminar Como Administrador</div>
              </div>
            </Button>
          }

          {!isEnabled? 
            <Button className='mr-2 mb-2' danger onClick={() => disableOrEnableUser(true)}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_add}</div>
                <div className='flex' style={{marginTop: '1px'}}>Habilitar Usuario</div>
              </div>
            </Button>
            :
            <Button className='mr-2 mb-2' danger onClick={() => disableOrEnableUser(false)}>
              <div className='flex items-center'>
                <div className='flex items-center mr-2'>{svg_add}</div>
                <div className='flex' style={{marginTop: '1px'}}>Deshabilitar Usuario</div>
              </div>
            </Button>
          }

          <Button danger className='mr-2 mb-2' onClick={() => { setShowDelete(1) }}>
            <div className='flex items-center'>
              <div className='flex items-center mr-2'>{svg_trash}</div>
              <div className='flex' style={{marginTop: '1px'}}>Eliminar Usuario</div>
            </div>
          </Button>

        </div>
      </div>
      
      <div className='container'>
        <Tabs defaultActiveKey="1" onChange={setTab}>
          <TabPane tab="Destacados" key="1">
            <UserDestacados id={id} destacados={destacados} />
          </TabPane>
          <TabPane tab="Notas" key="2">
            <UserNotas id={id} notas={notas} />
          </TabPane>
          <TabPane tab="Tags" key="3">
            <UserTags id={id} tags={tags} />
          </TabPane>
          <TabPane tab="Leer más tarde" key="4">
            <UserIndicesDestacados id={id} indicesDestacados={indicesDestacados} />
          </TabPane>
          <TabPane tab="Completados" key="5">
            <UserIndicesCompleted id={id} indicesCompleted={indicesCompleted} />
          </TabPane>
          <TabPane tab="LogIns" key="7">
            <UserLogIns id={id} logLogIn={logLogIn} />
          </TabPane>
        </Tabs>
      </div>

    


      <Modal
        toggle={newMessageIsOpen}
        close={() => setNewMessageIsOpen(false)}
        actions={[{ text: "Enviar", onClick: () => saveMessage() }]}
        value=""
        heading="Nueva Consulta"
        buttonEnabled={buttonEnabled}
        setButtonEnabled={setButtonEnabled}
      >
        <input
          autoFocus
          onChange={handleChangeTopic}
          placeholder="Asunto"
          resize="auto"
          className="p-2 w-full rounded-sm border focus:outline-none mb-2 mt-2"
        />

        <ReactQuill placeholder="Cuéntanos tus dudas..." theme="snow" className="h-32 mb-16" style={{ width: '60vw' }} onChange={updateMessage}/>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getUser: id => dispatch(getUser(id)),
    getDestacadosByUser: id => dispatch(getDestacadosByUser(id)),
    getNotasByUser: id => dispatch(getNotasByUser(id)),
    getTagsByUser: id => dispatch(getTagsByUser(id)),
    getIndicesDestacadosByUser: id => dispatch(getIndicesDestacadosByUser(id)),
    getIndicesCompleted: id => dispatch(getIndicesCompleted(id)),
    getLogLoginsByUser: userId => dispatch(getLogLoginsByUser(userId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User)