import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Input, DatePicker, Select, Button, Steps, InputNumber, Alert, Space  } from 'antd';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from 'moment'

import { getAllPlanCategorias, getAllBooks, getAllIndices, getPlanBySlug } from '../actions'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';
import { useCallback, useReducer } from "react";
import produce from "immer";



function PlanAdd(props) {

  const { Step } = Steps;
  const { TextArea } = Input;
  const { Option } = Select;

  const svg_anterior = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;
  const svg_siguiente = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;
  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>


  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({});
  const [loadingList, setLoadingList] = useState(0);
  const [showDelete, setShowDelete] = useState(0);
  const [cat, setCat] = useState('');
  const [language, setLanguage] = useState('esp');


  const { getAllPlanCategorias, planCategorias } = props
  const { getAllBooks, books } = props
  const { getAllIndices, indices } = props
  const { slug } = props.match.params;

  const { getPlanBySlug, plans } = props

  useEffect(() => {
    if (slug) {
      getPlanBySlug(slug)
    }
    getAllPlanCategorias()
    getAllBooks()
    getAllIndices()
  }, [])




  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };



  const submitResource = async () => {
    if (slug) {
      const formData = {
        titulo: data.title,
        subtitulo: data.subtitulo,
        descripcion: data.description,
        categoria: data.categoria,
        duracion: data.duracion,
        language: data.language,
        fecha: moment(data.fecha, 'DD/MM/YYYY').format('YYYY/MM/DD'),
  
        id: plans.id,
        slug: slug,
        indices: JSON.stringify(state['items-final']),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API}createPlans`,
        formData
      );
      if (response) {
        history.push('/planes');
      }
    } else {
      const formData = {
        titulo: data.title,
        subtitulo: data.subtitulo,
        descripcion: data.description,
        categoria: data.categoria,
        duracion: data.duracion,
        language: data.language,
        fecha: moment(data.fecha, 'DD/MM/YYYY').format('YYYY/MM/DD'),
  
        id: null,
        slug: null,
        indices: JSON.stringify(state['items-final']),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API}createPlans`,
        formData
      );

      if (response) {
        history.push('/planes');
      }
    }
  };


  const onSubmit = (fields, step) => {
    setCurrentStep(currentStep => currentStep + 1);
    setCatFunc(data.categoria)
    
    switch (step) {
      case 'step1':
        setData({ ...data, ...fields, slug: _.kebabCase(fields['title']) });
        break;
      case 'step2':
        break;
      default:
        return null;
    }
  };

  const validateMessages = {
    required: `Este campo es obligatorio...`,
    // ...
  };

  const handleFinish = (fileList, type = null) => {
    setCurrentStep(prev => prev + 1);

  };


  function setCatFunc(id) {
    planCategorias.map(element => {
      if (element.id === id) {
        setCat(element.name)
      }
    });
  }





  const styles = {
    dragger: `px-3 py-2 m-2 transition-colors duration-150 ease-in-out bg-white shadow hover:bg-gray-100`,
    dropper: `w-auto min-w-1/4 max-w-1/2 h-full`,
    draggerContent: `flex items-center space-x-3 text-base`,
    draggerIcon: `inline-flex items-center justify-center p-1.5 text-white bg-teal-100 text-teal-700`,
    dragging: `bg-gray-300`,
    dropOver: `bg-gray-100`,
  };



  const dragReducer = produce((draft, action) => {
    if (action.type === "MOVE") {
      draft[action.from] = draft[action.from] || [];
      draft[action.to] = draft[action.to] || [];
      const [removed] = draft[action.from].splice(action.fromIndex, 1);
      draft[action.to].splice(action.toIndex, 0, removed);
    } else if (action.type === "RELOAD") {
      return { ...draft, [action.name] : action.indices }
    }
  });

  const [state, dispatch] = useReducer(dragReducer, { });

  

  const onDragEnd = useCallback((result) => {
    if (result.reason === "DROP") {
      if (!result.destination) {
        return;
      }

      dispatch({
        type: "MOVE",
        from: result.source.droppableId,
        to: result.destination.droppableId,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
      });
    }
  }, []);



  useEffect(() => {
    reload()
  }, [plans, indices, books])


  
  function reset() {
    const indicesTMP = new Array();
    const lengthIndex = indices.length

    /* Poner la lista de Planes de lectura */
    dispatch({
      type: "RELOAD",
      name: 'items-final',
      indices: null,
    }); 

    /* Poner las listas de los libros */
    /* indicesTMP['items-final'] = new Array(); */
    books?.map((bookTMP, indexBook) => {
      indicesTMP['items-'+bookTMP.id] = new Array();
      indices?.map((indiceTMP, index) => {

        if (bookTMP.token === indiceTMP.libro) {
          indicesTMP['items-'+bookTMP.id][indiceTMP.id] = indiceTMP
        }

        if(index === lengthIndex -1) {
          dispatch({
            type: "RELOAD",
            name: 'items-'+bookTMP.id,
            indices: indicesTMP['items-'+bookTMP.id],
          }); 
          setLoadingList(1)
        }
      })
    })
  }


  function delete_plan(state) {
    if (state) {
      history.push("/planes/delete/"+plans.id);
    }
  }

  function reload() {
    const indicesTMP = new Array();
    const lengthIndex = indices.length
    const defaultIndicesTMP = new Array();
    var counter = 0;

    /* Poner la lista de Planes de lectura */
    if (slug && plans.relationTableOfContents && indices && books) {
      const lengthIndexContents = plans.relationTableOfContents.length

      plans.relationTableOfContents?.map((element, indexContents) => {
        counter = counter + 1;
        indices?.map((indiceTMP, index) => {
          if (indiceTMP.id == parseInt(element.contentId)) {
            defaultIndicesTMP[counter] = indiceTMP;
          }
          if(indexContents === lengthIndexContents -1 && index === lengthIndex -1) {
            dispatch({
              type: "RELOAD",
              name: 'items-final',
              indices: defaultIndicesTMP,
            }); 
          }
        })
      });
    } else {
      dispatch({
        type: "RELOAD",
        name: 'items-final',
        indices: null,
      }); 
    }

    /* Poner las listas de los libros */
    /* indicesTMP['items-final'] = new Array(); */
    books?.map((bookTMP, indexBook) => {
      indicesTMP['items-'+bookTMP.id] = new Array();
      indices?.map((indiceTMP, index) => {

        if (bookTMP.token === indiceTMP.libro) {
          var add = true;
          plans.relationTableOfContents?.map((avoidElement, indexAvoidElement) => {
            if (indiceTMP.id == parseInt(avoidElement.contentId)) {
              add = false;
            }
          })
          if (add) {
            indicesTMP['items-'+bookTMP.id][indiceTMP.id] = indiceTMP
          }
        }

        if(index === lengthIndex -1) {
          dispatch({
            type: "RELOAD",
            name: 'items-'+bookTMP.id,
            indices: indicesTMP['items-'+bookTMP.id],
          }); 
          setLoadingList(1)
        }
      })
    })
  }





  function showOrHide(bookid) {
    var allItem = document.getElementById('list-'+bookid);
    var button = document.getElementById('button-'+bookid);

    if (allItem && allItem.classList.contains('hidden')) {
      allItem.classList.remove("hidden");
      button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>';
    } else {
      allItem.classList.add("hidden");
      button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>';
    }
  };



  if ( !slug || (slug && plans.titulo) ) { 
    const steps = [
      {
        name: 'Datos básicos',
        content: (
          <Form
            initialValues={{
              title: slug && plans.titulo? plans.titulo : '', 
              subtitulo: slug && plans.subtitulo? plans.subtitulo : '',
              language: slug && plans.language? plans.language : '',
              duracion: slug && plans.duracion? plans.duracion : '',
              fecha: slug && plans.fecha? moment(new Date(parseInt(plans.fecha))) : '',
              categoria: slug && plans.categoriaId? parseInt(plans.categoriaId)+"" : '',
              description: slug && plans.descripcion? plans.descripcion : '',
            }}

            onFinish={fields => onSubmit(fields, 'step1')}
            validateMessages={validateMessages}
          >

            {slug && showDelete? <div className="mb-4">
              <Alert
                message="¿Seguro que quieres eliminar este plan de lectura?"
                type="error"
                action={
                  <Space direction="horizontal">
                    <Button size="small" type="danger" onClick={() => { delete_plan(1); }}>
                      Eliminar
                    </Button>
                    <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                      Cancelar
                    </Button>
                  </Space>
                }
              />
            </div> : ''}

            <div className='flex w-full'>
              <div className='mr-1 w-full'>
                <label>Título:</label>
                <Form.Item name="title" rules={[{ required: true }]}>
                  <Input placeholder="Título"/>
                </Form.Item>
              </div>
              <div className='ml-1 w-full'>
                <label>Subtítulo:</label>
                <Form.Item name="subtitulo" rules={[{ required: true }]}>
                  <Input placeholder="Subtítulo"/>
                </Form.Item>
              </div>
              <div className='ml-1 w-full'>
                <label>Idioma:</label>
                <Form.Item name="language" rules={[{ required: true }]}>
                  <Select name="categoria" placeholder="Selecciona un idioma" className='w-full' onChange={setLanguage}>
                    <Option value='esp'>Español</Option>
                    <Option value='eng'>Inglés</Option>
                  </Select>                
                </Form.Item>
              </div>
            </div>

            <div className='flex w-full'>
              <div className='mr-1 w-full'>
                <label>Duración:</label>
                <div className="flex">
                  <Form.Item name="duracion" rules={[{ required: true }]} className="w-full">
                    <InputNumber placeholder="Duración" className="w-full" min={'0'}/>
                  </Form.Item>
                  <div className="bg-gray-100 w-10 border-t border-r border-b rounded-sm border-gray-300 flex items-center justify-center" style={{height: '32px'}}><p>.pp</p></div>
                </div>
              </div>

              <div className='mx-1 w-full'>
                <label>Fecha:</label>
                <Form.Item name="fecha" rules={[{ required: true }]}>
                  <DatePicker placeholder="Fecha" className="w-full"/>
                </Form.Item>
              </div>

              <div className='ml-1 w-full'>
                <label>Categoría:</label>
                <Form.Item name="categoria" rules={[{ required: true }]}>
                  <Select name="categoria" placeholder="Selecciona una categoria" className='w-full' onChange={setCatFunc}>
                    {planCategorias?.map(element => {
                      return <Option key={element.id} value={element.id}>{element.name}</Option>
                    })}

                  </Select>
                </Form.Item>
              </div>
            </div>

            

            <label>Descripción:</label>
            <Form.Item  name="description" rules={[{ required: true }]}>
              <TextArea placeholder="Descripción" autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>

            <br></br>

            <div className='flex justify-between'>
              <Form.Item>
                <Button htmlType="button" size="large" type="primary" onClick={backStep} disabled>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_anterior}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                  </div>
                </Button>
              </Form.Item>

              <div className="flex">
                {slug? <Form.Item>
                  <Button htmlType="button" size="large" danger className='mr-2' onClick={() => { setShowDelete(1) }}>
                    <div className='flex items-center'>
                      <div className='flex items-center mr-2'>{svg_trash}</div>
                      <div className='flex' style={{marginTop: '1px'}}>Eliminar Plan</div>
                    </div>
                  </Button>
                </Form.Item> : ''}

                <Form.Item>
                  <Button htmlType="submit" size="large" type="primary">
                    <div className='flex items-center'>
                      <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                      <div className='flex items-center ml-2'>{svg_siguiente}</div>
                    </div>
                  </Button>
                </Form.Item>
              </div>
            </div>

          </Form>

        ),
      },
      {
        name: 'Indices',
        content: (
          <Form
            onFinish={fields => onSubmit(fields, 'step2')}
            validateMessages={validateMessages}
          >

            <div className={`flex w-full`}>

              <DragDropContext onDragEnd={onDragEnd}>
                {loadingList?
                  <div className='bg-gray-100 w-full ml-1 mb-2 pb-14'>
                    <div className='w-full flex justify-between align-middle'>
                      <h1 className='text-lg font-bold px-4 py-2'>Plan de lectura:</h1>
                    </div>
                    <Droppable droppableId="items-final" type="PERSON">
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={ styles.dropper+` ${
                              snapshot.isDraggingOver && styles.dropOver
                            }`}
                          >
                            {state['items-final']?.map((indiceTMP, index) => {
                              return (
                                <Draggable
                                  key={indiceTMP.id}
                                  draggableId={indiceTMP.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        className={ styles.dragger+` ${
                                          snapshot.isDragging && styles.dragging
                                        }`}
      
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className={styles.draggerContent + indiceTMP.depth > 3? 'text-xs' : 'text-base' }>
                                          {indiceTMP.depth <= 3 || indiceTMP.parentId === null? '' : ' - '} 
                                          <span className={indiceTMP.depth === 5 && indiceTMP.parentId != null? 'ml-10' : indiceTMP.depth === 4 && indiceTMP.parentId != null? 'ml-5' : '' }>
                                            
                                            {indiceTMP.title.replaceAll('*', '')}
                                          </span>
                                        </div>
                                        
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>  
                : 'loading...'}


                
                <div className='w-full'>
                  {loadingList? books?.map((bookTMP, indexBook) => {

                    return (
                      <div key={bookTMP.id} className='bg-gray-100 w-full ml-1 mb-2  select-none'>
                        <div className='w-full flex justify-between align-middle'>
                          <h1 className='text-lg font-bold px-4 py-2'>{bookTMP.titulo}:</h1>
                          { state['items-'+bookTMP.id]?.length > 0? 
                          <div id={'button-'+bookTMP.id} className='px-4 py-3 flex items-top font-normal cursor-pointer' onClick={() => showOrHide(bookTMP.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>
                          </div> 
                          : null }
                        </div>
                        
                        { state['items-'+bookTMP.id]?.length > 0? <div id={'list-'+bookTMP.id} className="hidden">
                          <Droppable droppableId={ 'items-'+bookTMP.id } type="PERSON" >
                            
                            {(provided, snapshot) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className={ styles.dropper+` ${
                                    snapshot.isDraggingOver && styles.dropOver
                                  }`}
                                >
                                  {state['items-'+bookTMP.id]?.map((indiceTMP, index) => {
                                    if (/* bookTMP.token === indiceTMP.libro */ true) {
                                      return (

                                        <Draggable
                                          key={indiceTMP.id}
                                          draggableId={indiceTMP.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <div
                                                className={ styles.dragger+` ${
                                                  snapshot.isDragging && styles.dragging
                                                }`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <div className={styles.draggerContent + indiceTMP.depth > 3? 'text-xs' : 'text-base' }>
                                                  {indiceTMP.depth <= 3 || indiceTMP.parentId === null? '' : ' - '} 
                                                  <span className={indiceTMP.depth === 5 && indiceTMP.parentId != null? 'ml-10' : indiceTMP.depth === 4 && indiceTMP.parentId != null? 'ml-5' : '' }>
                                                    
                                                    {indiceTMP.title.replaceAll('*', '')}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          }}
                                        </Draggable>

                                      );
                                    }
                                  })}

                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div> : null }

                      </div>
                    )
                  }): 'loading...'}
                </div>

              </DragDropContext>
            </div>


            <br></br>

            <div className='flex justify-between'>
              <Form.Item>
                <Button htmlType="button" size="large" type="primary" onClick={backStep}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_anterior}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                  </div>
                </Button>
              </Form.Item>

              <div className='flex'>
                <Form.Item>
                  <Button htmlType="button" size="large" danger className='mr-2' onClick={reset}>
                    Devolver capítulos a sus libros
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="button" size="large" className='mr-2' onClick={reload}>
                    Poner listas defecto
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" size="large" type="primary">
                    <div className='flex items-center'>
                      <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                      <div className='flex items-center ml-2'>{svg_siguiente}</div>
                    </div>
                  </Button>
                </Form.Item>
              </div>
            </div>

          </Form>
        ),
      },
      {
        name: 'Resumen',
        content: data && data.fecha ? (
          <div>

            <div className=''>
              <h2 className='text-5xl font-bold'>{data.title}</h2>
              <h2 className='text-2xl'>{data.subtitulo}</h2>
              <h2 className='text-base'>Plan de lectura dentro de <strong>{cat}</strong> creado el <strong>{moment(data.fecha, 'DD/MM/YYYY').format('DD/MM/YYYY')}</strong></h2>
              <h2 className='text-base'>{data.description}</h2>
            </div>


            <div className='my-8'>
              <h1 className='font-bold text-lg'>Capítulos a guardar:</h1>
              {state['items-final']?.map((element, key) => {
                return (
                  <h1 className='border p-2 mb-2' key={element.id}>{element.title.replaceAll('*', '')}</h1>
                );
              })}
              {!state['items-final']? 'Sin capítulos introducidos...':''}
            </div>





            <div className='flex justify-between'>
              <Form.Item>
                <Button htmlType="button" size="large" type="primary" onClick={backStep} >
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_anterior}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                  </div>
                </Button>
              </Form.Item>

              <Form.Item>
                <Button htmlType="button" size="large" type="primary" onClick={submitResource} disabled={state['items-final']? '' : false}>
                  <div className='flex items-center'>
                    <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                    <div className='flex items-center ml-2'>{svg_save}</div>
                  </div>
                </Button>
              </Form.Item>
            </div>
          </div>

        ) : (
          'no data'
        ),
      },
    ];

    return (
      <div className="">
        <div className="py-2 container">
          <Steps
            type="navigation"
            current={currentStep}
            // onChange={index => setCurrentStep(index)}
            className="site-navigation-steps mb-8"
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                status={currentStep === index ? `process` : `wait`}
                title={step.name}
              />
            ))}

          </Steps>
          {steps.map((step, index) => (
            <div className={currentStep !== index? 'hidden' : ''} key={index}>
              {step.content}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
}


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllPlanCategorias: () => dispatch(getAllPlanCategorias()),
    getAllBooks: () => dispatch(getAllBooks()),
    getAllIndices: () => dispatch(getAllIndices()),
    getPlanBySlug: slug => dispatch(getPlanBySlug(slug))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanAdd)