import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import rootReducer from '../src/reducers'
import firebaseConfig from './config/firebaseConfig'
import App from './App'
import 'typeface-source-sans-pro'
import 'antd/dist/antd.css'
import './index.css'




const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(
      thunk.withExtraArgument({getFirebase}),
      logger
    ),
    reactReduxFirebase(firebaseConfig, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true })
  )
)

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
})