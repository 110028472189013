import { useEffect } from 'react'
import { connect } from 'react-redux'
import { deletePlan } from '../actions'
import { useHistory } from "react-router-dom";

function PlanDelete(props){
  const { deletePlan } = props
  const { id } = props.match.params
  let history = useHistory();

  useEffect(() => {
    if (id) { 
      deletePlan(id);
    }
  }, [id])

  useEffect(() => {
    if (props.plans.status) {
      localStorage.setItem('deletedPlan', true);
      setTimeout(function(){ history.push('/planes'); }, 300);
    }
  }, [props])

  return "Eliminando..."
}


const mapStateToProps = state => state
const mapDispatchToProps = dispatch => {
  return {
    deletePlan: input => dispatch(deletePlan(input))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanDelete)