import { useEffect, useState } from 'react'

import socketIOClient from "socket.io-client";
import CryptoJS from "crypto-js";

import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllConsultas } from '../actions'
import { Modal, Input, Form, Alert } from 'antd'
import { useHistory } from 'react-router-dom';


const ENDPOINT = process.env.REACT_APP_SOCKET_ROUTE;


function Soporte(props) {

  const { getAllConsultas, mensajes } = props

  const [filter, setFilter] = useState(0)
  const [repeat, setRepeat] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const user = JSON.parse(localStorage.getItem('user'))


  /* MESSAGES */
  const socket = socketIOClient(ENDPOINT, {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd"
    },
  });

  useEffect( () => {
    if (repeat && user) {
      setRepeat(false)
      socket.emit("userIdAdmins", user.uid);
      socket.on("new-messages", (msg) => {
        getAllConsultas()
      });
    }
  }, [user]);




  useEffect(() => {
    getAllConsultas()
  }, [])



  function close() {
    localStorage.removeItem('deletedPlanCategoria')
  }





  return (
    <>
      <Filters options={['Todos', 'Abiertas', 'Cerradas']} setFilter={setFilter}/>
      <div className="container">

        {localStorage.getItem('deletedPlanCategoria')? <div className="my-4">
          <Alert
            showIcon 
            message="Plan de lectura eliminado correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        <DocumentList 
          data={mensajes}
          filter={filter}
          fields={[
            {
              label: 'Creada por',
              name: 'userFrom'
            },
            {
              label: 'Hacia',
              name: 'userTo'
            },
            {
              label: 'Razón',
              name: 'topic'
            },
            {
              label: 'Nuevos mensajes',
              name: 'responses'
            },
            {
              label: 'Estado',
              name: 'closed'
            },
            {
              label: 'Creada el',
              name: 'createdAt'
            },
          ]} />
      </div>




    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllConsultas: () => dispatch(getAllConsultas()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Soporte)