import * as types from '../constants/ActionTypes'

const mensajes = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_MENSAJES:
      return action.payload
    case types.GET_MENSAJE:
      return action.payload.message
    case types.NEW_MENSAJE:
      return action.payload.message
    case types.MARK_MENSAJE:
      return action.payload
    default:
      return state
  }
}

export default mensajes