import * as types from '../constants/ActionTypes';

export const indices = (state = [], action) => {
  switch (action.type) {
    case types.GET_ALL_INDICES:
      return action.payload;
    default:
      return state;
  }
};
