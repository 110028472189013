import * as types from '../constants/ActionTypes'
import _ from 'lodash'

const indicesCompleted = (state = [], action) => {
  switch(action.type) {
    case types.GET_INDICES_COMPLETEDS_BY_USER:
      return action.payload
    default:
      return state
  }
}

export default indicesCompleted