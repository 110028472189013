import React, { useState, useEffect } from 'react';
import { Animated } from 'react-animated-css';
import {
  Form,
  Upload,
  Button,
  Row,
  Col,
  List,
  Radio,
  Alert,
  message,
} from 'antd';
import { UploadOutlined, InboxOutlined, CheckCircleOutlined, ExclamationCircleOutlined,} from '@ant-design/icons';
import axios from 'axios';
import { isInteger } from 'lodash';

const { Dragger } = Upload;

function StepAudio(props) {

  const {
    onSubmit,
    audiosOcc,
    handleFinish,
    backStep,
    referencesFile,
    setReferencesFile,
    uploadedAudioInLine,
    setUploadedAudioInLine,
    slug, 
    isBook,

    references,
    setReferences,
    audioList,
    setAudioList,
    dataAudios,

    totalWithoutUpload,
    setTotalWithoutUpload,
    totalUploaded,
    setTotalUploaded,
    totalToUploaded,
    setTotalToUploaded,
  } = props;

  const svg_anterior = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;
  const svg_siguiente = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>
  const svg_upload = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="17 8 12 3 7 8"/><line x1="12" y1="3" x2="12" y2="15"/></svg>

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [showGenerated, setShowGenerated] = useState(false);




  useEffect(async () => {

    setTotalWithoutUpload(0)
    setTotalUploaded(0)
    setTotalToUploaded(0)
    var totalWithoutUploadTMP = 0
    var totalUploadedTMP = 0
    var totalToUploadeTMP = 0


    audioList.map(element => {
      element.hidde ? (
        totalWithoutUploadTMP++
      ) : (
        isAlreadyUploaded(element.fileName.toLowerCase())? totalUploadedTMP++ : totalToUploadeTMP++
      )
    });

    setTotalToUploaded(totalWithoutUploadTMP)
    setTotalUploaded(totalUploadedTMP)
    setTotalWithoutUpload(totalToUploadeTMP)

  }, [audioList, uploadedAudioInLine, fileList]);


  function remove_duplicates_safe(arr) {
    var seen = {};
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
      if (!ret_arr.find(x => x.fileName.toLowerCase() === arr[i].fileName.toLowerCase())) {
        ret_arr.push(arr[i]);
        seen[arr[i]] = true;
      }
    }
    return ret_arr;
  }


  useEffect(() => {
    setAudioList(remove_duplicates_safe(audiosOcc));
  }, [audiosOcc]);





  const propsDragnDropAudios = {
    multiple: true,
    listType: 'picture',
    className: 'ant-resize-drag',
    fileList: fileList,
    beforeUpload: (file, list) => {
      if (audioList.filter(e => e.fileName.toLowerCase() === file.name.toLowerCase()).length > 0) {
        setFileList(prev => [...prev, file]);

        const copy = audioList;
        const i = audioList.findIndex(obj => file.name.toLowerCase() === obj.fileName.toLowerCase());
        copy[i].hidde = true;
        setAudioList(copy);
      }

      return false;
    },
    onRemove: (file, list) => {
      setFileList(fileList.filter(f => f.name != file.name));
      const copy = audioList;
      const i = audioList.findIndex(obj => file.name.toLowerCase() === obj.fileName.toLowerCase());
      copy[i].hidde = false;
      setAudioList(copy);
    },
  };




  const propsUpload = {
    maxCount: 1,
    multiple: false,
    className: 'my-2',
    beforeUpload: file => {
      if (file.name.indexOf('.md') > -1) {
        setReferencesFile(file);
      } else {
        setReferencesFile(null);
      }
      return false;
    },
  };


  const handleGenerateReferences = async () => {
    const formData = new FormData();
    formData.append('md', referencesFile);

    const response = await axios.post(
      `${process.env.REACT_APP_API}generateAudioReferences`,
      formData
    );

    var referencesTMO = response.data.references.filter(el => el);
    var newRef = []
    await audioList.forEach( async (item, index) => {
      await referencesTMO.forEach( async (itemRef, indexRef) => {

        if ((indexRef+1).toString() === item.id.toString()) {
          var element = document.getElementsByName(item.fileName.toLowerCase());

          if (element != null) { 
            newRef.push({ id: item.fileName.toLowerCase(), text: itemRef.text })

            element.forEach(input => {
              input.value = itemRef.text
            });
          }
        }

      });

    });

    setReferences(newRef);
    setAudioList(audioList);
    setShowGenerated(true)
  };




  

  function isAlreadyUploaded(itemFileName) {
    var savedTMP = false;

    uploadedAudioInLine?.map(alreadyUploaded => {
      if (itemFileName.toLowerCase()  === alreadyUploaded.toLowerCase()  ) {
        savedTMP = true;
      }
    })

    return savedTMP;
  }


  async function remove_file(fileNameTMP) {
    if (slug && window.confirm("¿Seguro que quieres eliminar el fichero de este audio? Los cambios serán instantáneos no como los del resto de la edición.")) {
      const fileNameForm = new FormData();
      fileNameForm.append('filename', slug+'/MEDIA/'+fileNameTMP.toLowerCase() );
      const responseTMP = await axios.post(
        `${process.env.REACT_APP_API}removefile`,
        { filename: slug+'/MEDIA/'+fileNameTMP.toLowerCase() , isBook: isBook }
      );

      var uploadedAudioTMP = [];
      var removedAudio = null;
      await uploadedAudioInLine.forEach(element => {
        if (element.toLowerCase()  != fileNameTMP.toLowerCase() ) {
          uploadedAudioTMP.push(element);
        } else {
          removedAudio = element;
        }
      });
      setUploadedAudioInLine(uploadedAudioTMP)

      var newAudios = [];
      await audioList.forEach(element => {
        newAudios.push(element);
      });
      setAudioList(newAudios);


      /* var element = document.getElementsByName('text-'+removedAudio.id)
      element
      if (removedAudio && element != null) { 
        element.forEach(input => {
          input.value = removedAudio.text 
        });
      } */
    }
  }



  async function funcSetRef(e, item) {  // REDO
    var tmpRef = references; 
    var newValue = e.target.value
    var inputId = e.target.id

    var doesentExists = true;
    await tmpRef.map(element => {
      if (element.id.toString() === inputId) {
        element.text = newValue;
        doesentExists = false;
      }
    });

    if (doesentExists) {
      tmpRef.push({ id: inputId, text: newValue })
    }

  }



  useEffect(async () => {
    await audioList.map(item => {
      dataAudios.map(itemData => {

        if (item.fileName.toLowerCase() === itemData.fileName.toLowerCase()) {
          var element = document.getElementById(itemData.fileName.toLowerCase())
          if (element != null) { 
            element.value = itemData.text 
          }
        }
      });

    });
  }, [dataAudios, audioList])


  const formAutomaticallyUpload = (

    !audiosOcc.length || audiosOcc.length === 0?
      <>
        <span>
          No se han detectado audios en tus archivos, en el caso que hayan, revisa
          la sintaxis del markdown.
        </span>
  
      </>
    :
      <Row className='flex flex-nowrap'>
        <Col className='w-full max-h-screen overflow-y-auto overflow-x-visible border-t border-b'>
          <List
            header={<div>Audios encontrados</div>}
            bordered
            dataSource={audioList}
            renderItem={item => (
              <List.Item className='flex flex-wrap'>
                <div className='flex items-center justify-between w-full'>
                  <div className='flex items-center'>
                    { 
                      item.hidde ? (
                        <CheckCircleOutlined className="text-green-400" />
                      ) : (
                        isAlreadyUploaded(item.fileName.toLowerCase())? <CheckCircleOutlined className="text-blue-400" /> : <ExclamationCircleOutlined className="text-red-600" />
                      )
                    } 

                    {'\u00A0'}
                    <p className='overflow-ellipsis'>{item.fileName}</p>
                  </div>

                  <div className='flex items-center'>
                    <Upload {...propsDragnDropAudios} showUploadList={false} className='h-5'>
                      <Button htmlType="button" className='ml-2 px-2 h-6' onClick={() => {  }}>
                        <div className='flex items-center justify-center'>{svg_upload}</div>
                      </Button>
                    </Upload>

                    { isAlreadyUploaded(item.fileName.toLowerCase()) && !item.hidde? (
                      <Button htmlType="button" danger className='ml-2 px-2 h-6' onClick={() => { remove_file(item.fileName.toLowerCase()) }}>
                        <div className='flex items-center justify-center'>{svg_trash}</div>
                      </Button>
                    ) : (
                      null
                    )}
                  </div>
                </div>

                <div className='mt-2 w-full'>
                  { item.fileName? <input type='text' id={item.fileName.toLowerCase()} name={item.fileName.toLowerCase()} placeholder='Texto' onChange={e => funcSetRef(e, item)} className='border px-2 py-1 w-full' /> : '' }
                </div>

              </List.Item>
            )}
          />
        </Col>

        <Col className='w-full px-2 max-h-screen overflow-y-auto overflow-x-visible '>
          {loading && <div>Cargando</div>}
          <div>
            <Dragger {...propsDragnDropAudios}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Clica o arrastra tus imágenes para subirlas.
              </p>
            </Dragger>
          </div>
        </Col>
      </Row>

  );

  /*
    TODO:
     - Comprobar que al subir los archivos se vayan substituyendo dentro del cuadro lateral izquierdo.
     - Al eliminar un archivo volver a añadirlo al cuadro lateral.
     - React transform/trasition en los elementos de la list que desaparecen.
  */






  return (
    <>

      <Form onFinish={onSubmit}>

        <div className="">
          <div>

            {showGenerated?
              <Alert message="Referencias aplicadas" type="success" showIcon/>
              :
              null
            }

            <div className="my-4">
              <Form.Item>
                <Upload {...propsUpload} >
                  <Button icon={<UploadOutlined />}>
                    Subir archivo de referencias
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={handleGenerateReferences}
                  size="medium"
                  type="primary"
                  disabled={referencesFile ? false : true}
                >
                  Aplicar referencias en archivo
                </Button>
              </Form.Item>

              <div className='flex mb-2 align-middle'>
                <div className='mr-4 flex align-middle'>
                  <ExclamationCircleOutlined className="text-red-600 mr-1 mt-1" />
                  <p>{totalWithoutUpload} pendientes</p>
                </div>
                <div className='mr-4 flex align-middle'>
                  <CheckCircleOutlined className="text-blue-400 mr-1 mt-1" />
                  <p>{totalUploaded} guardadas</p>
                </div>
                <div className='mr-4 flex align-middle'>
                  <CheckCircleOutlined className="text-green-400 mr-1 mt-1" />
                  <p>{totalToUploaded} para subir</p>
                </div>
              </div>

              {formAutomaticallyUpload}
            </div>


          </div>
        </div>
      </Form>

      <br></br>
      <div className='flex justify-between' style={{margin: '0px'}}>
        <Form.Item>
          <Button htmlType="button" size="large" type="primary" onClick={backStep}>
            <div className='flex items-center'>
              <div className='flex items-center mr-2'>{svg_anterior}</div>
              <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
            </div>
          </Button>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" size="large" type="primary" onClick={() => handleFinish(fileList, 'audio')}>
            <div className='flex items-center'>
              <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
              <div className='flex items-center ml-2'>{svg_siguiente}</div>
            </div>
          </Button>
        </Form.Item>
      </div>

    </>
  );
}

export default StepAudio;
