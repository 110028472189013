import * as types from '../constants/ActionTypes'

const initState = {
  authError: null
}

const authentication = (state = initState, action) => {
  switch(action.type) {
    case types.LOGIN_ERROR:
      const { message } = action.error
      return {
        ...state,
        authError: `Login error: ${message}`
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authError: null
      }
    case types.LOGOUT_SUCCESS:
      return state
    default:
      return state
  }
}

export default authentication