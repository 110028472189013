import { useState, useEffect } from 'react'

import socketIOClient from "socket.io-client";
import CryptoJS from "crypto-js";

import { connect } from 'react-redux'
import { getConsulta, createMensaje, closeMensaje, markAsReaded } from '../actions'
import { Modal, Button, Form, Input, Alert, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';

import ReactQuill from 'react-quill';
import axios from 'axios';


const ENDPOINT = process.env.REACT_APP_SOCKET_ROUTE;


function SoporteMsgs(props){

  const { getConsulta, mensajes:soporteTMP, markAsReaded } = props
  const { id } = props.match.params
  const user = JSON.parse(localStorage.getItem('user'))

  const [repeat, setRepeat] = useState(true);

  const history = useHistory();

  const [soporte, setSoporte] = useState(null)
  const [responses, setResponses] = useState([])
  const [newResponse, setNewResponse] = useState(null)

  const [dateOfUser, setDateOfUser] = useState('')
  const [dateOfPost, setDateOfPost] = useState('')
  const [showDelete, setShowDelete] = useState(0);
  const [textTest, setTextTest] = useState('');
  const [closed, setClosed] = useState(false)
  const [otherUser, setOtherUser] = useState(null)


  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>
  const svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square"><polyline points="9 11 12 14 22 4"/><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"/></svg>




  useEffect(() => {
    getConsulta(id)
    var toRemove = document.getElementsByClassName('msgToRemove');
    for (var i=0; i<toRemove.length; i++) { toRemove[i].remove(); }
  }, [])



  useEffect(() => {
    markAsReaded(parseInt(id))
    if (responses || newResponse) {
      var remove = document.getElementById("remove")
      if (remove) { remove.remove(); }
    }
  }, [responses, newResponse])


  /* MESSAGES */
  const socket = socketIOClient(ENDPOINT, {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd"
    },
  });


  useEffect( () => {
    if (repeat && user) {
      setRepeat(false)
      socket.emit("userIdAdmins", user.uid);
      socket.on("new-messages", (msg) => {
        setNewResponse(msg)
      });
    }
  }, [user]);

  function setYourNewMessagesFunc(msg) {
    if (socket) {
      socket.emit("new-messages", msg);
    }
  }

  useEffect( () => {

    if (newResponse) {
      if (toString(soporte.id) === toString(newResponse.responseId)) {
        var today = new Date();
        var conversation = document.getElementById("conversation")
        var msgTMP = document.createElement( 'html' )
        msgTMP.innerHTML =  '<div class="px-4 mt-2 w-full flex flex-wrap justify-start text-left bg-white">'+
                              '<div class="w-full text-gray-400">'+
                                '<small class="mr-2"> User </small>'+
                                '<small> ' + today.getHours() + ":" + today.getMinutes() + "h " + today.toLocaleDateString() +' </small>'+
                              '</div>'+
                              '<div class="bg-gray-300 rounded-b-lg px-4 py-2 rounded-tr-lg">'+newResponse.body+'</div>'+
                            '</div>';
        conversation.appendChild(msgTMP)
        if (conversation) { conversation.scrollTop = conversation.scrollHeight; }
      }
    }
  }, [newResponse])




  useEffect(() => {
    if (soporteTMP && soporteTMP.id) {
      setSoporte(soporteTMP)
    }
  }, [soporteTMP])


  useEffect(() => {
    var objDiv = document.getElementById('conversation')
    if (objDiv) { objDiv.scrollTop = objDiv.scrollHeight; }
    if (soporte && (soporte.userFrom && soporte.userFrom.id != user.uid) || (soporte && soporte.userToId === 'admins')) {
      setOtherUser(soporte.userFrom)
    } else if (soporte && soporte.userTo) {
      setOtherUser(soporte.userTo)
    }

    var dateTMP = new Date(otherUser?.lastLogin);
    var hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
    var minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
    var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
    setDateOfUser(dateTMPToString)

    dateTMP = new Date(soporte?.createdAt);
    hourTMP = dateTMP.getUTCHours() < 10? '0'+dateTMP.getUTCHours() : dateTMP.getUTCHours();
    minsTMP = dateTMP.getUTCMinutes() < 10? '0'+dateTMP.getUTCMinutes() : dateTMP.getUTCMinutes();
    var dateTMPToString = dateTMP.toLocaleDateString()+' a las '+hourTMP+':'+minsTMP+'h'
    setDateOfPost(dateTMPToString)

    setResponses(soporte?.responses)
    
  }, [soporte, otherUser, user, soporteTMP])


  function delete_cat(state) {
    if (state) {
      history.push("/plancategorias/delete/"+soporte.id);
    }
  }


  function maybeSend(event, textTest) {
    var textIfTest = textTest.replaceAll('<p>', '').replaceAll('</p>', '').replaceAll(' ', '').replaceAll('<br>', '');

    if (event.ctrlKey && event.key === 'Enter' && textIfTest && textIfTest != '') {
      var toRemove = document.getElementsByClassName('delete');
      for (var i=0; i<toRemove.length; i++) {
        toRemove[i].remove();
      }
      sendMsg(textTest)
      setTextTest('')
    }
  }


  function send(textTest) {
    var textIfTest = textTest.replaceAll('<p>', '').replaceAll('</p>', '').replaceAll(' ', '').replaceAll('<br>', '');

    if ( textIfTest && textIfTest != '' ) {
      var toRemove = document.getElementsByClassName('delete');
      for (var i=0; i<toRemove.length; i++) {
        toRemove[i].remove();
      }
      sendMsg(textTest)
      setTextTest('')
    }
  }


  async function sendMsg(textTest) {
    var textarea = document.getElementById('desc-'+id);


    if (textTest && textTest != '') {
      var today = new Date();
      var conversation = document.getElementById("conversation")
      var msgTMP = document.createElement( 'html' )
      msgTMP.innerHTML =  '<div class="msgToRemove px-4 mt-2 w-full flex flex-wrap justify-end text-right bg-white">'+
                            '<div class="w-full text-gray-400">'+
                              '<small class="mr-2"> Tú </small>'+
                              '<small> ' + today.getHours() + ":" + today.getMinutes() + "h " + today.toLocaleDateString() +' </small>'+
                            '</div>'+
                            '<div class="bg-gray-200 rounded-b-lg px-4 py-2 rounded-tl-lg">'+textTest+'</div>'+
                          '</div>';
      conversation.appendChild(msgTMP)
      if (conversation) { conversation.scrollTop = conversation.scrollHeight; }
      
      var remove = document.getElementById("remove")
      if (remove) { remove.remove(); }
      

      var formData = {
        responseId: parseInt(id),
        topic: null,
        body: textTest,
        isReaded: false,
        closed: false,
        userFromId: user.uid,
        userToId: otherUser.id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API}createMensaje`,
        formData
      );
      setYourNewMessagesFunc(response.data.resource)
      textarea.scrollIntoView();
    }
  }


  async function cerrarConsulta() {
    setClosed(true)
    const response = await axios.post(
      `${process.env.REACT_APP_API}closeMensaje`,
      { id: parseInt(id) }
    );
  }


  return (
    soporte? 
      ( <>
        {showDelete? <div className="">
          <Alert
            message="¿Seguro que quieres eliminar esta categoría de los planes de lectura?"
            type="error"
            action={
              <Space direction="horizontal">
                <Button size="small" type="danger" onClick={() => { delete_cat(1); }}>
                  Eliminar
                </Button>
                <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                  Cancelar
                </Button>
              </Space>
            }
          />
        </div> : ''}

        <header className="py-8  bg-gray-100">
          <div className="flex items-start w-full pl-8 pr-7">
            <div className='flex w-full'>
              <div className='bg-gray-200 w-32 h-32 min-w-max mr-4 mb-4 overflow-hidden rounded'>
                <div className='w-full h-full overflow-hidden'>
                  {otherUser?.image?
                    <img className='object-cover w-32 h-32' id="profilePic" src={otherUser?.image} />
                    :
                    <div className='font-bold w-32 h-32 flex items-center justify-center text-4xl select-none' style={{ backgroundColor: '#DBEAFE' }}>
                      {otherUser?.name.split(' ')[0]? otherUser?.name.split(' ')[0].charAt(0) : null}
                      {otherUser?.name.split(' ')[1]? otherUser?.name.split(' ')[1].charAt(0) : null}
                    </div>
                  }
                </div>
              </div>
              <div className='w-full mt-1'>
                <h1 className="text-4xl font-semibold w-full">{otherUser?.name}</h1>
                <h1 className="text-lg">{otherUser?.email}</h1>
                <h1 className="text-lg">{dateOfUser != ''? 'Último inicio el '+dateOfUser : ''}</h1>
                <h1 className="text-lg">{dateOfPost != ''? 'Consulta creada el '+dateOfPost : ''}</h1>
              </div>
            </div>

            <div>
              <div className="flex justify-center items-center flex-wrap lg:flex-nowrap">
                <Button className='lg:mr-2' onClick={() => { cerrarConsulta() }} disabled={soporte?.closed? true : false}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_close}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Finalizar Consulta</div>
                  </div>
                </Button>
                <Button danger className='mt-2 lg:mt-0 lg:mr-2' onClick={() => { setShowDelete(1) }}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_trash}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Eliminar Consulta</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>

          <hr className='mb-6 mt-4'></hr>
            
          <h1 className="mx-10 text-3xl font-semibold">{soporte?.topic}</h1>
          <div dangerouslySetInnerHTML={{__html: soporte?.body}} className='mt-2 mx-10'></div>
        </header>


        <div id="conversation" className='pb-3 max-h-96 overflow-auto'>
          <div id='remove' className='p-4 pb-0 text-base text-gray-400'>Sin mensajes anteriores...</div>
          {responses && responses.length > 0? 
            <div className='w-full'>
              {responses.map(response => {
                var createdAtMsg = new Date(response.createdAt)
                return <div key={"res-"+response.id} className={`px-4 mt-2 w-full flex flex-wrap ${response.userToId != 'admins'? 'justify-end text-right' : 'justify-start text-left'} `}>
                    <div className="w-full text-gray-400">
                      <small className="mr-2">
                        {response.userToId != 'admins'? 'Tú' : 'User'}
                      </small>
                      <small>
                        { createdAtMsg.getHours() }:{createdAtMsg.getMinutes()<'10'? '0'+createdAtMsg.getMinutes() : createdAtMsg.getMinutes()}h {(new Date(createdAtMsg)).toLocaleDateString()}
                      </small>
                    </div>
                    <div className={`rounded-b-lg px-4 py-2 ${response.userToId != 'admins'? 'rounded-tl-lg bg-gray-200' : 'rounded-tr-lg bg-gray-300'}`} dangerouslySetInnerHTML={{ __html: response.body }}></div>
                  </div>
              })}
            </div>
            :
            null
          }
        </div>

        <hr></hr>
        {soporte?.closed || closed?
          <div className='p-4 text-sm text-gray-400'>*Esta ticket se encuentra cerrado, por ello las conversaciones no pueden ser modificadas.</div>
          :
          <div className='p-3'>
            <ReactQuill placeholder="Respuesta..." id={"desc-"+id} theme="snow" value={textTest? textTest : ''} onChange={setTextTest} onKeyDown={(event) => maybeSend(event, textTest)}/>
            <div className="flex justify-end items-start mt-3">
              <div onClick={ () => send(textTest)} className="flex items-center border bg-gray-100 py-1 pl-4 pr-2 rounded-sm select-none transform transition ease-in-out duration-200 hover:bg-gray-200 hover:scale-105 cursor-pointer">
                <p>Enviar</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>              
              </div>
            </div>
          </div>
        }

      </> )
      : 
      ( <> </> )
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getConsulta: id => dispatch(getConsulta(id)),
    createMensaje: input => dispatch(createMensaje(input)),
    markAsReaded: initialId => dispatch(markAsReaded(initialId)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoporteMsgs)