import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllUserSuscripciones } from '../actions'
import { Alert  } from 'antd';

function RegistroDeSuscripciones(props) {
  const { getAllUserSuscripciones, suscripciones } = props
  const [filter, setFilter] = useState(0)

  const svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>

  useEffect(() => {
    getAllUserSuscripciones()
  }, [])


  function close() {
    localStorage.removeItem('deletedSuscripcion')
  }



  return (
    <>
      <Filters options={['Todos']} setFilter={setFilter} />
      <div className="container">

        {localStorage.getItem('deletedSuscripcion')? <div className="my-4">
          <Alert
            showIcon 
            message="Suscripción eliminada correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        {suscripciones?
          <DocumentList 
            data={suscripciones}
            filter={filter}
            fields={[
              {
                label: 'Usuario',
                name: 'user'
              },
              {
                label: 'Email',
                name: 'userEmail'
              },
              {
                label: 'Precio pagado',
                name: 'price'
              },
              {
                label: 'De el',
                name: 'createdAt'
              },
              {
                label: 'Hasta el',
                name: 'endsAt'
              },
              
            ]} /> 
          : null }

      </div>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllUserSuscripciones: () => dispatch(getAllUserSuscripciones()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistroDeSuscripciones)