import React, { useState, useEffect } from 'react';
import { Form, Upload, Button, Row, Col, List } from 'antd';
import {
  InboxOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import axios from 'axios';

const { Dragger } = Upload;

function StepImage(props) {

  const { 
    imagesOcc, 
    handleFinish, 
    backStep,
    uploadedImageInLine, 
    setUploadedImageInLine, 
    slug, 
    isBook,
  
    totalWithoutUpload,
    setTotalWithoutUpload,
    totalUploaded,
    setTotalUploaded,
    totalToUploaded,
    setTotalToUploaded,
  } = props;

  const svg_anterior = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;
  const svg_siguiente = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg>
  const svg_upload = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="17 8 12 3 7 8"/><line x1="12" y1="3" x2="12" y2="15"/></svg>
  
  const [images, setImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  
  useEffect(async () => {
    setTotalWithoutUpload(0)
    setTotalUploaded(0)
    setTotalToUploaded(0)
    var totalWithoutUploadTMP = 0
    var totalUploadedTMP = 0
    var totalToUploadeTMP = 0

    images.map(element => {
      element.hidde ? (
        totalWithoutUploadTMP++
      ) : (
        isAlreadyUploaded(element.fileName)? totalUploadedTMP++ : totalToUploadeTMP++
      )
    });

    setTotalToUploaded(totalWithoutUploadTMP)
    setTotalUploaded(totalUploadedTMP)
    setTotalWithoutUpload(totalToUploadeTMP)

  }, [images, uploadedImageInLine, fileList]);

  


  function remove_duplicates_safe(arr) {
    var seen = {};
    var ret_arr = [];
    for (var i = 0; i < arr.length; i++) {
      if (!ret_arr.find(x => x.fileName === arr[i].fileName)) {
        ret_arr.push(arr[i]);
        seen[arr[i]] = true;
      }
    }
    return ret_arr;
  }

  useEffect(async () => {
    const imageNames = [];
    for await (let im of imagesOcc) {

      if (im.slice(-3) === 'jpg' || im.slice(-3) === 'png' || im.slice(-3) === 'jpeg') {
        imageNames.push({
          fileName: im,
          hidde: false,
        });
      }
    }
    const no_repeats = remove_duplicates_safe(imageNames);
    setImages(no_repeats);
  }, [imagesOcc]);


  const propsDragnDropImages = {
    multiple: true,
    listType: 'picture',
    className: 'ant-resize-drag',
    fileList: fileList,
    beforeUpload: (file, list) => {
      if (images.filter(e => e.fileName === file.name).length > 0) {
        setFileList(prev => [...prev, file]);
        const copy = images;
        const i = images.findIndex(obj => file.name === obj.fileName);
        copy[i].hidde = true;
        setImages(copy);
      } else {
        /* alert('El archivo que intentas subir no esta dentro del listado.'); */
      }
      return false;
    },
    onRemove: (file, list) => {
      setFileList(fileList.filter(f => f.name != file.name));
      const copy = images;
      const i = images.findIndex(obj => file.name === obj.fileName);
      copy[i].hidde = false;
      setImages(copy);
    },
  };

  function isAlreadyUploaded(itemFileName) {
    var savedTMP = false;

    uploadedImageInLine?.map(alreadyUploaded => {
      if (itemFileName === alreadyUploaded ) {
        savedTMP = true;
      }
    })
    return savedTMP;
  }



  async function remove_file(fileNameTMP) {
    if (slug && window.confirm("¿Seguro que quieres eliminar el fichero de esta imagen? Los cambios serán instantáneos no como los del resto de la edición.")) {
      const fileNameForm = new FormData();
      fileNameForm.append('filename', slug+'/MEDIA/'+fileNameTMP);
      const responseTMP = await axios.post(
        `${process.env.REACT_APP_API}removefile`,
        { filename: slug+'/MEDIA/'+fileNameTMP, isBook: isBook }
      );

      var uploadedImageTMP = [];
      await uploadedImageInLine.forEach(element => {
        if (element != fileNameTMP) {
          uploadedImageTMP.push(element);
        }
      });
      setUploadedImageInLine(uploadedImageTMP)

      var newImages = [];
      await images.forEach(element => {
        newImages.push(element);
      });
      setImages(newImages);
    }
  }




  return (
    <Form>
      <Form.Item>

        {isUploading?
          <div className="flex justify-center">
            <h1>Uploading...</h1>
          </div> : null
        }


        <div className='flex mb-2 align-middle'>
          <div className='mr-4 flex align-middle'>
            <ExclamationCircleOutlined className="text-red-600 mr-1 mt-1" />
            <p>{totalWithoutUpload} pendientes</p>
          </div>
          <div className='mr-4 flex align-middle'>
            <CheckCircleOutlined className="text-blue-400 mr-1 mt-1" />
            <p>{totalUploaded} guardadas</p>
          </div>
          <div className='mr-4 flex align-middle'>
            <CheckCircleOutlined className="text-green-400 mr-1 mt-1" />
            <p>{totalToUploaded} para subir</p>
          </div>
        </div>


        <div className="space-x-3 space-y-3">
          {images.length ? (
            <Row className='flex flex-nowrap'>

              <Col className='w-full max-w-xs max-h-screen overflow-y-auto overflow-x-visible border-t border-b'>
                <List
                  className=''
                  header={<div>Imágenes encontradas</div>}
                  bordered
                  dataSource={images}
                  renderItem={item => (
                    <List.Item>
                      <div className='flex items-center'>
                        
                        { 
                          item.hidde ? (
                            <CheckCircleOutlined className="text-green-400" />
                          ) : (
                            isAlreadyUploaded(item.fileName)? <CheckCircleOutlined className="text-blue-400" /> : <ExclamationCircleOutlined className="text-red-600" />
                          )
                        } 

                        {'\u00A0'}
                        <p className='overflow-ellipsis'>{item.fileName}</p>
                      </div>

                      <div className='flex items-center'>
                        <Upload {...propsDragnDropImages} showUploadList={false} className='h-5'>
                          <Button htmlType="button" className='ml-2 px-2 h-6' onClick={() => {  }}>
                            <div className='flex items-center justify-center'>{svg_upload}</div>
                          </Button>
                        </Upload>

                        { isAlreadyUploaded(item.fileName) && !item.hidde? (
                          <Button htmlType="button" danger className='ml-2 px-2 h-6' onClick={() => { remove_file(item.fileName) }}>
                            <div className='flex items-center justify-center'>{svg_trash}</div>
                          </Button>
                        ) : (
                          null
                        )}
                      </div>

                    </List.Item>
                  )}
                />
              </Col>

              <Col className='w-full px-2 max-h-screen overflow-y-auto overflow-x-visible'>
                <Dragger {...propsDragnDropImages}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Clica o arrastra tus imágenes para subirlas.
                  </p>
                </Dragger>
              </Col>
            </Row>
          ) : (
            <div> 
              No se han encontrado imagenes entre los ficheros subidos.
              <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
            </div>
          )}

          <br></br>
          <div className='flex justify-between' style={{margin: '0px'}}>
            <Form.Item>
              <Button htmlType="button" size="large" type="primary" onClick={backStep}>
                <div className='flex items-center'>
                  <div className='flex items-center mr-2'>{svg_anterior}</div>
                  <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                </div>
              </Button>
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" size="large" type="primary" onClick={() => handleFinish(fileList, 'img')}>
                <div className='flex items-center'>
                  <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                  <div className='flex items-center ml-2'>{svg_siguiente}</div>
                </div>
              </Button>
            </Form.Item>
          </div>

        </div>
      </Form.Item>
    </Form>
  );
}

export default StepImage;
