import { useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';

import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';

/* import Player from '@vimeo/player'; */


const { Option } = Select;


function RecursosDeVideoInputs(props) {
  var { dataTMP, num, refreshValues } = props

  const [videoSection, setVideoSection] = useState('');
  const [startPosition, setStartPosition] = useState('');
  const [endPosition, setEndPosition] = useState('');

  const [videoLink, setVideoLink] = useState('');  
  const [realVideoLink, setRealVideoLink] = useState('000');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoSubtitle, setVideoSubtitle] = useState('');
  const [videoDesciption, setVideoDescription] = useState('');

  const [sections, setSections] = useState([]);
  const [subSections, setSubSections] = useState([]);

  useEffect(() => {
    const interval = setInterval(function() {
      setSections(JSON.parse(localStorage.getItem('sectionsTMP')))
      setSubSections(JSON.parse(localStorage.getItem('subSectionsTMP')))
    }, 3000);
    /*  clearInterval(interval); */
  }, [])


  useEffect(() => {
    if (dataTMP) {
      var { titleTMP, subtitleTMP, descriptionTMP, linkTMP, sectionSlugTMP, startPositionTMP, endPositionTMP } = dataTMP;
      setVideoLink('https://vimeo.com/'+linkTMP)
      setVideoTitle(titleTMP)
      setVideoSubtitle(subtitleTMP)
      setVideoDescription(descriptionTMP)

      setVideoSection(sectionSlugTMP)
      setStartPosition(startPositionTMP)
      setEndPosition(endPositionTMP)
    }
  }, [dataTMP])


  useEffect(() => {
    if (videoLink && videoLink.replaceAll(' ', '') != '') {
      var linkTMP = videoLink.split('/');
      linkTMP = linkTMP[linkTMP.length - 1].split('?')[0];
      setRealVideoLink(linkTMP)
    } else {
      setRealVideoLink('000')
    }
  }, [videoLink])


  useEffect(() => {
    refreshValues(realVideoLink && realVideoLink.replaceAll(' ', '') != ''? realVideoLink : null, `video-link-${num}`)

    var iframe = document.querySelector(`iframe`);

    if (iframe) {
      /* var player = new Player(iframe);

      if (player) {

        player.getChapters().then(function(chapters) {

          console.log(' ')
          console.log('chapters')
          console.log(chapters)

        }).catch(function(error) {

          console.log(' ')
          console.log('error')
          console.log(error)

        });
      } */
      
    }
  }, [realVideoLink])

  useEffect(() => {
    refreshValues(videoTitle && videoTitle.replaceAll(' ', '') != ''? videoTitle : null, `video-title-${num}`)
  }, [videoTitle])

  useEffect(() => {
    refreshValues(videoSubtitle && videoSubtitle.replaceAll(' ', '') != ''? videoSubtitle : null, `video-subtitle-${num}`)
  }, [videoSubtitle])

  useEffect(() => {
    refreshValues(videoDesciption && videoDesciption.replaceAll(' ', '') != ''? videoDesciption : null, `video-description-${num}`)
  }, [videoDesciption])

  

  return (
    <div className="">

      <div className='w-full flex space-x-2 mb-2'>
        <div className='w-full'>
          <label className='whitespace-nowrap'>Unidad del vídeo:</label>
          <Select id={`section-slug-${num}`} placeholder="Seleccione la unidad del vídeo" onChange={ (value) => { setVideoSection(value); setStartPosition('')} } className='w-full'>
            {sections && sections.length > 0 && sections.map(element => {
              return <Option key={element.slug} value={element.slug}>{element.title}</Option>
            })}
          </Select>
        </div>

        <div className='w-full'>
          <label className='whitespace-nowrap'>Posición de inicio:</label>
          <Select id={`start-position-${num}`} placeholder="Seleccione un tipo" disabled={!videoSection} value={startPosition} onChange={setStartPosition} className='w-full'>
            <Option value=''>Des del inicio</Option>
            {subSections && subSections.length > 0 && subSections.map(element => {
              if (element.parent === videoSection) {
                return <Option key={element.slug} value={element.slug}>Des de {element.title}</Option>
              }
            })}
          </Select>
        </div>

        <div className='w-full'>
          <label className='whitespace-nowrap'>Posición de finalización:</label>
          <Select id={`end-position-${num}`} placeholder="Seleccione un tipo" disabled={!videoSection} value={endPosition} onChange={setEndPosition} className='w-full'>
            <Option value=''>Hasta el final</Option>
            {subSections && subSections.length > 0 && subSections.map(element => {
              if (element.parent === videoSection) {
                return <Option key={element.slug} value={element.slug}>Hasta {element.title}</Option>
              }
            })}
          </Select>
        </div>
      </div>
      
      <div className='iframeContainer mb-2'>
        <iframe id={`iframe-${num}`} className='responsive-iframe' src={`https://player.vimeo.com/video/${realVideoLink}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
      </div>

      <div className='w-full mb-2'>
        <label className='whitespace-nowrap'>Enlace del vídeo:</label>
        <Input id={`video-link-${num}`} name={`video-link-${num}`} placeholder="Enlace del vídeo..." value={videoLink} onChange={ (event) => setVideoLink(event.target.value)} rules={[{ required: true }]}/>
      </div>

      <div className='w-full flex space-x-2 mb-2'>
        <div className='w-full'>
          <label className='whitespace-nowrap'>Título del vídeo:</label>
          <Input id={`video-title-${num}`} name={`video-title-${num}`} placeholder="Título del vídeo..." value={videoTitle} onChange={ (event) => setVideoTitle(event.target.value)} rules={[{ required: true }]}/>
        </div>

        <div className='w-full'>
          <label className='whitespace-nowrap'>Subtítulo del vídeo:</label>
          <Input id={`video-subtitle-${num}`} name={`video-subtitle-${num}`} placeholder="Subtítulo del vídeo..." value={videoSubtitle} onChange={ (event) => setVideoSubtitle(event.target.value)}/>
        </div>
      </div>

      <div className="w-full mb-2">
        <label>Descripción del vídeo: </label>
        <ReactQuill id={`video-description-${num}`} name={`video-description-${num}`} theme="snow" placeholder='Descripción del vídeo...' value={videoDesciption} onChange={setVideoDescription}/>
      </div>

    </div>
  );

}



export default RecursosDeVideoInputs

