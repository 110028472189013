import * as types from '../constants/ActionTypes'

const planCategorias = (state = [], action) => {
  switch(action.type) {
    case types.GET_ALL_PLAN_CATEGORIAS:
      return action.payload
    case types.GET_PLAN_CATEGORIA:
      return action.payload
    case types.UPDATE_PLAN_CATEGORIA:
      return action.payload
    default:
      return state
  }
}

export default planCategorias