import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllChapterReviews } from '../actions'
import { Alert  } from 'antd';

function Opiniones(props) {
  const { getAllChapterReviews, chapterReviews } = props

  const svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>

  useEffect(() => {
    getAllChapterReviews()
  }, [])


  function close() {
    localStorage.removeItem('deletedOpiniones')
  }



  return (
    <>
      <Filters options={['Todos']}/>
      <div className="container">

        {localStorage.getItem('deletedOpiniones')? <div className="my-4">
          <Alert
            showIcon 
            message="Opinión eliminada correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        {chapterReviews?
          <DocumentList 
            data={chapterReviews}
            fields={[
              {
                label: 'User',
                name: 'user'
              },
              {
                label: 'Capítulo',
                name: 'indice'
              },
              {
                label: 'Comentario',
                name: 'body'
              },
              {
                label: 'Puntuación',
                name: 'stars'
              },
              /* {
                label: 'Creada él',
                name: 'createdAt'
              }, */
            ]} /> 
          : null }
      </div>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllChapterReviews: () => dispatch(getAllChapterReviews()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Opiniones)