import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'
import { getAllApprovedChapterReviews } from '../actions'
import { Alert  } from 'antd';

function OpinionesAprobadas(props) {
  const { getAllApprovedChapterReviews, approvedChapterReviews } = props

  const svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>


  useEffect(() => {
    getAllApprovedChapterReviews()
  }, [])


  function close() {
    localStorage.removeItem('deletedOpinionesAprobadas')
  }


  const actions = [
    {
      label: 'Añadir testimonio',
      to: '/opiniones-aprobadas/nuevo',
      type: 'link'
    }
  ]


  return (
    <>
      <Filters options={['Todos']} actions={actions}/>
      <div className="container">

        {localStorage.getItem('deletedOpinionesAprobadas')? <div className="my-4">
          <Alert
            showIcon 
            message="Testimoinio eliminado correctamente."
            type="success"
            onClose={close}
            closable
          />
        </div> : ''}

        {approvedChapterReviews?
          <DocumentList 
            data={approvedChapterReviews}
            fields={[
              {
                label: 'User',
                name: 'username'
              },
              {
                label: 'Comentario',
                name: 'body'
              },
              {
                label: 'Puntuación',
                name: 'stars'
              },
            ]} /> 
          : null }
      </div>
    </>
  )
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getAllApprovedChapterReviews: () => dispatch(getAllApprovedChapterReviews()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpinionesAprobadas)