import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getEmailTemplates } from '../actions'
import { Modal, Button, Form, Input, Alert, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import Filters from '../components/Filters'
import DocumentList from '../components/DocumentList'


const { TextArea } = Input

function Emails(props){

  const { getEmailTemplates, emailTemplates:emailTemplatesQuery } = props
  const { id } = props.match.params

  const history = useHistory();

  const [emailTemplates, setEmailTemplates] = useState(null)
  const [filter, setFilter] = useState(0)



  useEffect(() => {
    getEmailTemplates()
  }, [])

  useEffect(() => {
    setEmailTemplates(emailTemplatesQuery)
  }, [emailTemplatesQuery])





  return (
    <>
      <Filters options={['Todos']} setFilter={setFilter} />
      <div className="container">


        <DocumentList 
          data={emailTemplates}
          filter={filter}
          fields={[
            {
              label: 'Título',
              name: 'title'
            },
            {
              label: 'Tipo',
              name: 'slug'
            },
            {
              label: 'Creado el',
              name: 'createdAt'
            },
            {
              label: 'Editado el',
              name: 'updatedAt'
            },
          ]} />
      </div>


    </>
  )
}


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getEmailTemplates: () => dispatch(getEmailTemplates()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Emails)