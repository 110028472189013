import { useEffect } from 'react'
import { connect } from 'react-redux'
import { deleteApprovedChapterReview } from '../actions'
import { useHistory } from "react-router-dom";


function OpinionesAprobadasDelete(props){
  const { deleteApprovedChapterReview } = props
  const { id } = props.match.params
  let history = useHistory();

  useEffect(() => {
    if (id) { 
      deleteApprovedChapterReview(id);
    }
  }, [id])

  useEffect(() => {
    if (props.notifications) {
      localStorage.setItem('deletedOpinionesAprobadas', true);
      setTimeout(function(){ history.push('/opiniones-aprobadas'); }, 400);
    }
  }, [props])

  return "Eliminando..."
}


const mapStateToProps = state => state
const mapDispatchToProps = dispatch => {
  return {
    deleteApprovedChapterReview: input => dispatch(deleteApprovedChapterReview(input))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpinionesAprobadasDelete)