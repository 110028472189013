import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Input, Button, Steps, InputNumber, Alert, Space  } from 'antd';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from 'moment'

import { getSuscripcionBySlug } from '../actions'
import { connect } from 'react-redux'
import produce from "immer";
import ReactQuill from 'react-quill';


const { Step } = Steps;


function SuscripcionAdd(props) {


  const svg_enable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"/></svg>;
  const svg_disable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>;
  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;
  const svg_next = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>;
  const svg_prev = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>;


  const history = useHistory();
  const [showDelete, setShowDelete] = useState(0);

  const [textSummary, setTextSummary] = useState('');
  const [textDesc, setTextDesc] = useState('');
  const [textSummaryENG, setTextSummaryENG] = useState('');
  const [textDescENG, setTextDescENG] = useState('');

  const [enabled, setEnabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(0)

  const { slug } = props.match.params;
  const { getSuscripcionBySlug, suscripciones: suscripcion } = props

  useEffect(() => {
    if (slug) {
      getSuscripcionBySlug(slug)
    }
  }, [])

  useEffect(() => {



    if (slug && suscripcion && suscripcion.title) {

      setTextSummary(suscripcion.summary)
      setTextDesc(suscripcion.description)

      setTextSummaryENG(suscripcion.summaryENG)
      setTextDescENG(suscripcion.descriptionENG)

      setEnabled(suscripcion.enabled)
    }
  }, [suscripcion])
  

  const submitResource = async (fields) => {

    var input = {
      price: parseInt(fields.price.replaceAll(',', '').replaceAll('.', '').replaceAll(' ', '')), 
      daysDuration: fields.daysDuration, 
      monthsDuration: fields.monthsDuration, 
      
      title: fields.title, 
      summary: textSummary, 
      description: textDesc, 

      titleENG: fields.titleENG, 
      summaryENG: textSummaryENG, 
      descriptionENG: textDescENG, 

      enabled: enabled, 
      slug: slug? slug : null
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API}createSubsctiption`,
      input
    );

    if (response) {
      history.push('/suscripciones');
    }
  };



  const validateMessages = {
    required: `Este campo es obligatorio...`,
    // ...
  };




  function delete_suscripcion(state) {
    if (state) {
      history.push("/suscripciones/delete/"+suscripcion.id);
    }
  }


  function showOrHide(bookid) {
    var allItem = document.getElementById('list-'+bookid);
    var button = document.getElementById('button-'+bookid);

    if (allItem && allItem.classList.contains('hidden')) {
      allItem.classList.remove("hidden");
      button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>';
    } else {
      allItem.classList.add("hidden");
      button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>';
    }
  };



  function changeState(state) {
    setEnabled(state)
  }



  if ( !slug || (slug && suscripcion.title) ) { 
    return (
      <div className="">
        <div className="py-2 container">




          <Form
            initialValues={{
              title: slug && suscripcion.title? suscripcion.title : '', 
              titleENG: slug && suscripcion.titleENG? suscripcion.titleENG : '', 

              daysDuration: slug && suscripcion.daysDuration? suscripcion.daysDuration : 0,
              monthsDuration: slug && suscripcion.monthsDuration? suscripcion.monthsDuration : 0,
              price: slug && suscripcion.price != null? ((parseInt(suscripcion.price) / 100).toFixed(2)).replaceAll(',', ':').replaceAll('.', ',').replaceAll(':', '.') : '20,00',
            }}

            onFinish={fields => submitResource(fields)}
            validateMessages={validateMessages}
          >

            {slug && showDelete? <div className="mb-4">
              <Alert
                message="¿Seguro que quieres eliminar esta suscripción?"
                type="error"
                action={
                  <Space direction="horizontal">
                    <Button size="small" type="danger" onClick={() => { delete_suscripcion(1); }}>
                      Eliminar
                    </Button>
                    <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                      Cancelar
                    </Button>
                  </Space>
                }
              />
            </div> : ''}


            <div className='flex w-full'>
              <div className='ml-1 w-full'>
                <label>Precio {slug === 'mensual_personalizada'? 'por mes' : ''}:</label>
                <div className="flex">
                  <Form.Item name="price" rules={[{ required: true },
                  { validator: (_, value) => !isNaN(value.replaceAll(',', '').replaceAll('.', '').replaceAll(' ', '')) ? Promise.resolve() : Promise.reject(new Error('Formato incorrecto...')), } 
                  ]} className="w-full">
                    <Input placeholder="20,00" className="w-full"/>
                  </Form.Item>
                  <div className="bg-gray-100 w-10 border-t border-r border-b rounded-sm border-gray-300 flex items-center justify-center" style={{height: '32px'}}><p>€</p></div>
                </div>
              </div>

              {slug != 'mensual_personalizada'? <>
                <div className='ml-1 w-full'>
                  <label>Días de duración:</label>
                  <div className="flex">
                    <Form.Item name="daysDuration" rules={[{ required: true }]} className="w-full">
                      <InputNumber placeholder="Número de días de la suscripción" min={0} className="w-full"/>
                    </Form.Item>
                  </div>
                </div>
                <div className='ml-1 w-full'>
                  <label>Meses de duración:</label>
                  <div className="flex">
                    <Form.Item name="monthsDuration" rules={[{ required: true }]} className="w-full">
                      <InputNumber placeholder="Número de meses de la suscripción" min={0} className="w-full"/>
                    </Form.Item>
                  </div>
                </div> 
                </>: null }
            </div>

                        
            <div className='pt-3 pb-3'>
              <Steps current={currentStep} onChange={setCurrentStep}>
                <Step title="Español" />
                <Step title="Inglés" />
              </Steps>
            </div>






            <div style={{ display: currentStep === 0? 'inherit' : 'none' }}>
              <label>Título en español:</label>
              <Form.Item name="title" rules={[{ required: true }]}>
                <Input placeholder="Título en castellano..."/>
              </Form.Item>

              <label>Resumen en español:</label>
              <ReactQuill id="summary" placeholder='Resumen en español...' theme="snow" className='mb-8' value={textSummary? textSummary : ''} onChange={setTextSummary}/>

              <label>Descripción en español:</label>
              <ReactQuill id="desc" placeholder='Descripción en español...' theme="snow" className='mb-8' value={textDesc? textDesc : ''} onChange={setTextDesc}/>
            </div>

            <div style={{ display: currentStep === 1? 'inherit' : 'none' }}>
              <label>Título en inglés:</label>
              <Form.Item name="titleENG" rules={[{ required: true }]}>
                <Input placeholder="Título en inglés..."/>
              </Form.Item>

              <label>Resumen en inglés:</label>
              <ReactQuill id="summaryENG" placeholder='Resumen en inglés...' theme="snow" className='mb-8' value={textSummaryENG? textSummaryENG : ''} onChange={setTextSummaryENG}/>

              <label>Descripción en inglés:</label>
              <ReactQuill id="descENG" placeholder='Descripción en inglés...' theme="snow" className='mb-8' value={textDescENG? textDescENG : ''} onChange={setTextDescENG}/>
            </div>







            <div className='flex justify-between'>
              <div className='flex space-x-2'>
                <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep-1)} disabled={currentStep === 0}>
                  <div className='flex items-center'>
                    <div className='flex items-center mr-2'>{svg_prev}</div>
                    <div className='flex' style={{marginTop: '1px'}}>Anterior</div>
                  </div>
                </Button>

                {enabled?
                  <Button htmlType="button" size="large" danger onClick={() => changeState(false)} >
                    <div className='flex items-center'>
                      <div className='flex items-center mr-2'>{svg_disable}</div>
                      <div className='flex' style={{marginTop: '1px'}}>Deshabilitar</div>
                    </div>
                  </Button>
                  :
                  <Button htmlType="button" size="large" onClick={() => changeState(true)} >
                    <div className='flex items-center'>
                      <div className='flex items-center mr-2'>{svg_enable}</div>
                      <div className='flex' style={{marginTop: '1px'}}>Habilitar</div>
                    </div>
                  </Button>}
              </div>



              <div className='flex space-x-2'>
                <Button htmlType="submit" size="large" type="primary" disabled={!(currentStep === 1)}>
                  <div className='flex items-center'>
                    <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                    <div className='flex items-center ml-2'>{svg_save}</div>
                  </div>
                </Button>

                <Button htmlType="button" size="large" type="primary" onClick={ () => setCurrentStep(currentStep+1)} disabled={currentStep === 1}>
                  <div className='flex items-center'>
                    <div className='flex' style={{marginTop: '1px'}}>Siguiente</div>
                    <div className='flex items-center ml-2'>{svg_next}</div>
                  </div>
                </Button>
              </div>
            </div>
          </Form>


            
        </div>
      </div>
    );
  } else {
    return null;
  }
}


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getSuscripcionBySlug: slug => dispatch(getSuscripcionBySlug(slug))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuscripcionAdd)