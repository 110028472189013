import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Input,  Button, Alert, Space  } from 'antd';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from 'moment'

import { getGlossaryWord } from '../actions'
import { connect } from 'react-redux'
import produce from "immer";
import ReactQuill from 'react-quill';


const { TextArea } = Input;

function Sugerido(props) {

  const svg_enable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"/></svg>;
  const svg_disable = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>;
  const svg_save = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><polyline points="17 21 17 13 7 13 7 21"/><polyline points="7 3 7 8 15 8"/></svg>;


  const history = useHistory();
  const [showDelete, setShowDelete] = useState(0);
  const [textValue, setTextValue] = useState('');
  const [wordsToAdd, setWordsToAdd] = useState(null);

  const { id } = props.match.params;
  const { getGlossaryWord, glossaryWords: glossaryWord } = props


  useEffect(() => {

    if (textValue && textValue.replaceAll('\n', ' ').replaceAll(' ', '') != '') {
      /* var textArrTmp = textValue.replaceAll('\n', ' ').split(' '); */
      var textArrTmp = textValue.split('\n');
      var textArr = [];

      textArrTmp.map(element => {
          if (element && element != '' && element != ' ') {
              textArr.push(element)
          }
      });

      textArr = [...new Set(textArr)];

      setWordsToAdd(textArr);
    } else {
      setWordsToAdd(null);
    }
  }, [textValue])


  useEffect(() => {
    if (id) {
      getGlossaryWord(id)
    }
  }, [])


  const submitResource = async (fields) => {

    var input = {
      id: id, 
      word: fields.word, 
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API}editGlossaryWord`,
      input
    );

    if (response) {
      setTimeout(function(){ history.push('/sugeridos'); }, 300);
    }
  };


  
  const submitResourceText = async (fields) => {

    var input = wordsToAdd;

    const response = await axios.post(
      `${process.env.REACT_APP_API}generateGlossaryWords`,
      input
    );

    if (response) {
      setTimeout(function(){ history.push('/sugeridos'); }, 500);
    }
  };





  const validateMessages = {
    required: `Este campo es obligatorio...`,
  };




  function delete_glossary_word(state) {
    if (state) {
      history.push("/sugeridos/delete/"+glossaryWord.id);
    }
  }


  function showOrHide(bookid) {
    var allItem = document.getElementById('list-'+bookid);
    var button = document.getElementById('button-'+bookid);

    if (allItem && allItem.classList.contains('hidden')) {
      allItem.classList.remove("hidden");
      button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>';
    } else {
      allItem.classList.add("hidden");
      button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>';
    }
  };




  if ( id && glossaryWord && glossaryWord.word ) { 
    return (
      <div className="">
        <div className="py-2 container">




          <Form
            initialValues={{
              word: id && glossaryWord.word? glossaryWord.word : '', 
            }}

            onFinish={fields => submitResource(fields)}
            validateMessages={validateMessages}
          >

            {id && showDelete? <div className="mb-4">
              <Alert
                message="¿Seguro que quieres eliminar esta suscripción?"
                type="error"
                action={
                  <Space direction="horizontal">
                    <Button size="small" type="danger" onClick={() => { delete_glossary_word(1); }}>
                      Eliminar
                    </Button>
                    <Button size="small" type="primary" onClick={() => { setShowDelete(0); }}>
                      Cancelar
                    </Button>
                  </Space>
                }
              />
            </div> : ''}



            <label>Palabra:</label>
            <Form.Item name="word" rules={[{ required: true }]}>
              <Input placeholder="Palabra recomendada"/>
            </Form.Item>

            <div className='flex justify-between'>
              <Form.Item>
                <Button htmlType="submit" size="large" type="primary" disabled={false}>
                  <div className='flex items-center'>
                    <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                    <div className='flex items-center ml-2'>{svg_save}</div>
                  </div>
                </Button>
              </Form.Item>
            </div>
          </Form>


            
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="py-2 container">




          <Form
            onFinish={fields => submitResourceText(fields)}
            validateMessages={validateMessages}
          >

            <label>Palabras:</label>
            <Form.Item name="word" rules={[{ required: true }]}>
              <TextArea rows={20} placeholder="Palabra recomendada" onChange={ (event) => setTextValue(event.target.value) }/>
            </Form.Item>

            {wordsToAdd && <>
              <h1>Palabras a añadir ({wordsToAdd.length})</h1>
              <div className='flex items-center flex-wrap'>
                { wordsToAdd.map((wordTMP, index) => {
                  
                  return <div key={index} className='bg-gray-200 m-1 px-2 rounded'>
                    {wordTMP}
                  </div>
                })}
              </div>
            </>}

            <div className='flex justify-between mt-5'>
              <Form.Item>
                <Button htmlType="submit" size="large" type="primary" disabled={false}>
                  <div className='flex items-center'>
                    <div className='flex' style={{marginTop: '1px'}}>Guardar</div>
                    <div className='flex items-center ml-2'>{svg_save}</div>
                  </div>
                </Button>
              </Form.Item>
            </div>


          </Form>


            
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => state

const mapDispatchToProps = dispatch => {
  return {
    getGlossaryWord: id => dispatch(getGlossaryWord(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sugerido)