import { useEffect } from 'react'
import { connect } from 'react-redux'
import { deleteGlossaryWord } from '../actions'
import { useHistory } from "react-router-dom";


function SugeridoDelete(props){
  const { deleteGlossaryWord } = props
  const { id } = props.match.params
  let history = useHistory();

  useEffect(() => {
    if (id) { 
      deleteGlossaryWord(id);
    }
  }, [id])

  useEffect(() => {
    if (props.glossaryWords) {
      localStorage.setItem('deletedGlossaryWord', true);
      setTimeout(function(){ history.push("/sugeridos"); }, 300);
    }
  }, [props])

  return "Eliminando..."
}


const mapStateToProps = state => state
const mapDispatchToProps = dispatch => {
  return {
    deleteGlossaryWord: input => dispatch(deleteGlossaryWord(input))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SugeridoDelete)