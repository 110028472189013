import { useEffect } from 'react'
import { connect } from 'react-redux'
import { deleteRecurso } from '../actions'
import { useHistory } from "react-router-dom";

function RecursoDelete(props){
  const { deleteRecurso } = props
  const { id } = props.match.params
  let history = useHistory();

  useEffect(() => {
    if (id) { 
      deleteRecurso(id);
    }
  }, [id])

  useEffect(() => {
    if (props.books.status) {
      localStorage.setItem('deletedResource', true);
      setTimeout(function(){ history.push('/recursos'); }, 300);
    }
  }, [props])

  return "Eliminando..."
}


const mapStateToProps = state => state
const mapDispatchToProps = dispatch => {
  return {
    deleteRecurso: input => dispatch(deleteRecurso(input))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecursoDelete)